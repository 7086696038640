import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { learnerDetailsActions } from 'pages/insights/containers/learnerDetailContainer/actions';

const DownloadLearnerDetails = (props) => {
  const { learner_info, dispatch } = props;

  const handleFilterChange = (value) => {
    dispatch(learnerDetailsActions.downloadLearnerDetails({ username: learner_info.username }));
  };

  return (
    <div id="export_button">
      <button className="btn btn-primary" onClick={handleFilterChange}>
        Export <span className="hidden-mobile">CSV</span>
        <FontAwesomeIcon icon={Icons.faFileExport} />
      </button>
    </div>
  );
};

export default DownloadLearnerDetails;
