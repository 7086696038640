import React from 'react';

import UsersCenter from 'pages/users/containers/usersContainer/usersCenter';
import 'pages/users/index.scss';
import 'assets/sass/components/modal.scss';

const Users = () => {
  return <UsersCenter />;
};

export default Users;
