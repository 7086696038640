import React from 'react';

const FooterNote = () => {
  return (
    <p className="footer-note">
      *If a learner is converted to a staff user, they will no longer be counted as a learner. However, their learner
      history will still be included in calculation of course enrollments and completions.
    </p>
  );
};

export default FooterNote;
