import React from 'react';

const FeebackButtons = ({ range, updateValue }) => {
  return (
    <div className="rating-holder">
      {Array(range)
        .fill(null)
        .map((_, index) => {
          const val = index + 1;
          return (
            <div className="radio-holder" key={val}>
              <input
                type="radio"
                value={val}
                id={`nps-val-${val}`}
                name="nps-val-radio"
                onChange={() => updateValue(val)}
              />
              <label htmlFor={`nps-val-${val}`}>
                <span>{val}</span>
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default FeebackButtons;
