import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import LineChartTrend from 'components/charts/lineChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getCurrentTotalSiteCoursesTotalCount,
  getCurrentTotalSiteCoursesPercentageChange,
  getTotalSiteCoursesDataLabels,
  getTotalSiteCoursesDataValues
} from 'selectors';

const TotalCourses = (props) => {
  const { data_labels, data_values, percent_change, total_count } = props;

  return (
    <div className="stats-box">
      <div className="row align-items-center">
        <div className="col-6 col-xl-7">
          <h1>{total_count}</h1>
        </div>
        <div className="col-6 col-xl-5 justify-content-end d-flex">
          <PercentChangeBox percent_change={percent_change} />
        </div>
      </div>

      <span className="sub-title">
        Total Courses
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">Total number of courses published on the platform.</span>
        </span>
      </span>
      <LineChartTrend title="Courses Count" labels={data_labels} values={data_values} />
    </div>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteCoursesDataLabels(state);
  const data_values = getTotalSiteCoursesDataValues(state);
  const percent_change = getCurrentTotalSiteCoursesPercentageChange(state);
  const total_count = getCurrentTotalSiteCoursesTotalCount(state);

  return {
    data_labels,
    data_values,
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalCourses);
