import React, { Fragment, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { Accordion, Card } from 'react-bootstrap';

const moment = require('moment');

const DateFilter = (props) => {
  const {
    filterType,
    handleRefresh,
    handleFilterChange,
    handleSetStartDate,
    handleSetEndDate,
    setDefaultFilter
  } = props;

  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'months')
      .toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const applyButtonHandler = (e) => {
    handleRefresh(e);
    setIsFilterOpen(false);
  };

  const resetButtonHandler = (e) => {
    setDefaultFilter(e);
    setIsFilterOpen(false);
    setIsDatePickerOpen(false);
  };

  const handleStartDateChange = (date) => {
    handleSetStartDate(date);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleSetEndDate(date);
  };

  const filters = {
    this_year: 'This Year',
    last_year: 'Last Year',
    this_month: 'This Month',
    last_month: 'Last Month'
  };

  const filterListItems = Object.keys(filters).map((key, index) => {
    return (
      <li key={key}>
        <button className={filterType === key ? 'active' : ''} onClick={() => handleFilterChange(key)}>
          {filters[key]}
          {filterType === key && <FontAwesomeIcon icon={faCheck} />}
        </button>
      </li>
    );
  });

  return (
    <Fragment>
      <div className="maus-filter-container">
        <Accordion className="maus-filter">
          <Card>
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              {filters[filterType] ? filters[filterType] : 'Custom Date'}{' '}
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse
              className={isFilterOpen ? 'show' : ''}
              style={{ minWidth: isDatePickerOpen ? '545px' : '320px' }}
            >
              <Card.Body>
                <ul>{filterListItems}</ul>
                <Button
                  variant="outline-primary"
                  className={filterType === 'custom' ? 'active' : ''}
                  onClick={() => {
                    handleFilterChange('custom');
                    setIsDatePickerOpen(true);
                  }}
                >
                  Custom Date Range {isDatePickerOpen}
                </Button>

                {isDatePickerOpen && (
                  <div className="date-range-container">
                    <div className="date-range-header">
                      <Button onClick={() => setIsDatePickerOpen(false)} variant="link">
                        <FontAwesomeIcon icon={faChevronLeft} /> Back
                      </Button>
                    </div>
                    <div className="datepickers-holder">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => handleStartDateChange(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        inline
                      />
                      <div className="date-range-divider">
                        <span>To</span>
                      </div>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => handleEndDateChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        inline
                      />
                    </div>
                  </div>
                )}

                <div className="maus-filter-footer">
                  <Button variant="link" onClick={resetButtonHandler}>
                    Reset
                  </Button>
                  <Button onClick={applyButtonHandler} variant="primary">
                    Apply
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default DateFilter;
