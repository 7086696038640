import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserPanelRole, getUserSettings } from 'selectors';

import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import BillingNotifications from 'pages/notifications/containers/notifications/components/billing';
import { getCoursesCompletionInfo, getCoursesEnrollmentInfo, getUserSites } from 'selectors';
import NpsDialog from 'pages/dashboard/containers/npsDialog';
import { quickStatsActions } from 'pages/dashboard/containers/quickStats/actions/quickStats';
import TopCourseEnrollments from 'pages/insights/containers/analyticsSummaryContainer/components/topCourseEnrollments';
import TopCourseCompletions from 'pages/insights/containers/analyticsSummaryContainer/components/topCourseCompletions';
import UserAnalytics from 'pages/dashboard/containers/Analytics';
import UserGuide from 'pages/dashboard/containers/displayDemo';
import UserSite from 'pages/dashboard/containers/sites';
import FeatureOverview from 'pages/dashboard/containers/featureOverview';
import SiteDetails from 'pages/siteDetails';

import CreateSite from 'components/createSite';
import DashboardLoader from 'components/loaders/DashboardLoader';
import 'pages/dashboard/index.scss';
import { checkUserPanelAccess } from 'helpers/dashboard';

const moment = require('moment');

const Dashboard = (props) => {
  const { name, useSites, completion_results, enrollment_results, dispatch, panel_role } = props;
  const [creatingSite, setCreatingSite] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const defaultState = {
      filter_type: 'this_year',
      filter_start_date: moment().subtract(1, 'months'),
      filter_end_date: moment()
    };
    dispatch(quickStatsActions.fetchMAUSQuickStats({ useSites: useSites }));
    dispatch(
      analyticsSummaryActions.fetchGeneralSitesMetrics({
        filter_type: 'this_month',
        filter_start_date: defaultState.filter_start_date,
        filter_end_date: defaultState.filter_end_date,
        useSites: useSites
      })
    );
    dispatch(
      analyticsSummaryActions.fetchCoursesEnrollmentStats({
        filter_type: defaultState.filter_type,
        filter_start_date: defaultState.filter_start_date,
        filter_end_date: defaultState.filter_end_date,
        useSites: useSites
      })
    );
    dispatch(
      analyticsSummaryActions.fetchCoursesCompletionStats({
        filter_type: defaultState.filter_type,
        filter_start_date: defaultState.filter_start_date,
        filter_end_date: defaultState.filter_end_date,
        useSites: useSites
      })
    );
  }, [useSites, dispatch]);

  const handleSetLoading = (loading) => {
    setLoading(loading);
  };

  return loading ? (
    <DashboardLoader />
  ) : (
    <div className="block">
      <UserGuide />
      <FeatureOverview />
      <NpsDialog />
      <BillingNotifications />
      {creatingSite ? (
        window.location.hostname.includes('edly') || window.location.hostname.includes('edx') ? (
          <CreateSite setCreatingSite={setCreatingSite} />
        ) : (
          <SiteDetails />
        )
      ) : (
        <>
          <h1>Welcome {name}</h1>
          <UserSite handleSetLoading={handleSetLoading} creatingSite={creatingSite} setCreatingSite={setCreatingSite} />
          {checkUserPanelAccess(panel_role) && (
            <>
              <UserAnalytics />
              <div className="stats-block course-analytics-list-block">
                <div className="stats-box">
                  <TopCourseEnrollments enrollment_results={enrollment_results} />
                </div>
                <div className="stats-box">
                  <TopCourseCompletions completion_results={completion_results} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  const { name } = getUserSettings(state);
  const completion_results = getCoursesCompletionInfo(state);
  const enrollment_results = getCoursesEnrollmentInfo(state);
  const useSites = getUserSites(state);
  return {
    useSites,
    completion_results,
    enrollment_results,
    name,
    panel_role
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(quickStatsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
