import { coursesAnalyticsConstants } from 'pages/insights/containers/coursesAnalyticsContainer/constants';

const initialState = {
  all_courses_stats: [],
  all_courses_maus: {}
};

export function fetchAllCoursesGeneralData(state = initialState, action) {
  switch (action.type) {
    case coursesAnalyticsConstants.FETCH_COURSES_ALL_DATA_SUCCESS:
      return {
        ...state,
        all_courses_stats: action.payload
      };
    case coursesAnalyticsConstants.FETCH_COURSES_ALL_DATA_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case coursesAnalyticsConstants.FETCH_COURSES_MAUS_SUCCESS:
      let courses_maus_data = initialState.all_courses_maus;
      if (action.payload.length > 0) {
        action.payload.forEach((course_maus) => {
          courses_maus_data[course_maus.course_id] = course_maus;
        });
      }
      return {
        ...state,
        all_courses_maus: courses_maus_data
      };
    case coursesAnalyticsConstants.FETCH_COURSES_MAUS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
