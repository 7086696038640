import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const axios = require('axios');

export const notificationService = {
  fetchNotifications,
  fetchServicesNotifications,
  updateNotificationStatus
};

function fetchNotifications(page_number) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.NOTIFICATIONS_API}`;

  if (page_number !== undefined) {
    url = `${url}?page=${page_number}`;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
}

function fetchServicesNotifications(page_number) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.SERVICES_NOTIFICATIONS_API}`;

  if (page_number !== undefined) {
    url = `${url}?page=${page_number}`;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
}

function updateNotificationStatus(notification_id, status) {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.NOTIFICATIONS_API}${notification_id}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      id: notification_id,
      is_read: status
    }
  };
  return axios(requestOptions);
}
