import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const BillingNotificationItem = (props) => {
  const { notification, updateNotificationStatus } = props;

  return (
    <div key={notification.id} className="alert alert-warning">
      <FontAwesomeIcon icon={Icons.faExclamationCircle} className="icon" />
      <span className="close">
        <FontAwesomeIcon
          icon={Icons.faTimes}
          onClick={() => updateNotificationStatus(notification.id, notification.is_read !== true)}
        />
      </span>
      <p>{notification.message}</p>
    </div>
  );
};

export default BillingNotificationItem;
