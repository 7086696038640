import { createSelector } from 'reselect';
import {
  computePercentageChange,
  extractDataLabels,
  extractDataValues,
  getCurrentMonthStats,
  getPreviousMonthStats
} from 'selectors/computation_helpers';

export const getTotalSiteLearners = (state) => state.GENERAL_SITE_METRICS.total_site_learners;
export const getTotalSiteLearnersDataLabels = createSelector([getTotalSiteLearners], extractDataLabels);
export const getTotalSiteLearnersDataValues = createSelector([getTotalSiteLearners], extractDataValues);
export const getTotalSiteLearnersChange = createSelector([getTotalSiteLearnersDataValues], computePercentageChange);

export const getLearnerMAUsLabels = (state) => state.LEARNERS_QUICK_STATS.learners_maus_labels;
export const getLearnerMAUsValues = (state) => state.LEARNERS_QUICK_STATS.learners_maus_values;
export const getCurrentLearnerMAUs = createSelector([getLearnerMAUsValues], getCurrentMonthStats);
export const getPreviousLearnerMAUs = createSelector([getLearnerMAUsValues], getPreviousMonthStats);

export const getLearnerMAUsChange = (state) => state.LEARNERS_QUICK_STATS.learners_maus_percentage_change;

export const getLearnerCourseCompletionsLabels = (state) =>
  state.LEARNERS_QUICK_STATS.learners_course_completions_label;
export const getLearnerCourseCompletionsValues = (state) =>
  state.LEARNERS_QUICK_STATS.learners_course_completions_values;

export const getCurrentLearnerCourseCompletions = createSelector(
  [getLearnerCourseCompletionsValues],
  getCurrentMonthStats
);
export const getPreviousLearnerCourseCompletions = createSelector(
  [getLearnerCourseCompletionsValues],
  getPreviousMonthStats
);
export const getLearnerCourseCompletionsChange = (state) =>
  state.LEARNERS_QUICK_STATS.learners_course_completions_percentage_change;
