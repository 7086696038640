import React from 'react';

const CourseListItem = (props) => {
  const { id, name, value } = props;
  return (
    <li key={id}>
      <span>{name}</span>
      <span>{value}</span>
    </li>
  );
};

export default CourseListItem;
