import React from 'react';
import PublicRegistration from './containers/components/publicRegistration';

function SignInSettings() {
  return (
    <div className="container">
      <div className="block form-container">
        <h1>Sign in/up settings</h1>
        <PublicRegistration />
      </div>
    </div>
  );
}

export default SignInSettings;
