import React from 'react';

import { Bar } from 'react-chartjs-2';

const BarChart = (props) => {
  const { title, labels, values } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        backgroundColor: '#e94246',
        hoverBackgroundColor: '#ca3035',
        borderColor: '#dd1f25',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: values
      }
    ]
  };

  const options = {
    layout: {
      padding: {
        top: 5
      }
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ]
    }
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
