import React from 'react';

import CourseLearnerListItem from 'pages/insights/containers/courseDetailContainer/components/courseLearnerListItem';

const CourseLearnersListing = (props) => {
  const { enrollments_data } = props;
  return enrollments_data.map((enrollment, index) => {
    return <CourseLearnerListItem key={index} enrollment={enrollment} />;
  });
};

export default CourseLearnersListing;
