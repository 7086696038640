import React from 'react';
import './index.scss';
import SegmentKeyInput from './containers/components/segmentKeyInput';
import LmsKeyInput from './containers/components/lmsKeyInput';
import MarketingKeyInput from './containers/components/marketingKeyInput';

function Analytics() {
  return (
    <div className="container">
      <div className="block form-container">
        <h1>Analytics Integration</h1>
        <MarketingKeyInput />
        <LmsKeyInput />
        <SegmentKeyInput />
      </div>
    </div>
  );
}

export default Analytics;
