import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import GeneralSettings from 'pages/settings/containers/settingsContainer/components/generalSettings';
import Appearance from 'pages/appearance';
import Pricing from 'pages/pricing';
import { getUserPanelRole } from 'selectors';
import 'pages/insights/index.scss';
import SignInSettings from 'pages/signinSettings';
import Analytics from 'pages/analytics';
import Integrations from 'pages/integrations';
import SiteDetails from 'pages/siteDetails';
import MobileConfig from 'pages/mobileconfig';
import DataBackup from 'pages/dataBackup';

const Settings = (props) => {
  const { panel_role } = props;
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path="/settings">
        <Redirect to={`${path}/general`} />
      </Route>
      <Route exact path={`${path}/general`}>
        <GeneralSettings />
      </Route>
      <Route exact path={`${path}/profile`}>
        <GeneralSettings />
      </Route>
      {(panel_role === 'panel_admin' || panel_role === 'super_admin') && (
        <Fragment>
          <Route exact path={`${path}/signin`}>
            <SignInSettings panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/analytics`}>
            <Analytics panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/integrations`}>
            <Integrations panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/sitedetails`}>
            <SiteDetails panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/billing`}>
            <Pricing panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/appearance`}>
            <Appearance panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/mobileconfig`}>
            <MobileConfig panel_role={panel_role} />
          </Route>
          <Route exact path={`${path}/data_backup`}>
            <DataBackup panel_role={panel_role} />
          </Route>
        </Fragment>
      )}
    </Switch>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  return { panel_role };
}

export default connect(mapStateToProps)(Settings);
