export const showUserGuideConstant = {
  UPDATE_SHOW_USER_GUIDE_FAILURE: 'UPDATE_SHOW_USER_GUIDE_FAILURE'
};

const updateShowUserGuideFailure = (error) => {
  return { type: showUserGuideConstant.UPDATE_SHOW_USER_GUIDE_FAILURE, payload: error };
};

export const showUserGuideDispatches = {
  updateShowUserGuideFailure
};
