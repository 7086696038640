export const benefitsContent = [
  {
    title: 'Zendesk integration',
    description:
      'Provide top-notch customer support with Zendesk integration, enabling efficient ticket management, customer queries, and support documentation within your LMS platform. Available on Demand'
  },
  {
    title: 'Business intelligence connector',
    description:
      'Connect to business intelligence tools for advanced data analysis, allowing you to gain deeper insights into learner behavior, course effectiveness, and overall platform performance.'
  },
  {
    title: 'Single-Sign-On for learners',
    description:
      'Simplify access with single-sign-on functionality for learners, reducing login friction and enhancing security by allowing users to access the LMS using their existing credentials.'
  },
  {
    title: 'Mobile application',
    description:
      'Offer convenient on-the-go learning with a dedicated mobile application, ensuring that learners can access course materials, participate in discussions, and complete assignments from anywhere, at any time.'
  },
  {
    title: 'API endpoints',
    description:
      'Integrate seamlessly with other systems using our robust API endpoints, enabling data synchronization, custom workflows, and third-party application integrations to enhance your LMS capabilities.'
  },
  {
    title: 'Custom contracts and SLAs',
    description:
      'Ensure tailored service and reliability with custom contracts and service level agreements, providing you with specific terms, conditions, and guaranteed performance metrics to meet your unique requirements.'
  },
  {
    title: 'Custom feature development',
    description:
      'Enhance your LMS with features tailored to your specific needs, leveraging our development team to build custom functionalities that align with your educational goals and operational processes.'
  },
  {
    title: 'Unlimited Multi-Sites',
    description:
      'Enhance your LMS with features tailored to your specific needs, leveraging our development team to build custom functionalities that align with your educational goals and operational processes.'
  },
  {
    title: 'Dedicated Customer Support',
    description:
      'Receive personalized assistance with our dedicated customer support team, ensuring that you have a direct line to experts who understand your LMS setup and can provide timely, effective solutions to any issues. Available on demand.'
  },
  {
    title: 'Course authoring tools',
    description:
      'Let us create and customize engaging, multimedia-rich courses with our course authoring experts, empowering you to deliver personalized learning experiences without needing technical expertise. Service available on demand and is charged on the basis of efforts needed for individual project.'
  },
  {
    title: 'Insights and Reports',
    description:
      'Gain valuable insights into learner progress and course effectiveness with detailed analytics and reports, helping you make data-driven decisions to improve course content and learner outcomes. Basic available out of the box.'
  },
  {
    title: 'Marketing site',
    description:
      'Attract and convert potential learners with a dedicated marketing site for your courses, complete with customizable template, SEO optimization, and support of marketing tools to boost your online presence and improve discoverability.'
  },
  {
    title: 'Platform management',
    description:
      'Simplify administration with comprehensive platform management capabilities, including easy-to-use dashboards for monitoring performance, managing content, and ensuring system security and compliance.'
  },
  {
    title: 'User management',
    description:
      'Effortlessly manage user roles, permissions, and access levels with our robust user management system, ensuring that each learner and instructor has the appropriate access to the resources they need.'
  },
  {
    title: 'LTI integrations',
    description:
      "Unleash a world of interactive learning with our LMS's seamless LTI integrations, Host engaging live sessions, collaborative classrooms, and dynamic webinars directly within your LMS. This on-demand integration simplifies workflows, eliminates platform juggling, and fosters a convenient learning environment for all. LTI tools boost engagement with real-time interaction, creating a more dynamic and personalized learning experience."
  },
  {
    title: 'Basic content types',
    description:
      'Create diverse learning materials with support for various basic content types, including text, images, videos, and quizzes, allowing you to design comprehensive and interactive courses.'
  },
  {
    title: 'Analytics and reports',
    description:
      'Track and analyze learner engagement and performance with powerful analytics tools, providing you with actionable insights to tailor your teaching strategies and improve learner success rates.'
  },
  {
    title: 'Managed hosting',
    description:
      'Focus on teaching while we take care of secure and reliable hosting, offering high uptime, and maintenance, so you never have to worry about technical issues.'
  },
  {
    title: 'Professional support',
    description:
      'Access expert support to resolve issues and optimize your LMS experience, ensuring that any challenges are quickly addressed by our knowledgeable support team.'
  },
  {
    title: 'Custom domain and URL',
    description:
      'Enhance your brand identity with a custom domain and URL for your LMS, providing a professional and cohesive experience for your learners.'
  },
  {
    title: 'Advanced content types',
    description:
      'Offer rich learning experiences with support for advanced content types, such as interactive simulations, gamified elements, and adaptive learning paths, to keep learners engaged and motivated. Available on demand.'
  },
  {
    title: 'Able to create course programs',
    description:
      'Design comprehensive learning programs tailored to your curriculum needs, allowing for structured learning paths, prerequisites, and bundled courses to enhance educational outcomes.'
  },
  {
    title: 'Ecommerce and subscriptions',
    description:
      'Monetize your courses with integrated ecommerce and subscription management, providing seamless payment processing, automated billing, and various pricing models to maximize your revenue.'
  }
];
