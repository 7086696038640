import React, { useState, Fragment } from 'react';
import Pagination from 'react-js-pagination';

import { PAGE_SIZE } from 'config';
import SearchInput from 'components/analytics/searchInput';
import CourseLearnersListing from 'pages/insights/containers/courseDetailContainer/components/courseLearnersListing';
import { courseAnalyticsActions } from 'pages/insights/containers/courseDetailContainer/actions';

const CourseLearnersOverview = (props) => {
  const { course_id, enrollments_count, enrollments_data, current_page, dispatch } = props;
  const [searchText, setSearchText] = useState('');
  const [timer, setTimer] = useState(null);
  const [userFilters, setUserFilters] = useState({
    column: 'user__profile__name',
    direction: 'desc asc'
  });

  const handlePageChange = (page_number) => {
    let payload = { course_id: course_id, page: page_number, search: searchText };
    if (userFilters.direction !== 'desc asc') {
      payload['ordering'] = userFilters.direction === 'asc' ? userFilters.column : `-${userFilters.column}`;
    }
    dispatch(courseAnalyticsActions.fetchCourseEnrollmentsData(payload));
  };

  const handleInputChange = (event) => {
    let search = event.target.value;
    setSearchText(search);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(courseAnalyticsActions.fetchCourseEnrollmentsData({ page: 1, course_id: course_id, search: search }));
      }, 400)
    );
  };

  const onSort = (columnName) => {
    const direction = userFilters.column ? (userFilters.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    setUserFilters({
      ...userFilters,
      column: columnName,
      direction: direction
    });

    let order_by = `${columnName}`;
    if (direction === 'desc') {
      order_by = `-${columnName}`;
    }
    setTimer(
      setTimeout(() => {
        dispatch(
          courseAnalyticsActions.fetchCourseEnrollmentsData({
            course_id: course_id,
            search: searchText,
            ordering: order_by
          })
        );
      }, 400)
    );
  };

  return (
    <Fragment>
      <div className="learner-overview-header course-analytics-list-header">
        <SearchInput label="Search Learner" searchText={searchText} handleInputChange={handleInputChange} />
        <h1>
          Learners Overview
          <small>Current Page: {current_page}</small>
        </h1>
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>
                <span
                  className={
                    userFilters.column === 'user__profile__name' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('user__profile__name')}
                >
                  Name
                </span>
              </th>
              <th>
                <span
                  className={
                    userFilters.column === 'user__email' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('user__email')}
                >
                  Email
                </span>
              </th>
              <th width="120">
                <span>Graded Course Progress</span>
              </th>
              <th width="110">
                <span>Total Course Progress</span>
              </th>
              <th width="120">
                <span>Total Grade</span>
              </th>
              <th width="110">
                <span>Completion Date</span>
              </th>
              <th width="110">
                <span
                  className={
                    userFilters.column === 'user__date_joined' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('user__date_joined')}
                >
                  Account Created
                </span>
              </th>
              <th width="110">
                <span
                  className={
                    userFilters.column === 'user__last_login' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('user__last_login')}
                >
                  Last Login
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <CourseLearnersListing enrollments_data={enrollments_data} />
          </tbody>
        </table>
        <div className="table-footer">
          <Pagination
            activePage={current_page}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={enrollments_count}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CourseLearnersOverview;
