import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { getUserPanelRole, getUserSettings } from 'selectors';
import NavItem from 'components/sidebar/navItem';
import SettingsDropDown from 'components/sidebar/settingsDropDown';
import 'components/sidebar/index.scss';

const SideBar = (props) => {
  const { panel_role, user_email } = props;
  const location = useLocation();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [insightsOpen, setInsightsOpen] = useState(!!location.pathname.includes('insights'));
  const [settingsOpen, setSettingsOpen] = useState(!!location.pathname.includes('settings'));

  return (
    <div id="sidebar_content" className={`side-nav ${mobileSidebarOpen ? 'open' : ''}`}>
      <span
        className="sidebar-toggle"
        onClick={() => setMobileSidebarOpen(!mobileSidebarOpen)}
        aria-label="Mobile sidebar toggler"
      />
      <ul>
        <NavItem
          link="/panel/dashboard"
          label="Dashboard"
          fontIcon={Icons.faColumns}
          insightsOpen={false}
          setSidebarOpen={setMobileSidebarOpen}
          setInsightsOpen={setInsightsOpen}
          settingsOpen={false}
          setSettingsOpen={setSettingsOpen}
        />

        {(panel_role === 'panel_admin' || panel_role === 'super_admin') &&
          location.pathname !== '/panel/dashboard' &&
          location.pathname !== '/panel/settings/general' && (
            <NavItem
              link="/panel/users"
              label="Users"
              fontIcon={Icons.faUserFriends}
              insightsOpen={false}
              setSidebarOpen={setMobileSidebarOpen}
              setInsightsOpen={setInsightsOpen}
              settingsOpen={false}
              setSettingsOpen={setSettingsOpen}
            />
          )}

        {(panel_role === 'panel_admin' || panel_role === 'super_admin' || panel_role === 'insights_admin') &&
          location.pathname !== '/panel/dashboard' &&
          location.pathname !== '/panel/settings/general' && (
            <>
              <NavItem
                link="/panel/insights/courses"
                label="Courses"
                className={location.pathname.includes('course') && insightsOpen ? 'active' : ''}
                insightsOpen={false}
                fontIcon={Icons.faBookReader}
                setSidebarOpen={setMobileSidebarOpen}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={false}
                setSettingsOpen={setSettingsOpen}
              />
              <NavItem
                link="/panel/insights/learners"
                label="Learners"
                className={location.pathname.includes('learner') && insightsOpen ? 'active' : ''}
                insightsOpen={false}
                fontIcon={Icons.faGraduationCap}
                setSidebarOpen={setMobileSidebarOpen}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={settingsOpen}
                setSettingsOpen={setSettingsOpen}
              />
            </>
          )}

        <SettingsDropDown
          location={location}
          settingsOpen={settingsOpen}
          insightsOpen={false}
          setSidebarOpen={setMobileSidebarOpen}
          setSettingsOpen={setSettingsOpen}
          setInsightsOpen={setInsightsOpen}
          panelRole={panel_role}
          userEmail={user_email}
        />
      </ul>
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  const user_email = getUserSettings(state).email;
  return {
    panel_role,
    user_email
  };
}

export default connect(mapStateToProps, null)(SideBar);
