import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import 'components/footer/index.scss';

const StatsBox = (props) => {
  const { value, label, description } = props;
  return (
    <div className="stats-box">
      <h1>{value}</h1>
      <span className="sub-title">
        {label}
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">{description}</span>
        </span>
      </span>
    </div>
  );
};

export default StatsBox;
