import { messageNotification, validateLoginSession } from 'helpers';
import { quickStatsDispatches } from 'pages/dashboard/containers/quickStats/constants';
import { quickStatsService, mausService } from 'services';
import { addSubOrgParamValue } from 'helpers/dashboard';

const moment = require('moment');

const fetchMAUSQuickStats = (payload = {}, service = mausService.fetchMAUS) => {
  let params = {};
  let keys = Object.keys(payload);
  if (keys.includes('useSites')) {
    const { useSites, ...rest } = payload;
    addSubOrgParamValue(payload['useSites'], params);
    payload = rest;
    keys = Object.keys(payload);
  }
  if (keys.length) {
    params = getAnalyticsSummaryPayload(payload);
    params['type'] = 'custom';
  } else {
    params = {
      ...params,
      type: 'monthly',
      roles: 'learner',
      start_date: moment()
        .subtract(6, 'months')
        .endOf('month')
        .format('M-YYYY'),
      end_date: moment().format('M-YYYY')
    };
  }

  return (dispatch) => {
    return service(params)
      .then((userData) => {
        Promise.resolve(dispatch(quickStatsDispatches.fetchMAUsQSSuccess(userData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(quickStatsDispatches.fetchMAUsQSFailure(error));

        if (error.response.status !== 403) {
          messageNotification(
            'danger',
            'There was an error fetching MAUs',
            error.response.data.error ? error.response.data.error : 'There was an error requesting MAUs Report!'
          );
        }
      });
  };
};

const fetchEnrolledCoursesQuickStats = (payload, service = quickStatsService.fetchEnrolledCoursesQuickStats) => {
  let params = {};
  if (payload) {
    params = getAnalyticsSummaryPayload(payload);
    params['type'] = 'custom';
  } else {
    params = {
      type: 'monthly',
      learners_only: 'true',
      start_date: moment()
        .subtract(6, 'months')
        .endOf('month')
        .format('M-YYYY'),
      end_date: moment().format('M-YYYY')
    };
  }

  return (dispatch) => {
    return service(params)
      .then((coursesData) => {
        Promise.resolve(dispatch(quickStatsDispatches.fetchEnrolledCoursesQSSuccess(coursesData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(quickStatsDispatches.fetchEnrolledCoursesQSFailure(error));

        if (error.response.status !== 403) {
          messageNotification(
            'danger',
            'There was an error fetching report',
            error.response.data.error
              ? error.response.data.error
              : 'There was an error requesting enrolled courses report!'
          );
        }
      });
  };
};

const fetchCourseCompletionsQuickStats = (payload, service = quickStatsService.fetchCourseCompletions) => {
  let params = {};
  if (payload) {
    params = getAnalyticsSummaryPayload(payload);
    params['type'] = 'custom';
  } else {
    params = {
      type: 'monthly',
      learners_only: 'true',
      start_date: moment()
        .subtract(6, 'months')
        .endOf('month')
        .format('M-YYYY'),
      end_date: moment().format('M-YYYY')
    };
  }

  return (dispatch) => {
    return service(params)
      .then((courseCompletionsData) => {
        Promise.resolve(dispatch(quickStatsDispatches.fetchCourseCompletionsQSSuccess(courseCompletionsData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(quickStatsDispatches.fetchCourseCompletionsQSFailure(error));

        if (error.response.status !== 403) {
          messageNotification(
            'danger',
            'There was an error fetching course completions',
            error.response.data.error ? error.response.data.error : 'There was an error requesting course completions!'
          );
        }
      });
  };
};

const getAnalyticsSummaryPayload = (payload) => {
  if (!payload) return payload;
  if (!payload.filter_type) return payload;

  const { filter_type, filter_start_date, filter_end_date } = payload;
  let params = {};
  switch (filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'custom':
      params['start_date'] = moment(filter_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(filter_end_date).format('D-MM-YYYY');
      break;
    default:
      break;
  }
  return params;
};

export const quickStatsActions = {
  fetchMAUSQuickStats,
  fetchEnrolledCoursesQuickStats,
  fetchCourseCompletionsQuickStats
};
