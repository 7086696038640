import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import LineChart from 'components/charts/lineChart';
import InsightsLoader from 'components/loaders/InsightsLoader';

const CompletionsOverTime = (props) => {
  const { course_info } = props;

  const labels =
    course_info &&
    course_info.users_completed &&
    course_info.users_completed.history.map((data) => {
      return data.period;
    });
  const values =
    course_info &&
    course_info.users_completed &&
    course_info.users_completed.history.map((data) => {
      return data.value;
    });

  return (
    <div className="stats-box">
      {course_info && course_info.users_completed && course_info.users_completed.history ? (
        <Fragment>
          <span className="sub-title">
            Completions Over Time
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">Number of completions over time, for the last six months..</span>
            </span>
          </span>
          {labels && values && <LineChart title="Course Completions" labels={labels} values={values} />}
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

export default CompletionsOverTime;
