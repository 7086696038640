import React from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getOrganization } from 'selectors';

const NavItem = (props) => {
  const {
    className,
    fontIcon,
    insightsOpen,
    label,
    link,
    setInsightsOpen,
    setSidebarOpen,
    settingsOpen,
    setSettingsOpen
  } = props;

  return (
    <li>
      <NavLink
        to={link}
        className={className ? className : ''}
        activeClassName="active"
        onClick={() => {
          setSidebarOpen(false);
          setInsightsOpen(insightsOpen);
          setSettingsOpen(settingsOpen);
          mixpanel.track(label, {
            service: 'edly_panel',
            screen_name: 'panel_navigation',
            organization: props.organization.name
          });
        }}
      >
        <FontAwesomeIcon icon={fontIcon} />
        <span>{label}</span>
      </NavLink>
    </li>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  return { organization };
}

export default connect(mapStateToProps)(NavItem);
