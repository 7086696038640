export const analyticsSummaryConstants = {
  FETCH_COURSES_GENERAL_INFO_SUCCESS: 'FETCH_COURSES_GENERAL_INFO_SUCCESS',
  FETCH_COURSES_GENERAL_INFO_FAILURE: 'FETCH_COURSES_GENERAL_INFO_FAILURE',
  FETCH_GENERAL_SITE_METRICS_SUCCESS: 'FETCH_GENERAL_SITE_METRICS_SUCCESS',
  FETCH_GENERAL_SITE_METRICS_FAILURE: 'FETCH_GENERAL_SITE_METRICS_FAILURE',
  FETCH_MONTHLY_USERS_STATS_SUCCESS: 'FETCH_MONTHLY_USERS_STATS_SUCCESS',
  FETCH_MONTHLY_USERS_STATS_FAILURE: 'FETCH_MONTHLY_USERS_STATS_FAILURE',
  FETCH_COURSES_ENROLLMENT_SUCCESS: 'FETCH_COURSES_ENROLLMENT_SUCCESS',
  FETCH_COURSES_ENROLLMENT_FAILURE: 'FETCH_COURSES_ENROLLMENT_FAILURE',
  FETCH_COURSES_COMPLETION_SUCCESS: 'FETCH_COURSES_COMPLETION_SUCCESS',
  FETCH_COURSES_COMPLETION_FAILURE: 'FETCH_COURSES_COMPLETION_FAILURE',
  FETCH_SITE_DAILY_METRICS_SUCCESS: 'FETCH_SITE_DAILY_METRICS_SUCCESS',
  FETCH_SITE_DAILY_METRICS_FAILURE: 'FETCH_SITE_DAILY_METRICS_FAILURE',
  FETCH_SITE_MONTHLY_METRICS_SUCCESS: 'FETCH_SITE_MONTHLY_METRICS_SUCCESS',
  FETCH_SITE_MONTHLY_METRICS_FAILURE: 'FETCH_SITE_MONTHLY_METRICS_FAILURE'
};

const coursesGeneralInfoSuccess = (coursesData) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_GENERAL_INFO_SUCCESS, payload: coursesData.data };
};

const coursesGeneralInfoFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_GENERAL_INFO_FAILURE, error };
};

const generalSiteMetricsSuccess = (siteMetrics) => {
  return { type: analyticsSummaryConstants.FETCH_GENERAL_SITE_METRICS_SUCCESS, payload: siteMetrics.data };
};

const generalSiteMetricsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_GENERAL_SITE_METRICS_FAILURE, error };
};

const monthlyUsersStatsSuccess = (usersData) => {
  return { type: analyticsSummaryConstants.FETCH_MONTHLY_USERS_STATS_SUCCESS, payload: usersData.data };
};

const monthlyUsersStatsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_MONTHLY_USERS_STATS_FAILURE, error };
};

const coursesEnrollmentStatsSuccess = (siteMetrics) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_ENROLLMENT_SUCCESS, payload: siteMetrics.data };
};

const coursesEnrollmentStatsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_ENROLLMENT_FAILURE, error };
};

const coursesCompletionStatsSuccess = (siteMetrics) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_COMPLETION_SUCCESS, payload: siteMetrics.data };
};

const coursesCompletionStatsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_COURSES_COMPLETION_FAILURE, error };
};

const siteDailyMetricsSuccess = (siteMetrics) => {
  return { type: analyticsSummaryConstants.FETCH_SITE_DAILY_METRICS_SUCCESS, payload: siteMetrics.data };
};

const siteDailyMetricsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_SITE_DAILY_METRICS_FAILURE, error };
};

const fetchSiteMonthlyMetricsSuccess = (siteMetrics) => {
  return { type: analyticsSummaryConstants.FETCH_SITE_MONTHLY_METRICS_SUCCESS, payload: siteMetrics.data };
};

const fetchSiteMonthlyMetricsFailure = (error) => {
  return { type: analyticsSummaryConstants.FETCH_SITE_MONTHLY_METRICS_FAILURE, error };
};

export const analyticsSummaryDispatches = {
  coursesGeneralInfoSuccess,
  coursesGeneralInfoFailure,
  generalSiteMetricsSuccess,
  generalSiteMetricsFailure,
  monthlyUsersStatsSuccess,
  monthlyUsersStatsFailure,
  coursesEnrollmentStatsSuccess,
  coursesEnrollmentStatsFailure,
  coursesCompletionStatsSuccess,
  coursesCompletionStatsFailure,
  siteDailyMetricsSuccess,
  siteDailyMetricsFailure,
  fetchSiteMonthlyMetricsSuccess,
  fetchSiteMonthlyMetricsFailure
};
