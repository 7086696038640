export const mausConstants = {
  REQUEST_REPORT_SUCCESS: 'REQUEST_MAUS_REPORT_SUCCESS',
  REQUEST_REPORT_FAILURE: 'REQUEST_MAUS_REPORT_FAILURE',
  MAUS_FETCH_SUCCESS: 'MAUS_FETCH_SUCCESS',
  MAUS_FETCH_FAILURE: 'MAUS_FETCH_FAILURE'
};

const requestMausReportSuccess = (userData) => {
  return { type: mausConstants.REQUEST_REPORT_SUCCESS, payload: userData };
};

const requestMausReportFailure = (error) => {
  return { type: mausConstants.REQUEST_REPORT_FAILURE, payload: error };
};

const fetchMAUsSuccess = (userData) => {
  return { type: mausConstants.MAUS_FETCH_SUCCESS, payload: userData.data };
};

const fetchMAUsFailure = (error) => {
  return { type: mausConstants.MAUS_FETCH_FAILURE, payload: error };
};

export const mausDispatches = {
  requestMausReportSuccess,
  requestMausReportFailure,
  fetchMAUsSuccess,
  fetchMAUsFailure
};
