import React from 'react';
import { connect } from 'react-redux';

const DataBackupTable = ({ data_backup }) => {
  const backup_exists = data_backup.length !== 0;
  return (
    <div class="col-12 col-lg-10">
      <div class="integration-holder backup-files-holder">
        {!backup_exists ? (
          <p>'No backups available'</p>
        ) : (
          <div className="row align-items-center ">
            <div class="col-6">
              <h4>Backup File</h4>
            </div>
            <div class="col-6 justify-content-end d-flex">
              <h4>Download Link</h4>
            </div>
            <div class="col-12">
              <hr></hr>
            </div>
            <div class="col-6">Users CSV</div>
            <div class="col-6 justify-content-end d-flex">
              <a href={`${data_backup[0].user_data_csv_url}`} target="_blank" rel="noreferrer">
                Download
              </a>
            </div>
            <div class="col-12">
              <hr></hr>
            </div>
            <div class="col-6">Courses CSV</div>
            <div class="col-6 justify-content-end d-flex">
              <a href={`${data_backup[0].course_data_csv_url}`} target="_blank" rel="noreferrer">
                Download
              </a>
            </div>
            <div class="col-12">
              <hr></hr>
            </div>
            <div class="col-6">Enrollments CSV</div>
            <div class="col-6 justify-content-end d-flex">
              <a href={`${data_backup[0].enrollment_data_csv_url}`} target="_blank" rel="noreferrer">
                Download
              </a>
            </div>
            <div class="col-12">
              <hr></hr>
            </div>
            <div class="col-6">Course Progress CSV</div>
            <div class="col-6 justify-content-end d-flex">
              <a href={`${data_backup[0].progress_data_csv_url}`} target="_blank" rel="noreferrer">
                Download
              </a>
            </div>
            <div class="col-12">
              <hr></hr>
            </div>
            <div class="col-6">Certificates CSV</div>
            <div class="col-6 justify-content-end d-flex">
              <a href={`${data_backup[0].certificate_data_csv_url}`} target="_blank" rel="noreferrer">
                Download
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const data_backup = state.DATA_BACKUP.dataBackup;

  return {
    data_backup
  };
}

export default connect(mapStateToProps)(DataBackupTable);
