import { getPanelLmsBaseUrl, getWordpressBaseUrl } from '../../helpers/utilities';
import { EDX_API_ENDPOINTS, WORDPRESS_API_ENDPOINTS } from 'config';

const axios = require('axios');

export function getEdlySiteConfig() {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.EDLY_SITE_CONFIG}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}

export function updateEdlySiteConfig(form_data) {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.EDLY_SITE_CONFIG}`;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    url,
    data: form_data
  };
  return axios(requestOptions);
}
export function updateWpSiteConfig(form_data) {
  const url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.EDLY_SITES_CONFIG}`;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    crossDomain: true,
    url,
    data: form_data
  };
  return axios(requestOptions);
}

export function addBadgeClass(form_data) {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.BADGE_API}`;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    url,
    data: form_data
  };
  return axios(requestOptions);
}
