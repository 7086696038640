import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { dataBackupActions } from 'pages/dataBackup/actions';
import DataBackupTable from 'pages/dataBackup/components/dataBackupTable';
import DataBackupDownload from 'pages/dataBackup/components/dataBackupDownload';

function DataBackup({ dispatch }) {
  useEffect(() => {
    dispatch(dataBackupActions.fetchDataBackup());
  }, [dispatch]);

  return (
    <React.Fragment>
      <DataBackupDownload />
      <DataBackupTable />
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(dataBackupActions, dispatch)
  };
}

export default connect(mapDispatchToProps)(DataBackup);
