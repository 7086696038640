import React from 'react';

const NotificationsDropdownListing = (props) => {
  const { dropdown_notifications, handleNotificationStatus } = props;

  return dropdown_notifications.slice(0, 5).map((notification, index) => {
    return (
      <button
        key={index}
        className={notification.is_read === true ? 'read dropdown-item' : 'unread dropdown-item'}
        title={notification.is_read === true ? 'Read' : 'Mark as read'}
        onClick={() => handleNotificationStatus(notification.id, notification.is_read)}
      >
        {notification.message}
      </button>
    );
  });
};

export default NotificationsDropdownListing;
