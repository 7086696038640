import { learnerDetailsConstants } from 'pages/insights/containers/learnerDetailContainer/constants';

const initialState = {
  learner_info: {}
};

export function fetchLearnerDetails(state = initialState, action) {
  switch (action.type) {
    case learnerDetailsConstants.FETCH_LEARNER_DETAILS_SUCCESS:
      let learner_details = initialState.learner_details;
      if (action.payload.results.length > 0) {
        learner_details = action.payload.results[0];
      }
      return {
        ...state,
        learner_info: learner_details
      };
    case learnerDetailsConstants.FETCH_LEARNER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
