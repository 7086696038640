import { messageNotification } from 'helpers';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row } from 'react-bootstrap';
import { quickStatsService } from 'services';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import { UpdateCookie } from 'services/cookie';
import { isSubdomainValid } from 'helpers/dashboard';

const CreateSite = (props) => {
  const { setCreatingSite, dispatch } = props;
  const [subDomainError, setSubDomainError] = useState('');
  const [inputs, setInputs] = useState({
    site_name: '',
    sub_domain: '',
    tos: false,
    req_support: false
  });
  const [loading, setLoading] = useState(false);
  const buttonDisabled = !inputs.site_name || !inputs.sub_domain || loading;

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'tos' || event.target.name === 'req_support') {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
  };

  const handleClose = () => {
    setCreatingSite(false);
    dispatch(userSiteActions.fetchUserSites());
    setSubDomainError('');
    setInputs({
      site_name: '',
      sub_domain: '',
      tos: false,
      req_support: false
    });
  };

  const handleSubmit = (event) => {
    const subDomain = isSubdomainValid(inputs.sub_domain);
    if (!subDomain) {
      setSubDomainError('Special characters are not allowed');
      return;
    }
    event.preventDefault();
    setLoading(true);
    setSubDomainError('');
    let payload = inputs;
    quickStatsService
      .createSite(payload)
      .then((response) => {
        if (response.status === 201) {
          messageNotification('success', 'New Site Created', response.data);
          dispatch(userSiteActions.fetchUserSites());
          UpdateCookie();
          handleClose();
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const responseData = error.response.data;
          if (responseData.sub_domain) {
            setSubDomainError(responseData.sub_domain);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="col-12 col-lg-10">
      <h1>Site Details</h1>
      <div className="px-4 custom-domain-info">
        <p>
          You are using Edly subdomains previously, If you want to use custom domains for your sites please write to us.
        </p>
        <Button className="btn outline-primary" onClick={() => window.HubSpotConversations.widget.open()}>
          {' '}
          Contact Us{' '}
        </Button>
      </div>
      <h2>Continue by adding Edly subdomain</h2>
      <Form className="subdomain-info">
        <div className="block">
          <Form.Group controlId="site_name">
            <div className="subheader-holder">
              <h3>Site name*</h3>
              <p>
                Your organisation that will be displayed on your homepage, LMS, and Wordpress site (up to 50 characters)
              </p>
            </div>
            <Form.Control
              type="text"
              value={inputs.site_name}
              onChange={handleInputChange}
              name="site_name"
              placeholder="skillup (example)"
              required
            />
          </Form.Group>
        </div>
        <div className="block">
          <Form.Group controlId="sub_domain">
            <div className="subheader-holder">
              <h3>URL for Edly site*</h3>
              <p>
                An 8 character or less unique identifier for your platform that will appear in the URL. Note: You will
                not be able to update this later in the Edly Panel
              </p>
            </div>

            <div className="container px-4">
              <div className="row gx-5 align-items-center">
                <div className="col d-flex align-items-center">
                  <Form.Control
                    type="text"
                    value={inputs.sub_domain}
                    onChange={handleInputChange}
                    name="sub_domain"
                    placeholder="skillup"
                    required
                    style={{
                      minWidth: '230px',
                      marginRight: '20px'
                    }}
                  />
                  <p>.edly.io</p>
                </div>
                <div className="col">
                  <button className="btn btn-link site-detail-btn hidden"> Generate url</button>
                </div>
              </div>
            </div>
            {subDomainError && <Form.Text className="text-error">{subDomainError}</Form.Text>}
          </Form.Group>
        </div>
      </Form>
      <Row className="justify-content-end" style={{ gap: '1rem' }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={handleSubmit} disabled={buttonDisabled}>
          {loading && <div className="loader" />}
          <span className={loading ? 'ml-18' : ''}> Create New Site</span>
        </Button>
      </Row>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapDispatchToProps)(CreateSite);
