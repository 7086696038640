import { createSelector } from 'reselect';
import { getPercentageChange, getCurrentMonthStats, getPreviousMonthStats } from 'selectors/computation_helpers';

export const getEnrolledCoursesStats = (state) => state.QUICK_STATS.enrolled_courses_quick_stats_data;
export const getCurrentEnrolledCourses = createSelector([getEnrolledCoursesStats], getCurrentMonthStats);
export const getPreviousEnrolledCourses = createSelector([getEnrolledCoursesStats], getPreviousMonthStats);
export const getEnrolledCoursesQSChange = createSelector(
  [getCurrentEnrolledCourses, getPreviousEnrolledCourses],
  getPercentageChange
);

export const getCourseCompletionsStats = (state) => state.QUICK_STATS.course_completions_quick_stats_data;
export const getCurrentMonthCourseCompletionsCount = createSelector([getCourseCompletionsStats], getCurrentMonthStats);
export const getCourseCompletionsLabels = (state) => state.QUICK_STATS.course_completions_quick_stats_data_label;
export const getCourseCompletionTotalCount = (state) =>
  state.QUICK_STATS.course_completions_quick_stats_total_users_count;
export const getCourseCompletionPercentageChange = (state) =>
  state.QUICK_STATS.course_completions_quick_stats_percentage_change;
export const getCurrentCourseCompletions = createSelector([getCourseCompletionsStats], getCurrentMonthStats);
export const getPreviousCourseCompletions = createSelector([getCourseCompletionsStats], getPreviousMonthStats);
export const getCourseCompletionQSChange = createSelector(
  [getCurrentCourseCompletions, getPreviousCourseCompletions],
  getPercentageChange
);

export const getCurrentMonthMAUs = (state) => state.MAUS.maus_data[1];
export const getPreviousMonthMAUs = (state) => state.MAUS.maus_data[0];
export const getMAUsQuickStats = (state) => state.QUICK_STATS.monthly_active_quick_stats_data;
export const getMAUsQuickStatsLabels = (state) => state.QUICK_STATS.monthly_active_quick_stats_data_label;
export const getMAUsQuickStatsTotalCount = (state) => state.QUICK_STATS.monthly_active_quick_stats_total_users_count;
export const getMAUsQuickStatsPercentageChange = (state) =>
  state.QUICK_STATS.monthly_active_quick_stats_percentage_change;
export const getCurrentMAUs = createSelector([getMAUsQuickStats], getCurrentMonthStats);
export const getPreviousMAUs = createSelector([getMAUsQuickStats], getPreviousMonthStats);
export const getMAUsQSChange = createSelector([getCurrentMAUs, getPreviousMAUs], getPercentageChange);
