import React, { useState } from 'react';

import { AccountSettings } from 'pages/settings/containers/settingsContainer/components/accountSettings';
import UserPreferences from 'pages/settings/containers/settingsContainer/components/userPreferences';
import 'pages/settings/index.scss';

const GeneralSettings = () => {
  const [currentTab, setCurrentTab] = useState('account_settings');
  return (
    <div className="block">
      <h1>General</h1>
      <div className="tabs" id="accountSettings">
        <ul className="tab-links">
          <li
            onClick={() => setCurrentTab('account_settings')}
            className={currentTab === 'account_settings' ? 'active' : ''}
          >
            <span>Account Settings</span>
          </li>
          <li
            onClick={() => setCurrentTab('user_preferences')}
            className={currentTab === 'user_preferences' ? 'active' : ''}
          >
            <span>User Preferences</span>
          </li>
        </ul>
        <div className="tab-content">
          {currentTab === 'account_settings' ? (
            <div id="div_accounts_settings" className={currentTab === 'user_preferences' ? 'hidden' : ''}>
              <AccountSettings />
            </div>
          ) : (
            <div id="div_user_preferences">
              <UserPreferences />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
