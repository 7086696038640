import React, { Fragment, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card } from 'react-bootstrap';

import { usersActions } from 'pages/users/containers/usersContainer/actions';

const PaginationDropdown = (props) => {
  const { setPageSize, current_page, search, dispatch, roles, orderBy } = props;
  const [paginationSize, setPaginationSize] = useState(10);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handlePageSizeChange = (e) => {
    setPaginationSize(e);
    setIsFilterOpen(false);
    setPageSize(page_sizes[e]);
    dispatch(
      usersActions.fetchUsers({
        page_size: page_sizes[e],
        page_number: current_page,
        searchText: search,
        roles: roles,
        order_by: orderBy
      })
    );
  };

  const page_sizes = {
    page_size_10: 10,
    page_size_25: 25,
    page_size_50: 50,
    page_size_100: 100
  };

  const filterListItems = Object.keys(page_sizes).map((key, index) => {
    return (
      <li key={key}>
        <button className={paginationSize === key ? 'active' : ''} onClick={() => handlePageSizeChange(key)}>
          {page_sizes[key]}
          {paginationSize === key && <FontAwesomeIcon icon={faCheck} />}
        </button>
      </li>
    );
  });

  return (
    <Fragment>
      <div className="pagination-container">
        <span className="pagination-container-label">Show</span>
        <Accordion className="pagination-dropdown">
          <Card>
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              {page_sizes[paginationSize] ? page_sizes[paginationSize] : 10}{' '}
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse className={isFilterOpen ? 'show' : ''} style={{ minWidth: '150px' }}>
              <Card.Body>
                <ul>{filterListItems}</ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default PaginationDropdown;
