import React from 'react';

const Help = () => {
  return (
    <div className="block">
      <h1>Help is coming soon!</h1>
    </div>
  );
};

export default Help;
