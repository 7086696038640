import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { notificationActions } from 'pages/notifications/containers/notifications/actions';
import NotificationsDropdownListing from './dropdownListing';
import { getSiteNotifications } from 'selectors';

const NotificationDropdown = (props) => {
  const { dropdown_notifications, unread_notification, updateNotificationStatus } = props;

  const handleNotificationStatus = (notification_id, status) => {
    if (status === false) {
      updateNotificationStatus(notification_id, status !== true);
    }
  };

  return (
    <NavDropdown
      title={
        <span className="active">
          <FontAwesomeIcon icon={Icons.faBell} />
          <span className="sr-only">Toggle notifications drop down</span>
          {unread_notification.length > 0 ? <span className="badge">{unread_notification.length}</span> : ''}
        </span>
      }
      id="notification-nav-dropdown"
      className="notifications"
      alignRight
    >
      {dropdown_notifications.length > 0 ? (
        <Fragment>
          <div className="notification-block">
            <NotificationsDropdownListing
              dropdown_notifications={dropdown_notifications}
              handleNotificationStatus={handleNotificationStatus}
            />
          </div>
          <div className="see-all-notification">
            <NavLink className="btn btn-link" to="/panel/notifications">
              <span>See all +</span>
            </NavLink>
          </div>
        </Fragment>
      ) : (
        <div className="no-notification">
          <span>No Notification Available</span>
        </div>
      )}
    </NavDropdown>
  );
};

function mapStateToProps(state) {
  const { dropdown_notifications } = getSiteNotifications(state);
  const unread_notification = dropdown_notifications.filter((notification) => notification.is_read === false);
  return {
    dropdown_notifications,
    unread_notification
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);
