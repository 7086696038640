import { messageNotification, validateLoginSession } from 'helpers';
import { learnersAnalyticsService } from 'services/insights';
import { learnerDetailsDispatches } from 'pages/insights/containers/learnerDetailContainer/constants';

export const learnerDetailsActions = {
  fetchLearnerDetails,
  downloadLearnerDetails
};

function fetchLearnerDetails(payload, stopLoader) {
  return (dispatch) => {
    learnersAnalyticsService
      .fetchLearnerDetails(payload)
      .then((userData) => {
        Promise.resolve(dispatch(learnerDetailsDispatches.learnerDetailsSuccess(userData))).then(() => {
          if (stopLoader !== undefined) {
            stopLoader();
          }
        });
      })
      .catch((error) => {
        dispatch(learnerDetailsDispatches.learnerDetailsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching learner details', error.response.data.error);
      });
  };
}

function downloadLearnerDetails(payload) {
  return (dispatch) => {
    learnersAnalyticsService
      .downloadLearnerDetails(payload)
      .then((userData) => {
        messageNotification('success', 'Emailing learner detail report', userData.data['message']);
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error emailing learner details', error.response.data.error);
      });
  };
}
