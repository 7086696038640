import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BadgrIntegration from 'pages/integrations/containers/BadgrContainer/components/badgrIntegration';
import { badgrActions } from 'pages/integrations/containers/BadgrContainer/actions';

const Integrations = ({ dispatch }) => {
  useEffect(() => {
    dispatch(badgrActions.fetchBadgesData());
  }, [dispatch]);

  return <BadgrIntegration />;
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(badgrActions, dispatch)
  };
}

export default connect(mapDispatchToProps)(Integrations);
