import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const Badgr = forwardRef(({ badge, setNewBadgr, id, removeBadgr, sendData }, ref) => {
  const [courseKey, setCourseKey] = useState(badge ? badge.course_id : '');
  const [courseMode, setCourseMode] = useState(badge ? badge.mode : 'honor');
  const [serverSlug, setServerSlug] = useState(badge ? badge.badgr_server_slug : '');
  const [isBlur, setIsBlur] = useState(false);

  const sendDataToParent = () => {
    if (isBlur) {
      if (id !== null && id !== undefined) {
        sendData({ course_key: courseKey, course_mode: courseMode, badgr_server_slug: serverSlug });
      } else {
        sendData({
          slug: badge.slug,
          course_id: courseKey,
          mode: courseMode,
          badgr_server_slug: serverSlug
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    sendDataToParent
  }));

  const deleteBadgr = () => {
    if (id !== null && id !== undefined) {
      removeBadgr(id);
    } else {
      removeBadgr(badge.slug, true);
    }
  };

  const handleBlur = () => {
    setIsBlur(true);
  };

  return (
    <div className="row align-items-center badge-record">
      <div className="col-12">
        <hr className="custum-divider" />
      </div>
      <div className="col-9">
        <div className="row">
          <div className="col-12 col-lg-6">
            <label className="form-label">Course Key</label>
            <input
              className="form-control"
              value={courseKey}
              placeholder={'course-v1:badgr+bg+101'}
              onBlur={handleBlur}
              onChange={(event) => setCourseKey(event.target.value)}
            ></input>
          </div>
          <div className="col-12 col-lg-6">
            <label className="form-label">Course Mode</label>
            <select
              id="course_mode"
              name="course_mode"
              className="form-control"
              value={courseMode}
              onBlur={handleBlur}
              onChange={(event) => setCourseMode(event.target.value)}
            >
              <option value="" disabled hidden>
                Select a course mode
              </option>
              <option value="honor">honor</option>
              <option value="verified">verified</option>
              <option value="professional">professional</option>
            </select>
          </div>

          <div className="col-12">
            <label className="form-label">Badgr Server Slug</label>
            <input
              className="form-control"
              value={serverSlug}
              placeholder={'Enter your Badgr Server Slug'}
              onBlur={handleBlur}
              onChange={(event) => setServerSlug(event.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className="col-3 btn-holder badge-controls justify-content-end d-flex">
        <span className="btn-delete">
          <FontAwesomeIcon icon={Icons.faTimes} onClick={deleteBadgr} />
        </span>

        <div>
          <button
            className="btn btn-link"
            onClick={() => {
              setNewBadgr();
            }}
          >
            Add Badge
          </button>
        </div>
      </div>
    </div>
  );
});
export default Badgr;
