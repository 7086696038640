import { learnersAnalyticsConstants } from 'pages/insights/containers/learnersAnalyticsContainer/constants';

const learnersInitialState = {
  learners_count: 0,
  total_pages: 1,
  current_page: 1,
  next: null,
  previous: null,
  learners_data: []
};

export function fetchUsersDetailAnalytics(state = learnersInitialState, action) {
  switch (action.type) {
    case learnersAnalyticsConstants.FETCH_LEARNERS_DETAIL_ANALYTICS_SUCCESS:
      return {
        ...state,
        current_page: action.payload.current_page,
        learners_count: action.payload.count,
        total_pages: action.payload.total_pages,
        next: action.payload.next,
        previous: action.payload.previous,
        learners_data: action.payload.results
      };
    case learnersAnalyticsConstants.FETCH_LEARNERS_DETAIL_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function fetchAllUsersAnalytics(state = [], action) {
  switch (action.type) {
    case learnersAnalyticsConstants.FETCH_ALL_LEARNERS_ANALYTICS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case learnersAnalyticsConstants.FETCH_ALL_LEARNERS_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

export function downloadLearnersDetails(state = [], action) {
  switch (action.type) {
    case learnersAnalyticsConstants.DOWNLOAD_LEARNER_PDF_SUCCESS:
      return {
        ...state,
        download_success: action.payload.data
      };
    case learnersAnalyticsConstants.DOWNLOAD_LEARNER_PDF_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const learnersQuickStatsInitialState = {
  learners_maus_labels: [],
  learners_maus_values: [0, 0],
  learners_course_completions_label: [],
  learners_course_completions_values: [0, 0]
};

export function fetchLearnersQuickStats(state = learnersQuickStatsInitialState, action) {
  switch (action.type) {
    case learnersAnalyticsConstants.FETCH_LEARNERS_MAUS_SUCCESS:
      return {
        ...state,
        learners_maus_labels: action.payload.data.month_names,
        learners_maus_values: action.payload.data.monthly_users_count,
        learners_maus_percentage_change: action.payload.data.percentage_change
      };
    case learnersAnalyticsConstants.FETCH_LEARNERS_MAUS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case learnersAnalyticsConstants.FETCH_LEARNERS_COURSE_COMPLETIONS_SUCCESS:
      return {
        ...state,
        learners_course_completions_label: action.payload.data.month_names,
        learners_course_completions_values: action.payload.data.monthly_course_completions_count,
        learners_course_completions_percentage_change: action.payload.data.percentage_change
      };
    case learnersAnalyticsConstants.FETCH_LEARNERS_COURSE_COMPLETIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
