import { badgrDispatches } from 'pages/integrations/containers/BadgrContainer/constants';
import { badgrService } from 'services/integrations';

const fetchBadgesData = () => {
  return (dispatch) => {
    badgrService
      .fetchBadgesData()
      .then((badgesData) => dispatch(badgrDispatches.fetchBadgesSuccess(badgesData)))
      .catch((error) => dispatch(badgrDispatches.fetchBadgesFailure(error)));
  };
};

export const badgrActions = {
  fetchBadgesData
};
