import axios from 'axios';

import { EDX_API_ENDPOINTS, PANEL_API_ENDPOINTS } from 'config';
import {
  getPanelBackendUrl,
  getPanelLmsBaseUrl,
  getEcommerceBaseUrl,
  getStudioBaseUrl,
  getPanelOrganizationId
} from 'helpers';

const updateSettings = (userSettings) => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.ACCOUNTS_INFO}/${userSettings.username}`;
  const { name, email, old_email, year_of_birth } = userSettings;
  let data = {
    name: name,
    year_of_birth: year_of_birth ? year_of_birth : null
  };
  if (email !== old_email) {
    data['email'] = email;
  }

  return axios.patch(url, data, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/merge-patch+json',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    }
  });
};

const updateUserPreferences = (userSettings) => {
  const { username, time_zone, country_code } = userSettings;
  const accountsInfoUrl = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.ACCOUNTS_INFO}/${username}`;
  const userPreferencesUrl = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.USER_PREFERENCES}${username}`;
  let accountsInfoData = {
    country: country_code
  };
  let userPreferencesData = {
    time_zone: time_zone,
    country: country_code,
    country_code: country_code
  };

  axios.patch(accountsInfoUrl, accountsInfoData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/merge-patch+json',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    }
  });
  return axios.patch(userPreferencesUrl, userPreferencesData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/merge-patch+json',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    }
  });
};

const updateUserProfileImage = (userSettings, profile_image) => {
  const { username } = userSettings;
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.UPLOAD_USER_PROFILE_IMAGE}${username}/upload`;

  let bodyFormData = new FormData();
  bodyFormData.set('file', profile_image);

  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      Accept: '*/*',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: bodyFormData,
    url
  };
  return axios(requestOptions);
};

const resetUserPassword = (userSettings) => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.RESET_USER_PASSWORD}`;
  const { email } = userSettings;

  let bodyFormData = new FormData();
  bodyFormData.set('email', email);

  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      Accept: '*/*',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: bodyFormData,
    url
  };
  return axios(requestOptions);
};

const getAccountsSettings = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.ACCOUNTS_INFO}`;
  return axios.get(url, { withCredentials: true });
};

const getUserPreferences = () => {
  const username = localStorage.getItem('edx_username');
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.USER_PREFERENCES}${username}`;
  return axios.get(url, { withCredentials: true });
};

const getCountryTimezones = (country_code) => {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_COUNTRY_TIMEZONES}`;
  if (country_code !== undefined) {
    url = `${url}?country_code=${country_code}`;
  }

  return axios.get(url, { withCredentials: true });
};

const getLmsCsrfToken = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_CSRF_TOKEN}`;
  return axios.get(url, { withCredentials: true });
};

const getStudioCsrfToken = () => {
  const url = `${getStudioBaseUrl()}${EDX_API_ENDPOINTS.FETCH_CSRF_TOKEN}`;
  return axios.get(url, { withCredentials: true });
};

const getEcommerceLoginSession = () => {
  const url = `${getEcommerceBaseUrl()}login`;
  return axios.get(url, { withCredentials: true });
};

const getEcommerceCsrfToken = () => {
  const url = `${getEcommerceBaseUrl()}${EDX_API_ENDPOINTS.FETCH_ECOMMERCE_CSRF_TOKEN}`;
  return axios.get(url, { withCredentials: true });
};

const getPanelCsrfToken = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.FETCH_PANEL_CSRF_TOKEN}`;
  return axios.get(url, { withCredentials: true });
};

const getPanelUserRoles = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_CURRENT_USER_DETAIL}`;
  return axios.get(url, { withCredentials: true });
};

const SessionLogOut = () => {
  const url = `${getPanelBackendUrl()}logout`;
  return axios.get(url, { withCredentials: true });
};

const fetchOrganizations = () => {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const fetchBusinessDetails = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.CLIENT_BUSINESS}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const updateOrgTermsConditions = (accept_terms_conditions) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}${getPanelOrganizationId()}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      accept_terms_conditions: accept_terms_conditions
    }
  };
  return axios(requestOptions);
};

const updateShowUserGuide = (showUserGuide) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}${getPanelOrganizationId()}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      show_user_guide: showUserGuide
    }
  };
  return axios(requestOptions);
};

const updateNpsData = (data) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}${getPanelOrganizationId()}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data
  };
  return axios(requestOptions);
};

export const userSettingsService = {
  updateSettings,
  updateUserPreferences,
  updateUserProfileImage,
  resetUserPassword,
  getUserPreferences,
  getPanelUserRoles,
  getAccountsSettings,
  SessionLogOut,
  getCountryTimezones,
  getLmsCsrfToken,
  getStudioCsrfToken,
  getPanelCsrfToken,
  getEcommerceLoginSession,
  getEcommerceCsrfToken,
  fetchOrganizations,
  updateOrgTermsConditions,
  updateNpsData,
  fetchBusinessDetails,
  updateShowUserGuide
};
