import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { CTA_BANNER_MSG, BILLING_PAGE, CTA_BANNER_EXPIRE_MSG } from 'components/ctaBanner/constants/index';

const CTABannerContent = (props) => {
  return (
    <div className="expired-info-banner">
      <FontAwesomeIcon icon={Icons.faExclamationTriangle} />
      {props.daysRemaining > 0 ? ' ' + CTA_BANNER_MSG + props.daysRemaining + ' days ' : ' ' + CTA_BANNER_EXPIRE_MSG}
      <Link to={BILLING_PAGE}>Upgrade Now</Link>
    </div>
  );
};

export default CTABannerContent;
