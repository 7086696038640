import { courseDetailConstants } from 'pages/insights/containers/courseDetailContainer/constants';

const courseDetailInitialState = {
  course_id: null,
  enrollments_count: 0,
  total_pages: 1,
  current_page: 1,
  next: null,
  previous: null,
  enrollments_data: [],
  course_info: [],
  all_learners_info: null
};

export function fetchCourseDetailData(state = courseDetailInitialState, action) {
  switch (action.type) {
    case courseDetailConstants.FETCH_COURSE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        current_page: action.payload.current_page,
        enrollments_count: action.payload.count,
        total_pages: action.payload.total_pages,
        next: action.payload.next,
        previous: action.payload.previous,
        enrollments_data: action.payload.results,
        course_id: action.course_id
      };
    case courseDetailConstants.FETCH_COURSE_ENROLLMENT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case courseDetailConstants.FETCH_COURSE_ALL_LEARNERS_SUCCESS:
      return {
        ...state,
        all_learners_info: action.payload
      };
    case courseDetailConstants.FETCH_COURSE_ALL_LEARNERS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case courseDetailConstants.FETCH_COURSE_DETAIL_SUCCESS:
      let course_detail_info = courseDetailInitialState.course_info;
      if (action.payload.results.length > 0) {
        course_detail_info = action.payload.results[0];
      }
      return {
        ...state,
        course_info: course_detail_info
      };
    case courseDetailConstants.FETCH_COURSE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
