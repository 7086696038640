import {
  ltiDispatches,
  lti1p1Variables,
  lti1p3Variables
} from 'pages/integrations/containers/LtiIntegrationContainer/constants';
import { ltiConfigurationService } from 'services/integrations';
import { messageNotification } from 'helpers';

const fetchLtiConfigurations = () => {
  return (dispatch) => {
    dispatch(ltiDispatches.fetchLtiConfigurationsRequest());
    ltiConfigurationService
      .fetchLtiConfigurations()
      .then((ltiData) => dispatch(ltiDispatches.fetchLtiConfigurationsSuccess(ltiData)))
      .catch((error) => dispatch(ltiDispatches.fetchLtiConfigurationsFailure(error)));
  };
};

const updateLtiConfigurations = (data) => {
  const mappedDict = mapLtiKeys(data);
  return (dispatch) => {
    ltiConfigurationService
      .updateLtiConfiguration(mappedDict)
      .then((response) => {
        messageNotification('success', 'LTI configuration updated.', response.data);
        return dispatch(fetchLtiConfigurations());
      })
      .catch((error) => {
        messageNotification('danger', 'There was an error updating lti configuration.', error.response.data);
      });
  };
};

const deleteLtiConfiguration = (params) => {
  return (dispatch) => {
    ltiConfigurationService
      .deleteLtiConfiguration(params)
      .then((response) => {
        messageNotification('success', 'LTI configuration deleted.', response.data);
        return dispatch(fetchLtiConfigurations());
      })
      .catch((error) => {
        messageNotification('danger', 'Error deleting LTI configuration', error.response.data);
      });
  };
};

const mapLtiKeys = (dict) => {
  const mappingDict = dict.version == 'lti_1p1' ? lti1p1Variables : lti1p3Variables;
  const newDict = {};

  for (const key in mappingDict) {
    newDict[mappingDict[key]] = dict[key];
  }

  return newDict;
};

export const ltiActions = {
  fetchLtiConfigurations,
  updateLtiConfigurations,
  deleteLtiConfiguration
};
