export const npsConstants = {
  UPDATE_NPS_SUCCESS: 'UPDATE_NPS_SUCCESS',
  UPDATE_NPS_FAILURE: 'UPDATE_NPS_FAILURE'
};

const updateNpsSuccess = (response) => {
  return { type: npsConstants.UPDATE_NPS_SUCCESS, payload: response };
};

const updateNpsFailure = (error) => {
  return { type: npsConstants.UPDATE_NPS_FAILURE, payload: error };
};

export const npsDispatches = {
  updateNpsSuccess,
  updateNpsFailure
};

export const npsFields = {
  value: 'value',
  closed: 'closed',
  last_seen: 'last_seen'
};

export const NPS_REMIND_DAYS_COUNT = 2;
export const NPS_AFTER_CREATED_DAYS_COUNT = 14;
