import { messageNotification } from 'helpers';

import { dataBackupDispatches } from 'pages/dataBackup/constants';
import { dataBackupService } from 'services/dataBackup';

const fetchDataBackup = () => {
  return (dispatch) => {
    dataBackupService
      .fetchDataBackup()
      .then((dataBackup) => dispatch(dataBackupDispatches.fetchDataBackupSuccess(dataBackup)))
      .catch((error) => dispatch(dataBackupDispatches.fetchDataBackupFailure(error)));
  };
};

const downloadDataBackup = () => {
  return () => {
    dataBackupService
      .downloadDataBackup()
      .then((msg) => {
        messageNotification('success', 'Data Backup Task Success', msg.data.success);
      })
      .catch((error) => {
        messageNotification('danger', 'Data Backup Task failed', error.response);
      });
  };
};

export const dataBackupActions = {
  fetchDataBackup,
  downloadDataBackup
};
