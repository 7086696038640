export const notificationsConstants = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
  FETCH_SERVICES_NOTIFICATIONS_SUCCESS: 'FETCH_SERVICES_NOTIFICATIONS_SUCCESS',
  FETCH_SERVICES_NOTIFICATIONS_FAILURE: 'FETCH_SERVICES_NOTIFICATIONS_FAILURE',
  UPDATE_NOTIFICATION_STATUS: 'NOTIFICATION_UPDATE_STATUS',
  UPDATE_SERVICES_NOTIFICATIONS_DISPLAY_STATUS: 'UPDATE_SERVICES_NOTIFICATIONS_DISPLAY_STATUS',
  FETCH_DROPDOWN_NOTIFICATIONS_SUCCESS: 'FETCH_DROPDOWN_NOTIFICATIONS_SUCCESS',
  FETCH_DROPDOWN_NOTIFICATIONS_FAILURE: 'FETCH_DROPDOWN_NOTIFICATIONS_FAILURE'
};

const fetchNotificationsSuccess = (notifications, page_number) => {
  return {
    type: notificationsConstants.FETCH_NOTIFICATIONS,
    payload: notifications.data,
    currentPage: page_number
  };
};

const fetchNotificationsFailure = (error) => {
  return { type: notificationsConstants.FETCH_NOTIFICATIONS_FAILURE, payload: error };
};

const fetchServiceNotificationsSuccess = (notifications) => {
  return { type: notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_SUCCESS, payload: notifications.data };
};

const fetchServiceNotificationsFailure = (error) => {
  return { type: notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_FAILURE, payload: error };
};

const updateNotificationStatusSuccess = (notificationData) => {
  return {
    type: notificationsConstants.UPDATE_NOTIFICATION_STATUS,
    payload: notificationData.data
  };
};

const updateServiceNotificationDisplayStatusSuccess = (notification_id, display_status) => {
  return {
    type: notificationsConstants.UPDATE_SERVICES_NOTIFICATIONS_DISPLAY_STATUS,
    payload: { notification_id: notification_id, display_status: display_status }
  };
};

const fetchDropdownNotificationsSuccess = (notificationData) => {
  return {
    type: notificationsConstants.FETCH_DROPDOWN_NOTIFICATIONS_SUCCESS,
    payload: notificationData.data
  };
};

const fetchDropdownNotificationsFailure = (error) => {
  return { type: notificationsConstants.FETCH_DROPDOWN_NOTIFICATIONS_FAILURE, payload: error };
};

export const notificationsDispatches = {
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  fetchServiceNotificationsSuccess,
  fetchServiceNotificationsFailure,
  updateNotificationStatusSuccess,
  updateServiceNotificationDisplayStatusSuccess,
  fetchDropdownNotificationsSuccess,
  fetchDropdownNotificationsFailure
};
