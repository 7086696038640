import React, { useEffect, useState } from 'react';
import 'pages/appearance/index.scss';

import ColorPicker from './containers/appearanceContainer/components/colorPicker';
import ThemesCenter from 'pages/appearance/containers/appearanceContainer/appearanceCenter';
import { FileUploader } from './containers/appearanceContainer/components/fileUploader';
import { updateAppearanceSettings } from 'services/appearance';
import { messageNotification } from 'helpers';
import { getEdlySiteConfig } from 'services/siteConfiguration';

const Appearance = () => {
  const [primaryColor, setPrimaryColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [theme, setTheme] = useState(null);

  const handleSetPrimaryColor = (color) => {
    setPrimaryColor(color);
  };

  const handleSetSecondaryColor = (color) => {
    setSecondaryColor(color);
  };

  const handleLogoUpload = (file) => {
    setLogo(file);
  };

  const handleFaviconUpload = (file) => {
    setFavicon(file);
  };

  const handleThemeChange = (theme) => {
    setTheme(theme);
  };

  const validateColor = (color, colorName) => {
    if (!color.startsWith('#')) {
      messageNotification('danger', 'Invalid Color', `Please select a correct value for ${colorName} - ${color}.`);
      return false;
    }
    return true;
  };

  const handleSaveButton = () => {
    if (!validateColor(primaryColor, 'primary color') || !validateColor(secondaryColor, 'secondary color')) {
      return;
    }

    if (primaryColor === '' && secondaryColor === '' && !logo && !favicon && !theme) {
      messageNotification('warning', 'Nothing Updated', 'No values were changed');
      return;
    }

    const appearance_data = {
      ...(primaryColor && { primary_color: primaryColor }),
      ...(secondaryColor && { secondary_color: secondaryColor }),
      ...(logo && { logo: logo }),
      ...(favicon && { favicon: favicon }),
      ...(theme && { site_theme: theme })
    };

    updateAppearanceSettings(appearance_data)
      .then((res) =>
        messageNotification(
          'success',
          'Appearance updated',
          `The following apperance metrics have been updated:
          ${theme ? 'Site Theme: ' + theme : ''}
          ${primaryColor && 'Primary Color: ' + primaryColor}
          ${secondaryColor && 'Secondary Color: ' + secondaryColor}
          ${logo ? 'Logo' : ''}
          ${favicon ? 'Favicon' : ''}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      setPrimaryColor(res.data.lms.COLORS.primary);
      setSecondaryColor(res.data.lms.COLORS.secondary);
    });
  }, []);

  return (
    <div className="block">
      <h1>Appearance</h1>

      <div className="themes-holder">
        <div className="subheader-holder">
          <h2>Change theme</h2>
        </div>
        <ThemesCenter onUpdate={handleThemeChange} />
      </div>
      <div className="customizations-block">
        <div className="subheader-holder">
          <h2>Customizations</h2>
          <p> These customizations will appear on your selected theme</p>
        </div>
        <div className="customizations-holder">
          <h3> Upload Logo or Favicon</h3>
          <p>Your changes will appear on LMS, Studio and Discovery site.</p>
        </div>
        <div className="row">
          <div className="col-12 col-xs-6 upload-logo">
            <FileUploader
              uploadFilename="Logo"
              helpText="For best results: upload a PNG file under 250Kb with dimension 240 x 48px (5:1 ratio)"
              onUpdate={handleLogoUpload}
            />
          </div>
          <div className="col-12 col-xs-6">
            <FileUploader
              uploadFilename="Favicon"
              helpText="For best results: upload a PNG file under 250Kb with dimension 16 x 16px"
              onUpdate={handleFaviconUpload}
            />
          </div>
        </div>
      </div>

      <div className="subheader-holder">
        <div className="customizations-holder">
          <h3> Theme Colors </h3>
          <p>Your changes will appear on LMS, Studio and Discovery site.</p>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <ColorPicker color={primaryColor} text="Primary Color" onUpdate={handleSetPrimaryColor} />
          </div>
          <div className="col-12 col-md-6">
            <ColorPicker color={secondaryColor} text="Secondary Color" onUpdate={handleSetSecondaryColor} />
          </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-12 btn-holder justify-content-end d-flex">
            <button className="btn btn-primary" onClick={handleSaveButton}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appearance;
