import React from 'react';

import { Modal, Button } from 'react-bootstrap';

const TermsConditionsDetail = (props) => {
  const moment = require('moment');
  const currentDate = moment().format('YYYY-MM-DD');

  return (
    <Modal onHide={props.onHide} show={props.show} size="xl" className="modal-dialog-scrollable">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>Accept terms of service</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="contract-content">
        <h3>Master SAAS/CLOUD AND CONSULTING SERVICES AGREEMENT</h3>
        <p>
          This Master SaaS/Cloud Services and Professional Services Agreement and attached exhibits (collectively, the
          “Agreement”) is entered into by and between Edly Inc. (doing business under “Edly”) with a place of business
          at 2035 Central Cir, Suite # 201 McKinney, TX 75069 and{` `}
          <span className="underline bold">{props.inputs.businessName}</span> with a place of business at {` `}
          <span className="underline bold">{props.inputs.businessAddress}</span> and sets forth the terms and conditions
          under which Customer licenses Edly’s SaaS and/or Hosting Services and/or orders Edly’s consulting services, as
          applicable. In the event of a conflict between the terms and conditions in this Agreement and a Quote/Order or
          SOW, the terms and conditions of the Quote/Order or SOW, as applicable, will control. In consideration of the
          mutual promises contained herein, Edly and Customer hereby agree to the terms set forth in this Agreement.
        </p>
        <p>
          By signing below, the parties have caused this Agreement to be duly executed by their respective authorized
          representatives, effective as of {currentDate}.
        </p>
        <h3>EXHIBIT A</h3>
        <h4>General Terms and Conditions</h4>
        <p>
          These General Terms and Conditions (“Terms”) shall apply to the (i) Cloud Services and/or SaaS Services
          (collectively, “Hosting Services”) provided by Edly as specified in a quote or order agreement from Edly or
          Edly Reseller to Customer (“Quote”) and (ii) consulting services described in one or more Statement (s) of
          Work (“SOW”) (“Consulting Services”, collectively with “Hosting Services”, “Services”). Capitalized terms used
          in this Agreement but not defined in this Exhibit A shall have the meanings set forth in the applicable
          Exhibits. In consideration of the mutual promises contained herein, Edly and Customer hereby agree to the
          terms set forth in this Agreement.
        </p>
        <ol className="count-list">
          <li>
            <h4 className="underline">Fees, Billing and Payment</h4>
            <ol className="letters-list">
              <li>
                <span className="underline">Fees.</span> Customer shall pay Edly the fees (e.g., the set-up fees and the
                committed recurring fees) specified in the Quote or the applicable SOW, as the case may be. Unless
                otherwise specified, all invoices are due thirty (30) calendar days after the date of invoice.
              </li>
              <li>
                <span className="underline">T&L.</span> Unless otherwise expressly provided in a SOW, Customer shall
                reimburse Edly for reasonable travel and living expenses incurred by Edly personnel in the performance
                of the Consulting Services.
              </li>
              <li>
                <span className="underline">Overages.</span> Edly shall measure Customer’s usage of the Hosting Services
                (e.g., number of Users by User type, amount of inventory managed, or such other fee basis as is
                applicable to the Services purchased by Customer) on a monthly basis. If the peak usage exceeds the
                quantity purchased, Customer shall be charged and shall pay the applicable monthly fee for such excess
                usage.
              </li>
              <li>
                <span className="underline">Storage Overage.</span> Edly shall monitor Customer’s Storage. In any month
                where Customer’s peak Storage exceeds the committed Storage amount, Customer shall be charged the
                applicable fee(s) for the Storage overage according to the pricing schedule in the SOW.
              </li>
              <li>
                <span className="underline">Taxes.</span> Fees do not include sales, use, value added or any other
                excise tax. Customer shall pay or (if paid by Edly or Reseller) reimburse Edly and/or Reseller for all
                such taxes.
              </li>
              <li>
                <span className="underline">Overdue Amounts.</span> Amounts not paid when due shall be subject to
                interest at one and one-half percent (1.5%) per month or, if less, the maximum rate of interest allowed
                by law, calculated from the due date. In addition, Edly may suspend Customer’s access to the Services in
                the event that Customer is overdue in payments under this Agreement and does not make full payment of
                the amount owed within fifteen days after notice thereof by Edly.
              </li>
              <li>
                <span className="underline">Purchase Orders.</span> Other than the line items that serve to order the
                Services, in no event shall any other terms of any purchase order or other document issued by the
                Customer modify or become part of this Agreement or become binding on Edly.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Indemnification</h4>
            <ol className="letters-list">
              <li>
                Edly’s Obligation to Indemnify the Customer. Edly, at its own expense, will defend any action brought
                against Customer based on a claim that any Deliverable Item(s) and/or Hosted Software infringes a United
                States patent, copyright or trademark and, at its option, will settle any such action or will pay any
                final judgment awarded against Customer, provided that: (i) Edly shall be notified promptly in writing
                by Customer of any notice of any such claim; (ii) Edly shall have the sole control of the defense of any
                action on such claim and all negotiations for its settlement or compromise and shall bear the costs of
                the same (save where one or more of the exclusions in subsection (ii) of this Section applies); and
                (iii) Customer shall cooperate fully at Edly’s expense with Edly in the defense, settlement or
                compromise of such claim. This Section states Edly’s sole and exclusive liability, and Customer’s sole
                remedy, for any and all claims relating to infringement of any intellectual property right.
              </li>
              <li>
                Edly’s Right to Act to Prevent a Claim. If a claim described in Section 2(a) hereof occurs or, in Edly’s
                opinion, may occur, Customer shall permit Edly, at Edly’s option and expense to: (i) procure for
                Customer the right to continue using the Deliverable Item(s) or the Hosted Software through the Hosting
                Services; (ii) modify the Deliverable Item(s) or Hosted Software so that it becomes non-infringing
                without materially impairing its functionality; (iii) accept return of the applicable Deliverable
                Item(s) and grant Customer a refund of any fees paid to Edly for such Deliverable Item(s) or (iv) Edly
                terminates the Hosting Services and grants Customer a credit equal to the unused, prepaid Hosting
                Services fees paid for the applicable terminated portion of the Hosting Services. This Section 2 states
                Edly’s sole and exclusive liability, and Customer’s sole remedy, for any and all claims relating to
                infringement of any intellectual property rights.
              </li>
              <li>
                Exclusions from Edly’s Obligation to Indemnify Customer. Edly shall have no liability to Customer under
                Section 2(a) hereof or otherwise to the extent that any infringement or claim thereof is based upon: (i)
                use of the Deliverable Item(s) in combination with equipment or software not supplied hereunder where
                the Deliverable Item(s) itself would not be infringing; (ii) use of the Deliverable Item(s) in an
                application or environment for which it was not designed or not contemplated under this Agreement; or
                (ii) modification of the Deliverable Item(s) or the Hosted Software by anyone other than Edly or its
                employees or agents.
              </li>
              <li>
                Customer shall indemnify, hold harmless, and defend Edly and its Affiliates, and their respective
                directors, officers, employees, subcontractors, and agents (the “Supplier Indemnitees”) from and against
                any Claims against any Supplier Indemnitee, as well as any liabilities, damages, or recoveries payable
                to a Third Party claimant and any reasonable attorneys’ fees and costs of litigation incurred by a
                Customer Indemnitee in connection therewith, to the extent resulting from or arising out of (a)
                Customer’s breach of any warranty or other provision of the Agreement including claims related to any
                data provided by Customer to Edly; or (b) the negligence or intentional misconduct of Customer, its
                employees, subcontractors, officers, agents or representatives, except in each case to the extent caused
                by the negligence or willful misconduct of any Supplier Indemnitee or Supplier’s breach of this
                Agreement.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Confidentiality and Proprietary Information/Data Procedures/Data Processing</h4>
            <ol className="letters-list">
              <li>
                All information disclosed by either party to the other in connection with the Services and clearly
                identified as confidential or proprietary shall be safeguarded by the recipient from unauthorized
                disclosure to third parties to the same extent recipient safeguards its own proprietary or confidential
                information of like importance and in any event with not less than a reasonable degree of care. All such
                confidential information may only be disclosed to other party’s employees, officers, representatives,
                consultants or advisers (including those of a party’s affiliated companies) who need to know such
                information for the purpose of exercising its rights or carrying out its obligations under this
                Agreement. However, neither party is responsible for safeguarding information that is (i) publicly
                available, (ii) in its possession prior to the date of this Agreement, (iii) obtained by it from third
                parties without restriction on disclosure, or (iv) developed without reference to the confidential or
                proprietary information disclosed hereunder.
              </li>
              <li>
                No party shall use any other party's confidential information for any purpose other than to exercise its
                rights and perform its obligations under or in connection with this Agreement.
              </li>
              <li>
                All personal data received or collected by Edly in connection with the performance of its obligations
                will be processed in accordance with the Terms of Service and Edly’s Privacy Policy made available to
                the Client along with this Agreement. Customer acknowledges that Edly is part of a global company with
                global operations, and that personal data may be processed outside Customer’s country. All such
                transfers of personal data shall be in accordance with applicable data privacy laws. Customer certifies
                that it has obtained any personal data provided to Edly in accordance with applicable data protection
                laws and has obtained all required consents.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Disclaimer of Warranty/Limitations of Liability</h4>
            <ol className="letters-list">
              <li>
                EXCEPT AS EXPLICITLY STATED HEREIN, EDLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, WRITTEN
                OR ORAL, INCLUDING ANY WARRANTY OF SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND/OR
                NON-INFRINGEMENT, AND/OR ANY WARRANTY WITH RESPECT TO THE SECURITY OF THE HOSTING SERVICES OR THAT
                HOSTED DATA WILL NOT BE DESTROYED, LOST, INTERCEPTED, OR ALTERED BY UNAUTHORIZED PERSONS. EDLY DOES NOT
                WARRANT THAT THE OPERATION OR OTHER USE OF THE HOSTED SOFTWARE OR DELIVERABLES WILL BE UNINTERRUPTED OR
                ERROR FREE OR WILL NOT CAUSE DAMAGE OR DISRUPTION TO HOSTED DATA OR DELIVERABLES. WITHOUT LIMITING THE
                FOREGOING, AND WITH RESPECT TO THE HOSTING SERVICES, CUSTOMER WILL PROVIDE CONSENT TO IMPLEMENT ANY
                SECURITY SOLUTION, DEVICE OR FEATURE. IF CUSTOMER DOES NOT PROVIDE SUCH CONSENT IN A TIMELY MANNER, EDLY
                WILL HAVE NO LIABILITY ARISING FROM ANY SECURITY INCIDENT OR DATA LOSS THAT WOULD HAVE BEEN PREVENTED IF
                CUSTOMER HAD IMPLEMENTED A SECURITY SOLUTION, DEVICE OR FEATURE INCLUDING “PATCHES,” FIXES AND UPDATES)
                PROVIDED OR MADE AVAILABLE BY EDLY TO CUSTOMER.
              </li>
              <li>
                EXCEPT UNDER SPECIFIC WARRANTIES PROVIDED IN EXHIBITS B & C, EDLY’S AND ITS LICENSORS’ MAXIMUM AGGREGATE
                LIABILITY FOR ALL CLAIMS OR LIABILITIES ARISING HEREUNDER OR OUT OF, OR RELATING TO, THE CREATION,
                LICENSE, SUPPLY, FAILURE TO SUPPLY OR USE OF THE CONSULTING SERVICES OR HOSTING SERVICES OR OTHERWISE
                RELATING TO THIS AGREEMENT, WHETHER BASED UPON WARRANTY, CONTRACT, TORT, OR OTHERWISE, SHALL NOT EXCEED
                THE FEES PAID TO EDLY FOR THE SERVICES DURING THE TWELVE MONTH PERIOD PRIOR TO THE EVENTS THAT GAVE RISE
                TO THE APPLICABLE CLAIM. IN NO EVENT SHALL EDLY, ITS SUBSIDIARIES OR AFFILIATES, ITS LICENSORS OR ANY OF
                THEIR DIRECTORS, OFFICERS, EMPLOYEES, SUBCONTRACTORS OR AGENTS BE LIABLE FOR INDIRECT, SPECIAL,
                INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS
                INTERRUPTION, LOSS OF USE OF DATA AND ANY LOSS CAUSED BY THE INTERRUPTION, TERMINATION OR FAILED
                OPERATION OF THE INTERNET, THIRD PARTY TELECOMMUNICATION SERVICES OR THIRD PARTY SECURITY FEATURES OR
                SYSTEMS), EVEN IF EDLY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CUSTOMER AGREES NOT TO BRING
                ANY SUIT OR ACTION AGAINST EDLY AND/OR ITS DIRECTORS, OFFICERS, EMPLOYEES, SUBCONTRACTORS OR AGENTS FOR
                ANY REASON WHATSOEVER MORE THAN ONE YEAR AFTER THE CAUSE OF ACTION ARISES. THE LIMITATIONS AND
                EXCLUSIONS SET FORTH IN THIS SECTION 4 SHALL NOT APPLY TO ANY CLAIM IN RESPECT OF DEATH OR PERSONAL
                INJURY IF CONTRARY TO ANY APPLICABLE LAW.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">General</h4>
            <ol className="letters-list">
              <li>
                <span className="underline">Governing Law and Jurisdiction.</span> All disputes arising under this
                Agreement shall be governed by and construed in accordance with the laws of the State of Texas, and (ii)
                all disputes arising under, out of, or in any way connected with this Agreement shall be litigated
                exclusively in the state or federal courts situated in Dallas County, Texas
              </li>
              <li>
                <span className="underline">Force Majeure.</span> Neither Party shall be in default of its obligations
                to the extent its performance is delayed or prevented by causes beyond its control, including but not
                limited to, acts of God, acts of Customer, staff unavailability due to circumstances beyond such party’s
                control.
              </li>
              <li>
                <span className="underline">Marketing.</span> Customer agrees that while this Agreement is in effect,
                Edly shall be authorized to identify Customer as a Customer/end-user of Edly software and services (as
                applicable) in public relations and marketing materials.
              </li>
              <li>
                <span className="underline">Notices.</span> Notices under this Agreement shall be in writing and, if to
                Edly, delivered to Edly’s General Counsel, and if to Customer, to the address specified in the Quote.
              </li>
              <li>
                <span className="underline">Assignment, Waiver, Modification.</span> Customer may not assign, transfer,
                delegate or sublicense any of Customer’s rights or obligations under this Agreement without Edly’s prior
                written consent. Any such attempted delegation, assignment, transfer or sublicense shall be void and a
                breach of this Agreement.
              </li>
              <li>
                Customer acknowledges it has had the opportunity to consult and review these terms and conditions with
                legal counsel of choice prior to executing this Agreement.
              </li>
              <li>
                <span className="underline">Entire Agreement; Severability.</span> This Agreement, including any
                exhibits or schedules hereto, constitutes the entire agreement between the parties, and supersedes all
                prior discussions, representations, and understandings, with respect to the subject matter hereof. If
                any provisions of this Agreement, or the application thereof, shall for any reason and to any extent be
                determined to be invalid or unenforceable, the remaining provisions of this Agreement will remain
                binding and enforceable, and shall be interpreted so as best to reasonably effect the intent of the
                parties.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Fair Use Policy</h4>
            <p>
              Edly provides multi-tenanted SaaS solutions and seeks to ensure and maintain an optimal user experience.
              Edly is committed to an up-front, published, simple, and transparent subscription pricing model. In order
              to ensure that the provision of our services is profitable, as well as fair we monitor technical usage and
              work according to a Fair Use Policy. This Fair Use Policy has been created to prevent improper use of the
              software, so we can offer all our Customers an excellent user experience. The Fair Use Policy for our SaaS
              solution comprise storage, traffic, emails and computing requirements – this includes for example file
              storage, database size, the amount of internal and external network traffic including web traffic, the
              number of emails sent from and received by our servers, and the amount of database load and API load –
              including CPU time, memory use and queue lengths across all services.
            </p>
            <p>
              Unless otherwise specified, Edly does not charge extra costs when the tool is used reasonably and
              normally. When we determine the scope of Fair Use we take into account your contract, the observed use of
              more than a hundred end-users, technical advances and the current price rate of all necessary assets. We
              shall adjust these values when there is reasonable cause. In general, this will often mean expansion and
              raising of thresholds and reducing the cost of excessive use, as a result of lower costs of cloud storage
              and cloud computing. We will inform you in a timely fashion, when these changes affect your costs or use.
              When we detect something out of the ordinary in your Edly environment, we shall contact you to discuss the
              situation and potential alternatives including but not limited to payment of additional license fees,
              implementation of data safeguards, and/or revision of applicable terms.
            </p>
          </li>
          <li>
            <h4 className="underline">Term and Termination</h4>
            <ol className="letters-list">
              <li>
                There General Terms and Conditions shall remain in effect as long as Edly is providing Hosting Services
                and/or Consulting Services to Customer.
              </li>
              <li>These General Terms and Conditions shall survive to any termination of this agreement.</li>
            </ol>
          </li>
        </ol>
        <h3>EXHIBIT B</h3>
        <h4>Edly Cloud/SaaS Services Terms and Conditions (“Services Terms”)</h4>
        <p>
          Capitalized terms used in these Services Terms but not defined in the body of these Services Terms have the
          meanings set forth in Schedule A.
        </p>
        <ol className="count-list">
          <li>
            <h4 className="underline">Documents Making Up This Agreement</h4>
            <p>These Services Terms consists of the following documents:</p>
            <ul className="c-order-list">
              <li>The Quote</li>
              <li>The General Terms and Conditions</li>
              <li>The Cloud and SaaS Offering Specific Provisions and the Licensing Basis Document</li>
              <li>Additional/Different Cloud/SaaS Terms for Customers Outside of the United States of America</li>
              <li>These Services Terms</li>
              <li>The Edly Services Security and Support Document set forth on Schedule B.</li>
            </ul>
            <p>
              For purposes of the Cloud and SaaS Offering Specific Provisions and the Edly Services Security and Support
              Document the term “Services” shall mean “Hosting Services”. In the event of conflict between the above
              documents, the documents higher in the list above shall supersede those lower in the list.
            </p>
          </li>
          <li>
            <h4 className="underline">Hosting Services</h4>
            <ol className="letters-list">
              <li>
                <span className="underline">Hosting Services.</span> During the Service Period Edly will: (i) manage the
                Hosted Software and the Hosted Data on the Hosted System and make it available to Customer via remote
                internet access, and (ii) allow Users to access and modify the Hosted Data, and store additional Hosted
                Data, through Customer’s use of the Hosted Software. If a Quote identifies a Service or environment as
                being “developer”, “development”, “sandbox”, “demo”, “evaluation” or similar non-production service or
                environment, then the Customer will use such Service or the applicable environment only for
                non-production purposes.
              </li>
              <li>
                <span className="underline">Use of the Hosting Services. </span> Customer may access and use the Hosting
                Services only to the extent of authorizations acquired by Customer, as specified in the Quote. Customer
                is responsible for use of the Hosting Services by all Users that access the Hosting Services with
                Customer’s account credentials. Customer agrees to abide by the terms and conditions of the third- party
                Hosting Provider.
              </li>
              <li>
                <span className="underline">Use of Edly Hosting Services. </span> The Hosting Services may not be used
                for unlawful, obscene, infringing, offensive or fraudulent content or activity. If there is a complaint
                or notice of violation, use and access may be suspended until resolved. Customer shall not permit any
                third party(ies) to: (i) use the Hosting Services, or permit them to be used, or for commercial
                time-sharing or service bureau use; or (ii) copy, download or otherwise reproduce the Hosted Software in
                whole or in part.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Availability SLA</h4>
            <ol className="letters-list">
              <li>
                Edly shall monitor the availability of the Hosted System 24/7. The availability of the production
                environment(s) of the Hosting Services will be 99.5% of each calendar quarter, excluding Excused
                Downtime.
              </li>
              <li>
                Edly’s and its licensors’ entire liability and Customer’s exclusive remedy for any breach by Edly of the
                obligation in Section 3(a) shall be to credit to Customer a portion of its fees for the quarter during
                which such breach of obligation occurred, which credit shall be equal to the fees under this Agreement
                for such quarter multiplied by the Downtime Percentage. Such credit will be applied against any
                outstanding or future fees due under these Service Terms during the then current Service Period. If
                Customer fails to make a written request for a credit under this provision within ten business days
                after the end of the applicable Transaction Outage, no credit shall be due to Customer.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Ownership</h4>
            <p>
              Ownership of the Hosted Software and Hosting Services, any related documentation, copies, modifications
              and derivatives of the foregoing or documentation (in whole or in part), and all related copyright,
              patent, trade secret and other proprietary rights, are and will remain the exclusive property of Edly
              and/or its licensors. Customer shall not and shall not attempt to (a) reverse engineer the Hosted Software
              or derive its algorithms from its use; (b) created derivative works of the Hosted Software; or (c)
              authorize or permit a third party to access the Hosting Services using Customer issued logins or
              passwords. All non-public information regarding the Hosted Software and its performance, including any
              analyses and benchmarking that Customer may perform, shall be deemed the confidential information of Edly
              and Customer shall not disclose to any third party or use for any purpose other than exercising its rights
              hereunder.
            </p>
          </li>
          <li>
            <h4 className="underline">Hosted Data</h4>
            <ol className="letters-list">
              <li>
                Unless otherwise agreed in an SOW, Edly has no ownership in all Hosted Data provided by Customer or
                Customer’s users. Edly shall take commercially reasonable steps, or cause such commercially reasonable
                steps to be taken, designed to prevent security breaches. Customer agrees that the Hosted Data will not
                include: (i) any information, or documents or technical data that are classified, Controlled
                Unclassified Information, ITAR controlled or otherwise have been determined by the United States
                Government or by a foreign government to require protection against unauthorized disclosure for reasons
                of national security (provided, however, that this restriction shall not apply with respect to ITAR
                controlled data if the Quote so specifies), and/or (ii) any data that is “protected health information,
                including any medical, demographic, visual or descriptive information that can be used to identify a
                particular patient/individual” and/or any other data subject to the U.S. “Health Insurance Portability &
                Accountability Act of 1996” and regulations promulgated under that Act (collectively “HIPAA”).
              </li>
              <li>
                Edly shall treat all Hosted Data as confidential and shall only use the Hosted Data to (i) provide the
                Hosting Services (including reporting to Customer on their use of the Hosting Services), (ii) monitor
                Customer’s use of the Hosting Services for security and technical support purposes and for validating
                Customer’s compliance and usage limitations, and for purposes of otherwise complying with Edly’s
                obligations to Customer, and (iii) share with any Edly subcontractors who need to know such information
                in order to provide the Hosting Services, provided that they are bound by similar confidentiality
                obligations. For purposes of clarity, Edly’s obligation to keep such Hosted Data confidential shall not
                apply to information that Edly is required to disclose by law (but only to the extent of such required
                disclosure). Customer warrants and represents that it is the lawful owner of or has the requisite
                consent for use of its Hosted Data.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">EDLY API Clauses</h4>
            <ol className="letters-list">
              <li>
                API shall refer to any available Edly Application Programming Interface (“API”) and any accompanying or
                related documentation, source code, executable applications and other materials made available by Edly,
                including, without limitation, through its applications and website. APIs allow the Customer to access
                Edly sites and services and Edly Content, to develop Applications that integrate with or facilitate the
                use of Edly’ sites and services.
              </li>
              <li>
                Customer’s use of the Edly’s API is permitted only for the purpose of facilitating or optimizing
                Customer or Customer Users’ use of Edly’ sites and services.
              </li>
              <li>
                Edly grants Customer a non-exclusive, non-transferable, and non-sublicensable license to use the API
                solely to develop, test and support an integration of Customer’s application.
              </li>
              <li>
                Edly will provide you with Access Credentials that permit Customer to access Edly’s Customer-specific
                databases and applications. The Access Credentials are the property of Edly and may be revoked if
                Customer share them with any third party (other than as allowed under this Agreement), if they are
                compromised, or if Edly determines the API calls to be adversely affecting the Edly Services, if
                Customer violates any term of this Agreement, or if Edly terminates this Agreement.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Term and Termination</h4>
            <ol className="letters-list">
              <li>
                The initial Services Period and any renewal provisions shall be as specified in the Quote. Either party
                may terminate the Hosting Services if the other party breaches the terms set forth herein or in the
                Quote and fails to remedy such breach within thirty (30) days after written notice thereof from the
                non-breaching party.
              </li>
              <li>
                In case of early termination without cause, the Customer will be charged early termination fees
                calculated as sixty percent (60%) of the service fees for the remaining period.
              </li>
              <li>Sections 5 through 8 shall survive termination or expiration of the Hosting Services.</li>
              <li>
                Unless Customer notifies Edly of cancellation 30 days before the end of term, Services will auto-renew
                at the end of the term specified in the SOW for another term.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Warranty</h4>
            <p>
              Edly warrants that the Hosted Software shall function substantially in accordance with the then applicable
              documentation accompanying the Hosted Software. In the event of any breach of this warranty, Edly’s sole
              obligation, and Customer’s sole remedy, shall be for Edly to use commercially reasonable efforts to (a)
              fix or replace the Hosted Software so that it conforms to this warranty; (b) identify or make available a
              work-around or alternative approach that achieves substantially the same result or functionality; or, if
              Edly fails to resolve as described in (a) or (b) above, then for Edly to terminate this Agreement and
              refund Customer the monthly or other recurring Service fees paid by Customer for the period after the date
              Customer reported such breach of warranty to Edly.
            </p>
          </li>
          <li>
            <h4 className="underline">Export</h4>
            <p>
              Customer hereby warrants and represents that neither Customer nor any Users are listed on the U.S.
              Commerce Department’s Denied Persons List, Entity List, or Unverified List, the U.S. State Department’s
              Nonproliferation Sanctions List, or the U.S. Treasury Department’s List of Specially Designated Nationals
              and Blocked Persons or the Sectoral Sanctions Identifications (SSI) List (each a “List”, and collectively
              the “Restricted Party Lists”). The Restricted Party Lists can be found at: (
              <a href="http://export.gov/ecr/eg_main_023148.asp" target="_blank" rel="noreferrer">
                http://export.gov/ecr/eg_main_023148.asp
              </a>
              )
            </p>
            <p>
              Customer shall not export or re-export, directly or indirectly, or provide to any other person or entity
              for export or re-export, or provide access to, the Hosting Services without first complying with all U.S.
              and applicable foreign export control regulations, including, without limitation, obtaining any necessary
              export or re-export consent from the U.S. Department of Commerce or other governmental authority.
            </p>
          </li>
        </ol>
        <h4>Schedule A - Definitions</h4>
        <p>
          “Active User(s)” is a user who is counted only when he visits the courseware or the discussions inside a
          course one or more times.
        </p>
        <p>
          “Cloud Services” means Edly providing hosting services so that the Customer may access the Hosted Software via
          the internet, along with certain IT administration and application administration support services, as
          specified in this Agreement. For Cloud Services, the Customer is purchasing (or previously purchased) the
          licenses for the Hosted Software (under a separate Edly Customer Agreement or other software license agreement
          between the parties) which are being made available to the Customer via the Hosted System, and the Customer
          purchases support for such Hosted Software (either directly or as part of a subscription license).
        </p>
        <p>
          “Designated Technical Support Personnel” means the technical contacts that are trained subject matter experts
          on the Hosted Software and who have been identified in writing to Edly by Customer.
        </p>
        <p>
          The “Downtime Percentage” shall equal the result obtained by dividing (1) the aggregate number of minutes of
          Transaction Outages during such a quarter by (2) the aggregate number of minutes in such a quarter.
        </p>
        <p>“Excused Downtime” means any and all of the following:</p>
        <ul className="c-order-list">
          <li>Force majeure events as Exhibit A of this Agreement.</li>
          <li>
            Data transmission failures outside the control of Edly not caused by Edly’s negligence or willful
            misconduct.
          </li>
          <li>
            Downtime resulting from applications developed for or by the Customer that are running on or interacting
            with the Hosted System.
          </li>
          <li>
            Downtime resulting from third party software utilized by the Customer that is not Hosted System and/or
            third-party software integrations developed by or for Customer.
          </li>
          <li>Downtime due to failure of the internet or failure of Customer’s network.</li>
          <li>
            Maintenance outages (including emergency maintenance outages), for which Edly will endeavor to give Customer
            as much notice as is reasonably practicable under the circumstances.
          </li>
        </ul>
        <p>
          “Hosted Data” means the data transmitted to, loaded into, or stored in, the Hosted Software or on the Hosted
          System by Customer and Users or otherwise through the use of the Hosted Software.
        </p>
        <p>
          “Hosted Software” means the software solution for which Edly is providing the Hosting Services including any
          custom solutions implemented or integrated by Edly, as specified in the Quote and any future Scopes of Work.
        </p>
        <p>
          “Hosted System” means the servers and computer network on which Edly and/or its contractors provide Customer
          and other Edly Customers remote access to the Hosted Software and Hosted Data.
        </p>
        <p>
          “Hosting Provider” means the third-party cloud hosting provider that Edly will use to provide its SaaS
          Services for the Customer including but not limited to AWS or Azure. These providers may have additional terms
          and conditions that apply to their services.
        </p>
        <p>“Edly” means, as applicable, Edly Inc</p>
        <p>
          “SaaS Services” means Edly providing SaaS services so that the Customer may access the Hosted Software via the
          internet, along with certain IT administration and application administration support services, as specified
          in this Agreement. For SaaS Services, the Customer does not purchase (and has not previously purchased) the
          licenses for the Hosted Software being made available to the Customer as a service via the Hosted System.
        </p>
        <p>
          “Service Period” means the term that Edly commits to perform the Hosting Services (i.e., the initial term and
          any renewal terms), per Section 6 of these Services Terms.
        </p>
        <p>“Storage” means the amount of disk storage used by the Customer across the applicable environment.</p>
        <p>
          “Transaction Outage” means any period (measured in minutes) during which the Hosted System, and the
          connections for such servers to the Internet, do not have adequate bandwidth capacity and speed to meet the
          peak demands of Users accessing the Hosted Software and Hosted Data, resulting in such Permitted Users being
          denied access or experiencing unavailable or interrupted access due to lack of capacity or speed of the Host
          Servers or of the Host Servers’ Internet connections, excluding Excused Downtime.
        </p>
        <p>
          “User” means persons who are employees or consultants of either Customer or of a subcontractor, supplier,
          business partner, or Customer of Customer, and whom Customer authorizes to access the Hosted Software and
          Hosted Data.
        </p>
        <h3>Schedule B - Edly Cloud/SaaS Security and Support Document</h3>
        <p>
          This document is incorporated by reference into the Edly Cloud/SaaS Services Terms and Conditions. All
          capitalized terms used in this document are as defined in the Edly Cloud/SaaS Services Terms and Conditions.
        </p>
        <ol className="count-list">
          <li>
            <h4 className="underline">Security</h4>
            <p>
              Edly uses third party Hosting Providers’ that provide reasonable industry measures designed to protect the
              operating environment of the Services and Hosted Software against unauthorized physical access and the
              threats of fire, power, temperature, humidity and other physical forces.
            </p>
          </li>
          <li>
            <h4 className="underline">Additional Security Measures for Hosted Data</h4>
            <p>In addition to Hosting Providers’ protections, Edly provides the following additional measures:</p>
            <ul className="c-order-list">
              <li>Hosted Data transmitted to the Hosted System is encrypted.</li>
              <li>
                Hosted Data is stored on S3. Daily backups of the production system will be retained for at least one
                week, and a weekly backup will be retained for at least one month.
              </li>
              <li>
                Services and Hosted Data are protected with several layers of security, including routing, service
                access separation, user access control, and auditing.
              </li>
              <li>Edly maintains controls consistent with the ISO 27001 framework.</li>
              <li>The Hosted Data is maintained in secure directories that require access authentication.</li>
            </ul>
            <p>In order to enhance data security, Customer is responsible for:</p>
            <ul className="c-order-list">
              <li>Ensuring it uses utmost discretion in granting administrator privileges. </li>
              <li>
                Ensuring that its Users do not share their passwords and that such Users implement adequate password
                practices and safeguards.
              </li>
              <li>Designing, authoring, validating, and approving all custom reports.</li>
              <li>
                Ensuring and maintaining security of its systems and the machines that connect to and use the Hosted
                System, including implementation of necessary patches and operating system updates.
              </li>
            </ul>
            <p>
              The scope of the Services does not include any Customer security requirements beyond those set forth in
              the Edly Cloud/SaaS Services Terms and Conditions. Customer hereby agrees that it will not and will not
              engage or authorize any third party to perform any penetration testing of the Hosted System without
              obtaining Edly’s prior written permission.
            </p>
          </li>
          <li>
            <h4 className="underline">Support</h4>
            <ul className="c-order-list">
              <li>
                <span className="underline">Support Hours.</span> Edly’s technical staff is available for support and
                troubleshooting by email or phone 24x7 for Severity 1 and 2 issues. For other issues, email the
                Customer’s Designated Technical Support Personnel, excluding public holidays and other Edly-observed
                holidays. E-mail inquiries and cases are accepted 24x7.
              </li>
              <li>
                <span className="underline">Designated Employees.</span>Customer’s Designated Technical Support
                Personnel shall be the only persons authorized to contact Edly via its support line in connection with
                Services. When Edly provides notification of scheduled outages, such Designated Technical Support
                Personnel shall promptly relay such notification in a timely manner to all of Customer’s Users.
              </li>
              <li>
                <span className="underline">Support Process.</span> The support process begins when Customer notifies
                Edly’s technical staff of an issue with which Customer requires assistance and opens a case. Customer
                will provide the following information in order to ensure the issue may be correctly and efficiently
                assessed and resolved: without limitation, the Customer name, case number and a detailed description of
                the issue. Edly shall assign a severity level to an issue upon initiation of the case based on the
                defined severity levels described below in this document. Target resolution times are set forth below.
                Edly shall determine, at its discretion, based on availability of staff and experience, whether to
                allocate Customer’s case to a named individual. In order to ensure continuity of service and
                professional call handling, Edly shall use reasonable efforts not to reassign technical staff members
                once they have been assigned to resolving a particular problem.
              </li>
              <li>
                <span className="underline">Prioritization and Escalation.</span>Edly shall use commercially reasonable
                efforts to resolve each significant issue by providing a Workaround, an object code patch or a specific
                action plan for how Edly will address the issue, and an estimate of how long it will take for the issue
                to be resolved. “Workaround” means a change in the procedures followed or that Customer supplies to
                avoid a problem in the hosted environment without substantially impairing Customer’s use of the Hosted
                Software. A Workaround may be either temporary or permanent in nature.
              </li>
              <li>
                <span className="underline">New Releases.</span>Cloud Services support shall only apply if and for so
                long as Customer’s underlying licenses of the Hosted Software are current on Edly Support. Subject to
                the exclusions set forth below, Services will include installation of New Releases of the Hosted
                Software. In single-tenant environments, Edly will coordinate the installation of the New Releases with
                Customer to reasonably minimize disruption to Customer’s operations. However, Edly will have no
                liability arising from any technical problem, security incident or data loss that would likely have been
                prevented if a New Release and/or a patch, fix or update had been made but the Customer requested a
                delay in the implementation of the same. It is the Customer's responsibility to test and validate their
                custom developed, created, or 3rd party applications in a test environment prior to moving them into
                production. This includes making any necessary modifications to those applications to be supported with
                the New Release. “New Release” means updates and/or new releases to the Hosted Software that Edly elects
                to apply to the Hosted Software. The entitlement to New Releases is not included with Cloud Services
                unless Customer’s underlying licenses are covered by a Edly Support services plan.
              </li>
              <li>
                <span className="underline">Maintenance Outage Delay.</span>Within 24 hours of Edly’s notice of a
                Maintenance outage, Customer may request in writing to Edly that such Maintenance outage be delayed due
                to extenuating Customer business needs; provided, that Edly shall not be required to delay any outage if
                such outage is required due to a security issue or to avoid the potential of an unplanned outage. In the
                event that Edly agrees to Customer’s request, such delay shall not exceed ten (10) business days.
              </li>
              <li>
                <span className="underline">Exclusions.</span> Unless otherwise stated, Services support does not
                include:
                <ul className="c-order-list">
                  <li>Support on Customer’s site;</li>
                  <li>
                    Design, code development, break-fixes, or testing of integrations, customizations and/or
                    modifications;
                  </li>
                  <li>Third party software included in the Services;</li>
                  <li>Customer developed and/or owned applications;</li>
                  <li>Issues caused by material changes to the configuration of the Hosted Software by Customer;</li>
                  <li>Errors caused by Customer’s negligence or fault;</li>
                  <li>Consulting or training services; or</li>
                  <li>
                    Responsibility for changes to or replacement of any Customer hardware that may be necessary to use
                    the Hosted Software due to a Workaround, fix or Hosted Software New Release.
                  </li>
                </ul>
              </li>
              <p>
                In addition to the above exclusions, where Edly is providing Cloud Services for licenses of the Hosted
                Software that Customer owns (as opposed to SaaS Services), the support for the underlying licenses must
                be purchased by Customer under the License Agreement. If such licenses are not on an active support
                contract, Customer will not be entitled to such Support under this Agreement.
              </p>
              <li>
                <span className="underline">Severity, Initial Response Times and Resolution Targets:</span> Case
                severity levels and associated initial response and estimated resolution times are as set forth below:
              </li>
            </ul>
            <table className="table tos-table tos-custom-table">
              <colgroup>
                <col className="tos-first-col" />
              </colgroup>
              <tr>
                <th>Severity Level</th>
                <th>Definition</th>
                <th>Initial Response</th>
                <th>Target Resolution Time*</th>
              </tr>
              <tr>
                <td>Severity 1 - Hosting Problem</td>
                <td>
                  A problem in the Hosted System that causes substantial downtime of the Hosted System with no viable
                  Workaround available. Generally requires 24X7 availability of Customer’s Designated Technical Support
                  Personnel. Edly will work 24X7 until the issue is resolved or the Severity is lowered.
                </td>
                <td>1 Hour</td>
                <td>4 Hours</td>
              </tr>
              <tr>
                <td>Severity 2 - Hosting Problem</td>
                <td>
                  A problem in the Hosted System that results in the loss of critical functions of the Hosted Software
                  or a limited number of Users cannot access the Hosted Software via the Services.
                </td>
                <td>3 Hours</td>
                <td>12 Hours</td>
              </tr>
              <tr>
                <td>Severity 3 - Hosting Problem</td>
                <td>
                  A problem in the Hosted System that impacts Services operations and/or efficiency but Customer is
                  still able to use the Hosted Software. A Workaround is generally available.
                </td>
                <td>1 Business Day</td>
                <td>2 Business Days</td>
              </tr>
              <tr>
                <td>Severity 4 - Hosting Problem</td>
                <td>
                  A technical question about the Services or the Hosted System that does not impact Customer’s use of
                  the Hosted Software.
                </td>
                <td>2 Business Days</td>
                <td>5 Business Days</td>
              </tr>
            </table>
            <h4>Note :-</h4>
            <ul className="c-order-list">
              <li className="dash">
                Severity 1 and Severity 2 problems can be communicated to Edly by emailing at support+critical@edly.io.
              </li>
              <li className="dash">
                Severity 3 and Severity 4 problems can be communicated to Edly by emailing the designated technical
                support personnel.
              </li>
              <li className="Asterisk">
                Target resolution times are measured from Edly’s initial response and reflect the target resolution
                times for hosting problems only, based on Edly using diligent efforts to return Customer to production
                status.
              </li>
            </ul>
          </li>
          <li>
            <h4 className="underline">Uptime Percent Calculation</h4>
            <p>
              SLA’s are measured by attempting to access the Service from locations throughout the world. The Service
              access confirms front end (user interface) and back end (database) availability. If the Service is
              accessible, it is considered up.
            </p>
            <p>
              If the Service is not accessible, it is considered down. A Customer’s Service Uptime Percentage is
              calculated quarterly using the formula:
            </p>
            <table className="table tos-table">
              <tr>
                <td>Uptime Percentage</td>
                <td>
                  (Minutes in the Quarter – Minutes of Excused Downtime – Minutes of Unexcused Downtime) / (Minutes in
                  the Quarter – Minutes of Excused Downtime)
                </td>
              </tr>
            </table>
            <p>Excused Downtime is a non-availability of the Service due to any of the causes listed below:</p>
            <ul className="c-order-list">
              <li>Force majeure events. </li>
              <li>
                Data transmission failures outside the control of Edly not caused by Edly’s negligence or willful
                misconduct.
              </li>
              <li>
                Downtime resulting from applications developed for or by Customer that are running on or interacting
                with the Hosted System.{' '}
              </li>
              <li>
                Downtime resulting from third party software utilized by the Customer that is not Hosted System and/or
                third-party software integrations developed by or for Customer.
              </li>
              <li>Downtime due to failure of the internet or Customer’s network. </li>
              <li>
                Maintenance outages (including emergency maintenance outages), for which Edly will endeavor to give
                Customer as much notice as is reasonably practicable under the circumstances:
                <ul className="c-order-list">
                  <li>Downtime due to Hosting Provider failure.</li>
                  <li>
                    Downtime due to Customer’s actions or OS/Application changes of the Hosted System not due to bugs in
                    Software.
                  </li>
                  <li>Downtime while Hosted Data is being restored.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h4 className="underline">Backups</h4>
            <p>
              The table below lists the backups for all hosted Edly instances (unless otherwise noted in the Customer’s
              contract) or specified in the application specific Service Description.
            </p>
            <table className="table tos-table">
              <colgroup>
                <col className="tos-first-col" />
              </colgroup>
              <tr>
                <th>Service Type</th>
                <th>Backup Frequency </th>
                <th>Backup Retention Duration</th>
              </tr>
              <tr>
                <td rowSpan="2">Production</td>
                <td>Local every 24 hours </td>
                <td>Local 30 days </td>
              </tr>
              <tr>
                <td>Remote replicated within 24 hours of the backup</td>
                <td>Remote 0 days </td>
              </tr>
              <tr>
                <td rowSpan="2">Non-Production (ex: development, QA, training) </td>
                <td>Local every 24 hours</td>
                <td>Local 30 days</td>
              </tr>
              <tr>
                <td>No remote backup </td>
                <td>None</td>
              </tr>
            </table>
            <p>
              Local backups are stored at the same location as the running instance. Remote backups stored at a location
              outside of the primary data center’s disaster radius and are typically utilized for disaster recovery.
            </p>
            <p>
              If the Service is terminated, data will be archived for 30 days from the date of termination. After the
              30-day period, the data will be destroyed and cannot be recovered. The Customer can request a copy of the
              archived data prior to or during the 30-day period. The request should be made through email (with
              confirmation of receipt) to your Edly Service Manager. Recovered databases may contain URLs and file paths
              to locations within the Edly Cloud that are no longer accessible by the Customer.
            </p>
            <h4>Data Recovery and Data Recovery Requests</h4>
            <p>
              Backup recovery is required when data is either corrupted or deleted; or for specific application/project
              requests. It should be noted that data recovery can take anywhere from minutes to hours based on the type
              of data. A file can usually be restored quickly; however, a full database may take hours.
            </p>
            <h4>Customer Data Recovery Requests</h4>
            <p>
              Data recovery (restoration of backed up data or database usually due to accidental deletion) requires a
              special request and is not considered part of normal Service delivery. The Service includes one
              Customer-initiated data recovery event per year. For each additional request, the Customer will be charged
              standard Services rates.
            </p>
            <p>Note: All Edly Cloud initiated recoveries to manage delivery are included in the Service.</p>
          </li>
          <li>
            <h4 className="underline">Business Continuity and Disaster Recovery</h4>
            <p>
              A disaster scenario will be evaluated if the primary Service Delivery is down and is not expected to be
              restored within a minimum of 48 hours. The actual Service downtime before declaring a disaster switchover
              will likely be greater than 24 hours.
            </p>
            <p>
              The Disaster Recovery process is tested on a yearly basis by invoking the recovery plan for a
              representative test environment. The testing does not introduce faults into the production environment,
              nor does it require downtime of production environments. The test is proctored by the Disaster Recovery
              Group Lead.
            </p>
            <p>
              The table below lists the Recovery Time and Point Objectives (RTO and RPO) and backups for all hosted Edly
              instances (unless otherwise noted in the Customer’s contract) or specified in the application specific
              Service Description. Disaster recovery only covers Production instances.
            </p>
            <table className="table tos-table">
              <colgroup>
                <col className="tos-first-col" />
              </colgroup>
              <tr>
                <th>Systems </th>
                <th>Recovery Point Objective </th>
                <th>Recovery Time Objective </th>
              </tr>

              <tr>
                <td>Production</td>
                <td>24 hours</td>
                <td>5 business days</td>
              </tr>

              <tr>
                <td>Development, QA, others</td>

                <td>None</td>
                <td>None</td>
              </tr>
            </table>
          </li>
        </ol>
        <h3>EXHIBIT C</h3>
        <h4> Consulting Services Terms and Conditions (“Consulting Terms”)</h4>
        <ol className="count-list">
          <li>
            <h4 className="underline">Assignment of Edly Personnel and Customer Participation</h4>
            <ol className="letters-list">
              <li>
                Edly reserves the right to (i) determine the assignment of Edly personnel for performance of Consulting
                Services, (ii) replace or reassign such personnel; and/or (iii) subcontract with qualified third persons
                for part or all of the performance of the Consulting Services required under a Statement of Work. No
                person performing Services on behalf of Edly hereunder shall be restricted or prevented from performing
                services for others that are similar to the Services provided under this Agreement.
              </li>
              <li>
                Customer shall reasonably cooperate with Edly to facilitate Edly’s performance of Consulting Services,
                including providing Edly timely access to Customer’s computer systems, personnel, facilities, data and
                other information reasonably necessary to the performance of the Consulting Services.
              </li>
              <li>
                During the term of a Statement of Work and for two (2) year after the expiration or termination thereof,
                neither party will solicit for employment (or solicit to perform services in another capacity), the
                other party’s personnel who have performed Consulting Services pursuant to such Statement of Work;
                provided, however, that this Section 1(c) shall not prohibit either party from hiring any individual who
                applies for employment in response to a general solicitation for employment not specifically directed to
                the other party’s employees.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Deliverable Items; Acceptance</h4>
            <p>
              For Deliverables specifically identified in a Statement of Work as being subject to acceptance by the
              Customer (each a “Deliverable Item”), Customer agrees that the following review and acceptance process
              shall apply:
            </p>
            <ol className="letters-list">
              <li>
                The acceptance criteria shall be set forth in the relevant Statement of Work (“Acceptance Criteria”).
                Acceptance reviews are to be conducted within the framework of the Acceptance Criteria and not as an
                opportunity for re-defining Customer’s requirements.
              </li>
              <li>
                Customer shall accept each Deliverable Item when it meets the Acceptance Criteria. Customer shall have a
                maximum period of ten (10) business days after delivery of a Deliverable Item (the “Evaluation Period”)
                to verify that the Deliverable Item meets the Acceptance Criteria.
              </li>
              <li>
                If a Deliverable Item materially fails to meet the Acceptance Criteria (a “Nonconformity”) and Customer
                provides written notice to Edly prior to expiration of the Evaluation Period, specifying the manner in
                which the Deliverable Item materially fails to meet the Acceptance Criteria, Edly shall use reasonable
                efforts to correct such Nonconformity, or proceed on another mutually acceptable basis. Upon Edly’s
                delivery of the revised Deliverable Item, Customer shall have an additional five (5) business days
                (“Verification Period”) commencing upon Edly’s delivery of the revised Deliverable Item to verify that
                the previously reported Nonconformity has been corrected.
              </li>
              <li>
                The process for review and verification described in this section shall be repeated until the
                Deliverable Item meets the Acceptance Criteria in all material respects or the parties agree upon some
                other resolution. Unless Edly receives a notice of Nonconformity as provided above, the Deliverable
                Items shall be deemed accepted upon expiration of the applicable Evaluation Period or the Verification
                Period as the case may be.
              </li>
              <li>
                Any milestone or fixed deliverable payment paid to Edly for a Deliverable Item or otherwise is
                non-refundable.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">ITAR</h4>
            <p>
              Customer shall, prior to disclosing to Edly any data that is subject to International Traffic in Arms
              Regulations (“ITAR”) or other export control requirements, notify Edly that such information is subject to
              such regulations or requirements, and follow such data transfer procedures as reasonably requested by
              Edly.
            </p>
          </li>
          <li>
            <h4 className="underline">Rights in Work Product</h4>
            <ol className="letters-list">
              <li>
                Any pre-existing intellectual property owned by either party shall remain the property of that party and
                the other party shall obtain no ownership rights therein.
              </li>

              <li>
                “Work Product” means any materials and any intellectual property rights embodied therein that are made,
                conceived, written, created, developed, reduced to practice and/or delivered by Edly in the course of
                providing Consulting Services hereunder, including without limitation any reports, computer software
                and/or software documentation created developed and/or delivered by Edly. Edly shall have exclusive
                ownership of the Work Product, including exclusive ownership of any copyright thereto and of any
                inventions, discoveries, improvements, ideas, techniques and/or know-how embodied therein.
              </li>

              <li>
                Edly grants to Customer, subject to receipt of payment by Customer and the other terms and conditions of
                this Agreement, a nonexclusive, perpetual, non-transferable right and license to use such Work Product
                solely for Customer’s internal use purposes. This license shall include the right to use, prepare
                derivative works based upon for internal use and, with respect to documentation, copy and distribute
                internally only and not to third parties, such Work Product.
              </li>
              <li>
                Notwithstanding the foregoing or anything to the contrary in this Agreement, and unless otherwise
                specified in a mutually agreed SOW: (a) Edly will not provide source files for any custom training
                materials developed and/or delivered by Edly under this Agreement, and (b) Customer will have no right
                whatsoever to (i) make additional copies (other than a reasonable number of backup copies) of any
                training materials and/or custom training materials developed and/or delivered by Edly under this
                Agreement, (ii) prepare derivative works based upon any such materials, or (iii) distribute such
                materials to any third parties.
              </li>
              <li>
                Without limiting Edly’s ownership rights in the Work Product, Edly expressly retains the right to use
                and provide to third parties in the course of its business copies or portions of the Work Product or
                works derived from the Work Product, exclusive of any confidential or proprietary information of
                Customer subject to Section 3 of Exhibit A. Edly shall not be precluded in any way from using any
                generalized knowledge or expertise that Edly acquires during performance of the Consulting Services.{' '}
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Warranty/Disclaimer</h4>
            <ol className="letters-list">
              <li>
                Where a Statement of Work states Consulting Services are provided on a time and materials basis, Edly
                warrants that such Consulting Services shall be performed with reasonable care and skill in accordance
                with industry standards. Edly’s entire liability and Customer’s exclusive remedy for any breach by Edly
                of the foregoing warranty shall be that Edly shall use commercially reasonable efforts to correct and/or
                re-perform the deficient Consulting Services provided that Customer notifies Edly in writing of the
                deficient Consulting Services within thirty (30) days of Edly’s original performance of such Consulting
                Services.
              </li>
              <li>
                For any Deliverable Item(s) that Edly is required to deliver pursuant to a Statement of Work, Edly
                warrants that such Deliverable Item(s) shall operate in substantial conformity with the relevant
                specifications set forth in the applicable Statement of Work for a period of thirty (30) days following
                Customer’s acceptance of such Deliverable Item(s) pursuant to Section 2 of these Consulting Terms
                (“Warranty Period”). Edly's entire liability and Customer's exclusive remedy for any breach by Edly of
                the foregoing warranty shall be, at Edly's sole discretion, either to replace the nonconforming
                Deliverable Items(s) or to use diligent efforts to repair the nonconforming Deliverable Items(s)
                provided in each case that Customer notifies Edly in writing of the alleged nonconformity during the
                foregoing Warranty Period, specifying as to each nonconformity how the Deliverable Item materially fails
                to conform to the relevant specifications.
              </li>
              <li>
                EDLY DOES NOT WARRANT THAT THE OPERATION OF ANY HOSTED SOFTWARE PROVIDED HEREUNDER WILL BE UNINTERRUPTED
                OR ERROR FREE. THERE IS NO EXPRESSED OR IMPLIED OBLIGATION ON THE PART OF EDLY TO MAINTAIN OR SUPPORT
                ANY ITEMS PROVIDED TO Customer HEREUNDER.
              </li>
              <li>
                CUSTOMER IS RESPONSIBLE FOR CREATING AND MAINTAINING CURRENT AND COMPLETE BACK-UP FILES FOR ANY CUSTOMER
                DATA AND PROGRAMS THAT MAY BE AFFECTED BY EDLY’S PERFORMANCE OF THE CONSULTING SERVICES. EDLY SHALL NOT
                BE RESPONSIBLE FOR THE PROTECTION OR LOSS OF CUSTOMER DATA OR INFORMATION. CUSTOMER SHALL NOT BRING ANY
                SUIT OR ACTION AGAINST EDLY OR ANY OF ITS SUBSIDIARIES OR THEIR DIRECTORS, OFFICERS OR EMPLOYEES FOR ANY
                REASON WHATSOEVER MORE THAN ONE YEAR AFTER THE CAUSE OF ACTION ARISES.
              </li>
              <li>
                The parties acknowledge that the charges and fees hereunder are based in part on the limited warranty
                and limitation of liability set forth above.
              </li>
              <li>
                Neither party shall be in default of its obligations to the extent its performance is delayed or
                prevented by causes beyond its control, including but not limited to acts of God, acts of Customer, acts
                of third parties not under such party’s control, and staff unavailability due to illness or other
                circumstances beyond such party’s control.
              </li>
            </ol>
          </li>
          <li>
            <h4 className="underline">Term and Termination</h4>
            <ol className="letters-list">
              <li>
                Either party may terminate any Consulting Statement of Work upon thirty (30) days prior written notice
                to the other in the event that the other party fails to perform any of material obligations hereunder,
                unless such other party cures such failure within such notice period. Edly may immediately suspend its
                performance under these terms in the event Customer fails to pay any Edly invoice when due.
              </li>
              <li>
                Subject to Section 6(d) below, any Consulting Statement of Work issued hereunder may be terminated for
                convenience (i.e., without cause) by either party upon thirty (30) days written notice to the other.
              </li>
              <li>
                Except as provided in Section 6(d), upon termination of a Statement of Work, all applicable fees
                accruing up to and including the effective date of termination, together with all reasonable costs and
                expenses incurred by Edly in connection therewith, shall be immediately due and payable.
              </li>
              <li>
                If Customer elects to terminate a Fixed Price Statement of Work or one for which a fixed fee is payable
                then, notwithstanding such termination, Edly shall be entitled to receive the total fixed fee set forth
                in the Statement of Work, except solely where the Statement of Work is terminated pursuant to Section 6
                (a) above based on Edly’s material and uncured failure to perform its material obligations hereunder.
              </li>
              <li>Sections 1, 4, 5, and 6 shall survive termination of any Statement of Work.</li>
            </ol>
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <span className="top-text">Accepting these terms and conditions are equivalent to a signature.</span>
        <Button variant="primary" onClick={props.handleAccept}>
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsConditionsDetail;
