import React, { Fragment } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppRoute from 'components/routes/AppRoute';
import PublicRoute from 'components/routes/PublicRoute';
import ProtectedLayout from 'components/layouts/ProtectedLayout';
import PublicLayout from 'components/layouts/PublicLayout';
import Dashboard from 'pages/dashboard';
import Notifications from 'pages/notifications';
import Insights from 'pages/insights';
import Settings from 'pages/settings';
import Help from 'pages/help';
import Users from 'pages/users';
import SeeAllApps from 'pages/seeAllApps/index';
import Login from 'pages/login';
import Page401 from 'pages/401/index';
import Page404 from 'pages/404/index';

const Routes = (props) => {
  return (
    <Fragment>
      <ReactNotifications />
      <Switch>
        <Route exact path="/">
          <Redirect to="/panel/dashboard" />
        </Route>
        <Route exact path="/panel">
          <Redirect to="/panel/dashboard" />
        </Route>
        <AppRoute
          path="/panel/dashboard"
          component={Dashboard}
          layout={ProtectedLayout}
          panel_role={props.panel_role}
        />
        <AppRoute
          path="/panel/notifications"
          component={Notifications}
          layout={ProtectedLayout}
          panel_role={props.panel_role}
        />
        <AppRoute
          path="/panel/see_all_apps"
          component={SeeAllApps}
          layout={ProtectedLayout}
          panel_role={props.panel_role}
        />
        <AppRoute path="/panel/insights" component={Insights} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/panel/settings" component={Settings} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/panel/help" component={Help} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/panel/users" component={Users} layout={ProtectedLayout} panel_role={props.panel_role} />
        <AppRoute path="/panel/401" component={Page401} layout={PublicLayout} panel_role={props.panel_role} />
        <PublicRoute path="/panel/login" component={Login} layout={PublicLayout} />
        <PublicRoute component={Page404} layout={PublicLayout} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
