import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import CourseListItem from 'pages/insights/containers/analyticsSummaryContainer/components/courseListItem';

const TopCourseCompletions = (props) => {
  const { completion_results } = props;
  const courses = completion_results.slice(0, 10).map((course, index) => {
    return (
      <CourseListItem
        key={index}
        id={course.course_id}
        name={course.course_name}
        value={course.num_learners_completed}
      />
    );
  });

  return (
    <div>
      <h4>
        Top Course by Completions
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">Courses ranked by number of completions.</span>
        </span>
      </h4>
      <ul className="course-analytics-list">
        <li className="head-list-item">
          <span>Name</span>
          <span>Completions</span>
        </li>
        {courses.length > 0 ? courses : <li className="no-data">No Data Available</li>}
      </ul>
    </div>
  );
};

export default TopCourseCompletions;
