import React from 'react';
import 'components/footer/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { convertValueTo2DP } from 'helpers';

const PercentChangeBox = (props) => {
  const { percent_change } = props;
  return (
    <strong className={`number ${percent_change < 0 ? 'fails' : percent_change > 0 ? 'success' : 'zero'}`}>
      <FontAwesomeIcon
        icon={percent_change < 0 ? Icons.faArrowDown : percent_change > 0 ? Icons.faArrowUp : Icons.faArrowsAltV}
      />
      {convertValueTo2DP(percent_change)}%
    </strong>
  );
};

export default PercentChangeBox;
