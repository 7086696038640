const axios = require('axios');

export const postInsights = (params) => {
  const { url, headers, formData } = params;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: headers,
    data: formData,
    url
  };
  return axios(requestOptions);
};

export const getInsights = (params) => {
  const { url, requestData } = params;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: requestData,
    url
  };
  return axios(requestOptions);
};
