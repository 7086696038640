export const getCurrentMonthStats = (stats) => {
  return stats.length > 1 ? stats[stats.length - 1] : 0;
};

export const getPreviousMonthStats = (stats) => {
  return stats.length > 1 ? stats[stats.length - 2] : 0;
};

export const extractDataLabels = (stats) => {
  let statistics;
  if (!stats?.history) {
    statistics = {
      ...stats,
      history: []
    };
  } else {
    statistics = stats;
  }
  return statistics.history.map((data) => {
    return data.period;
  });
};

export const extractDataValues = (stats) => {
  let statistics;
  if (!stats?.history) {
    statistics = {
      ...stats,
      history: []
    };
  } else {
    statistics = stats;
  }
  return statistics.history.map((data) => {
    return data.value;
  });
};

export const getPercentageChange = (current_month_stats, last_month_stats) => {
  let percentage_change = ((current_month_stats - last_month_stats) / last_month_stats) * 100;

  if (last_month_stats === 0) {
    percentage_change = current_month_stats * 100;
  }
  return percentage_change;
};

export const computePercentageChange = (stats) => {
  return getPercentageChange(getCurrentMonthStats(stats), getPreviousMonthStats(stats));
};
