import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEdlySiteConfig, updateEdlySiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';
import BadgrContainer from 'pages/integrations/containers/BadgrContainer/components/badgrContainer';
import { badgrActions } from 'pages/integrations/containers/BadgrContainer/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const BadgrIntegration = ({ badges, dispatch }) => {
  const [issuer, setIssuer] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [badgr, setBadgr] = useState(false);
  const [disabled, setDisabled] = useState();

  useEffect(() => {
    getEdlySiteConfig().then((res) => {
      const settings = res.data.lms.DJANGO_SETTINGS_OVERRIDE;
      setIssuer(settings.BADGR_ISSUER_SLUG ? settings.BADGR_ISSUER_SLUG : null);
      setUsername(settings.BADGR_USERNAME ? settings.BADGR_USERNAME : null);
      setPassword(settings.BADGR_PASSWORD ? settings.BADGR_PASSWORD : null);
      if (settings.BADGR_ISSUER_SLUG && settings.BADGR_USERNAME && settings.BADGR_PASSWORD) {
        setBadgr(true);
        setDisabled(true);
      }
    });
  }, []);

  const saveBadgrConfig = () => {
    const json_data = {
      lms: {
        DJANGO_SETTINGS_OVERRIDE: {
          BADGR_ISSUER_SLUG: issuer,
          BADGR_USERNAME: username,
          BADGR_PASSWORD: password
        }
      }
    };

    updateEdlySiteConfig(json_data)
      .then(() => messageNotification('success', 'Badgr Configuration', 'Badgr Configuration Updated.'))
      .catch(() => messageNotification('error', 'Badgr Configuration', 'Badgr Configuration Not Updated.'));
  };

  return (
    <div className="integration-holder">
      <h2>Badgr Integrations </h2>
      <div className="subheader-holder">
        <h4>
          2. Badges Details
          {badgr ? <FontAwesomeIcon icon={Icons.faCheck} className="check-icon" /> : null}
        </h4>
        <p>
          Please add the badgr account credientials.
          <a className="nav-link" href="https://badgr.com/">
            Learn more
          </a>
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="form-label">Badgr Issuer Slug</label>
          <input
            className="form-control"
            value={issuer}
            placeholder={'xxxxxx'}
            onChange={(event) => setIssuer(event.target.value)}
            disabled={disabled}
          ></input>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Badgr Username</label>
          <input
            className="form-control"
            value={username}
            placeholder={'xxxxxx'}
            onChange={(event) => setUsername(event.target.value)}
            disabled={disabled}
          ></input>
        </div>
        <div className="col-12">
          <label className="form-label">Badgr Password</label>
          <input
            className="form-control"
            value={password}
            placeholder={'xxxxxx'}
            type="password"
            disabled={disabled}
            onChange={(event) => setPassword(event.target.value)}
          ></input>
        </div>
        <div className="col-12 btn-holder justify-content-end d-flex">
          {badgr && (
            <button className="btn btn-link" onClick={() => setDisabled(false)}>
              Edit
            </button>
          )}
          <button className="btn btn-primary ml-2" onClick={saveBadgrConfig}>
            Save
          </button>
        </div>
      </div>
      <BadgrContainer badges={badges} dispatch={dispatch} enableBadges={badgr} />
    </div>
  );
};

function mapStateToProps(state) {
  const badges = state.SITE_BADGES.badges;
  return {
    badges: badges
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(badgrActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgrIntegration);
