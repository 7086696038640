import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { convertValueTo2DP } from 'helpers';
import LineChart from 'components/charts/lineChart';
import LineChartTrend from 'components/charts/lineChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getCourseCompletionsLabels,
  getCourseCompletionsStats,
  getCourseCompletionPercentageChange,
  getCurrentMonthCourseCompletionsCount
} from 'selectors';

const CoursesCompletions = (props) => {
  const { month_count, percent_change, data_labels, data_values, show_chart, trending, title } = props;

  return (
    <div className="stats-box">
      <div className="row align-items-center">
        <div className="col-6 col-xl-7">
          <h1 test-data-field="current-completions">{month_count}</h1>
        </div>
        <div className="col-6 col-xl-5  justify-content-end d-flex">
          <PercentChangeBox percent_change={convertValueTo2DP(percent_change)} />
        </div>
      </div>
      <span className="sub-title">
        {title}
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">
            Total number of time all of the courses have been completed by the learners in the current calendar month.
          </span>
        </span>
      </span>
      {show_chart &&
        (trending === 'true' ? (
          <LineChartTrend title="Completions Count" labels={data_labels} values={data_values} />
        ) : (
          <LineChart title="Completions Count" labels={data_labels} values={data_values} />
        ))}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getCourseCompletionsLabels(state);
  const data_values = getCourseCompletionsStats(state);
  const month_count = getCurrentMonthCourseCompletionsCount(state);
  const percent_change = getCourseCompletionPercentageChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    percent_change,
    month_count,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(CoursesCompletions);
