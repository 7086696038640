import { badgrConstants } from 'pages/integrations/containers/BadgrContainer/constants';

const badgrInitialState = {
  badges: []
};

export const fetchBadgesData = (state = badgrInitialState, action) => {
  switch (action.type) {
    case badgrConstants.FETCH_BADGES_SUCCESS:
      return {
        ...state,
        badges: action.payload.data
      };
    case badgrConstants.FETCH_BADGES_FAILURE:
      return {
        error: action.payload.error
      };
    default:
      return state;
  }
};
