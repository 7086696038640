import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getPanelBackendUrl } from 'helpers';
import { getUserSettings } from 'selectors';
import 'pages/login/index.scss';

const Login = (props) => {
  const { username } = props;

  return (
    <Fragment>
      {username ? (
        <Redirect to={{ pathname: '/panel/dashboard' }} />
      ) : (
        (window.location = `${getPanelBackendUrl()}login`)
      )}
    </Fragment>
  );
};

function mapStateToProps(state) {
  const { username } = getUserSettings(state);
  return {
    username
  };
}

export default connect(mapStateToProps)(Login);
