import axios from 'axios';

import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const getFeatureOverviewDetails = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.FEATURE_OVERVIEW}`;
  return axios.get(url, { withCredentials: true });
};

const postFeatureOverviewDetails = (show_overview) => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.FEATURE_OVERVIEW}`;

  const requestOptions = {
    method: 'POST',
    crossDomain: true,
    withCredentials: true,
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      show_overview: show_overview
    }
  };
  return axios(requestOptions);
};

export const featureOverviewService = {
  getFeatureOverviewDetails,
  postFeatureOverviewDetails
};
