import { EDX_API_ENDPOINTS, PANEL_API_ENDPOINTS, WORDPRESS_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl, getPanelLmsBaseUrl, getWordpressBaseUrl, getEcommerceBaseUrl } from 'helpers';
import FormData from 'form-data';

const axios = require('axios');

export function updateAppearanceSettings(payload) {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.EDLY_SITE_CONFIG}`;
  const form = new FormData();

  const propertiesToAppend = ['primary_color', 'secondary_color', 'site_theme', 'logo', 'favicon'];

  for (const property of propertiesToAppend) {
    if (payload[property]) {
      form.append(property, payload[property]);
    }
  }

  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token'),
      ...axios.defaults.headers.common,
      ...(form.getHeaders ? form.getHeaders() : {})
    },
    url,
    data: form
  };
  return axios(requestOptions);
}

const fetchThemes = () => {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.THEMES_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const updateSiteThemeConfiguration = (payload) => {
  const { theme_name, organization_id } = payload;
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.ORGANIZATIONS_API}${organization_id}/`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: {
      current_site_theme: theme_name
    }
  };
  return axios(requestOptions);
};

const updateLMSTheme = (payload) => {
  const { theme_name } = payload;
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.UPDATE_LMS_THEME}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: {
      theme_dir_name: theme_name
    }
  };
  return axios(requestOptions);
};

const updateEcommerceTheme = (payload) => {
  const { theme_name } = payload;
  const url = `${getEcommerceBaseUrl()}${EDX_API_ENDPOINTS.UPDATE_ECOMMERCE_THEME}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('ecommerce_csrf_token')
    },
    data: {
      theme_dir_name: `${theme_name}-ecommerce`
    }
  };
  return axios(requestOptions);
};

const updateWordpressTheme = (payload) => {
  const { theme_name } = payload;
  const url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.UPDATE_THEME}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    data: {
      theme_dir_name: theme_name
    }
  };
  return axios(requestOptions);
};

export const themesService = {
  fetchThemes,
  updateSiteThemeConfiguration,
  updateLMSTheme,
  updateEcommerceTheme,
  updateWordpressTheme
};
