import { PANEL_API_ENDPOINTS } from 'config';
import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl, getPanelBackendUrl } from 'helpers';

const axios = require('axios');

export const quickStatsService = {
  fetchEnrolledCoursesQuickStats,
  fetchCourseCompletions,
  downloadLearnersPDF,
  createSite
};

function fetchEnrolledCoursesQuickStats(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_MONTHLY_ENROLLMENT}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function fetchCourseCompletions(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_MONTHLY_COURSE_COMPLETIONS}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function downloadLearnersPDF() {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.DOWNLOAD_LEARNERS_DETAIL_PDF}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    url
  };
  return axios(requestOptions);
}

function createSite(payload) {
  const url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.CREATE_SITE}`;
  payload['csrfmiddlewaretoken'] = localStorage.getItem('panel_csrf_token');
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    data: payload
  };
  return axios(requestOptions);
}
