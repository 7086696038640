import React from 'react';
import { Link } from 'react-router-dom';

import { getLearnerCompletedCourses } from 'helpers';

const moment = require('moment');

const LearnerListItem = (props) => {
  const { learner } = props;
  return (
    <tr key={learner.id}>
      <td data-title="Name">
        <Link
          to={{ pathname: `/panel/insights/learners/learner-details/${learner.username}` }}
          className="list-group-item"
          key={learner.id}
        >
          {learner.name}
        </Link>
      </td>
      <td className="ellipsis-250" data-title="Email">
        <span>{learner.email}</span>
      </td>
      <td align="center" data-title="Courses Enrolled">
        {learner.courses.length}
      </td>
      <td align="center" data-title="Courses Completed">
        {getLearnerCompletedCourses(learner.courses).length}
      </td>
      <td align="center" data-title="Account Created">
        {learner.date_joined ? moment(learner.date_joined).format('YYYY-MM-DD') : 'N/A'}
      </td>
      <td align="center" data-title="Last Login">
        {learner.last_login ? moment(learner.last_login).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}
      </td>
    </tr>
  );
};

export default LearnerListItem;
