import { dataBackupConstants } from 'pages/dataBackup/constants';

const dataBackupInitialState = {
  dataBackup: []
};

export const fetchDataBackup = (state = dataBackupInitialState, action) => {
  switch (action.type) {
    case dataBackupConstants.FETCH_DATA_BACKUP_SUCCESS:
      return {
        ...state,
        dataBackup: action.payload.data
      };
    case dataBackupConstants.FETCH_DATA_BACKUP_FAILURE:
      return {
        error: action.payload.error
      };
    default:
      return state;
  }
};
