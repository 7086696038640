import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getTotalSiteLearnersDataLabels,
  getTotalSiteLearnersDataValues,
  getCurrentTotalSiteLearnersTotalCount,
  getCurrentTotalSiteLearnersPercentageChange
} from 'selectors';

const TotalLearners = (props) => {
  const { percent_change, total_count } = props;

  return (
    <div className="stats-box">
      <div className="d-flex align-items-center justify-content-between">
        <h1>{total_count}</h1>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      <span className="sub-title">
        Total learners
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">Number of unique learners who have registered on the platform.</span>
        </span>
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteLearnersDataLabels(state);
  const data_values = getTotalSiteLearnersDataValues(state);
  const percent_change = getCurrentTotalSiteLearnersPercentageChange(state);
  const total_count = getCurrentTotalSiteLearnersTotalCount(state);
  return {
    data_labels,
    data_values,
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalLearners);
