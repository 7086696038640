import React from 'react';
import { Route } from 'react-router-dom';
import { getPanelBackendUrl } from 'helpers';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('edx_username') && props.panel_role !== 'panel_restricted' ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        (window.location = `${getPanelBackendUrl()}login`)
      )
    }
  />
);

export default AppRoute;
