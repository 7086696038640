import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import LineChart from 'components/charts/lineChart';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getTotalSiteLearnersChange, getTotalSiteLearnersDataLabels, getTotalSiteLearnersDataValues } from 'selectors';

const TotalLearners = (props) => {
  const { data_labels, data_values, percent_change } = props;

  return (
    <div className="stats-box">
      <div className="d-flex justify-content-between align-items-center">
        <span className="sub-title pt-0">
          Total Learners
          <span className="tool-tip">
            <FontAwesomeIcon icon={Icons.faQuestionCircle} />
            <span className="tip-content">Total number of learners who registered on the platform over time.</span>
          </span>
        </span>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      <LineChart title="Users Count" height={80} labels={data_labels} values={data_values} />
    </div>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteLearnersDataLabels(state);
  const data_values = getTotalSiteLearnersDataValues(state);
  const percent_change = getTotalSiteLearnersChange(state);

  return {
    data_labels,
    data_values,
    percent_change
  };
}

export default connect(mapStateToProps)(TotalLearners);
