import React from 'react';

import { getWordpressBaseUrl } from 'helpers';

const UsersListingHeader = (props) => {
  const { checkAll, handleSelectAllUsers, userFilters, onSort } = props;

  return (
    <thead>
      <tr>
        <th rowSpan="2" className="col-check">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="checkAll"
              checked={checkAll === true}
              onChange={handleSelectAllUsers}
            />
            <label className="custom-control-label" htmlFor="checkAll">
              <span className="sr-only">All Users</span>
            </label>
          </div>
        </th>
        <th rowSpan="2">
          <span
            className={userFilters.column === 'profile__name' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
            onClick={() => onSort('profile__name')}
          >
            Name
          </span>
        </th>
        <th rowSpan="2">
          <span
            className={userFilters.column === 'username' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
            onClick={() => onSort('username')}
          >
            Username
          </span>
        </th>
        <th rowSpan="2">
          <span
            className={userFilters.column === 'email' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
            onClick={() => onSort('email')}
          >
            Email
          </span>
        </th>
        <th rowSpan="2" className="status">
          <span>Status</span>
        </th>
        <th colSpan="3" className="roles">
          Roles
        </th>
        <th rowSpan="2">
          <div className="actions">Actions</div>
        </th>
      </tr>
      <tr>
        <th>
          <span>LMS</span>
        </th>
        {getWordpressBaseUrl() && (
          <th>
            <span className="">Discovery</span>
          </th>
        )}
        <th>
          <span>Panel</span>
        </th>
      </tr>
    </thead>
  );
};

export default UsersListingHeader;
