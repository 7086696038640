import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

const fetchDataBackup = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.DATA_BACKUP}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const downloadDataBackup = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.DATA_BACKUP}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    url
  };
  return axios(requestOptions);
};

export const dataBackupService = {
  fetchDataBackup,
  downloadDataBackup
};
