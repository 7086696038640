import React from 'react';

function BenefitsSlideContent({ title, description }) {
  return (
    <div className="benefits-content">
      <h3>{title}</h3>
      {description}
    </div>
  );
}

export default BenefitsSlideContent;
