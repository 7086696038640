import React from 'react';

import BillingNotificationItem from 'pages/notifications/containers/notifications/components/billing/billingNotificationItem';

const BillingNotificationsListing = (props) => {
  const { billing_notifications, updateNotificationStatus } = props;

  return billing_notifications
    .filter((notification) => notification.is_read === false)
    .slice(0, 3)
    .map((notification, index) => {
      return (
        <BillingNotificationItem notification={notification} updateNotificationStatus={updateNotificationStatus} />
      );
    });
};

export default BillingNotificationsListing;
