export const badgrConstants = {
  FETCH_BADGES_SUCCESS: 'FETCH_BADGES_SUCCESS',
  FETCH_BADGES_FAILURE: 'FETCH_BADGES_FAILURE'
};

const fetchBadgesSuccess = (badges) => {
  return {
    type: badgrConstants.FETCH_BADGES_SUCCESS,
    payload: badges
  };
};

const fetchBadgesFailure = (error) => {
  return {
    type: badgrConstants.FETCH_BADGES_FAILURE,
    error
  };
};

export const badgrDispatches = {
  fetchBadgesSuccess,
  fetchBadgesFailure
};
