import { settingConstants } from 'pages/settings/containers/settingsContainer/constants';

const initialState = {
  name: '',
  username: '',
  email: '',
  organization: '',
  profile_image: '',
  country_code: null,
  country_timezones: [],
  time_zone: '',
  site_logo: '',
  old_email: '',
  is_loading: true,
  year_of_birth: '',
  password_reset: true
};

const initialUserRolesState = {
  roles: {
    panel_role: 'panel_restricted'
  }
};

export const userSettings = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.UPDATE_USER_SETTINGS:
      if (action.payload['profile_image']) {
        action.payload['profile_image'] = action.payload['profile_image']['image_url_full'];
      }

      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_USER_SETTINGS:
      action.payload['profile_image'] = action.payload['profile_image']['image_url_full'];
      action.payload['old_email'] = action.payload['email'];
      action.payload['country_code'] = action.payload['country'];

      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_USERNAME:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_USER_PREFERENCES:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.FETCH_COUNTRY_TIMEZONES:
      return {
        ...state,
        country_timezones: action.payload
      };
    case settingConstants.UPDATE_USER_PREFERENCES:
      return {
        ...state,
        ...action.payload
      };
    case settingConstants.APPLICATION_LOADED:
      return {
        ...state,
        is_loading: false
      };
    case settingConstants.USER_SETTINGS_FAILURE:
      return {
        ...initialState,
        is_loading: false
      };
    case settingConstants.SET_PASSWORD_RESET:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const fetchUserRoles = (state = initialUserRolesState, action) => {
  switch (action.type) {
    case settingConstants.FETCH_USER_ROLES:
      return {
        ...state,
        roles: action.payload ? action.payload['roles'] : initialUserRolesState['roles']
      };
    case settingConstants.FETCH_USER_ROLES_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const fetchOrganization = (state = { organization: null }, action) => {
  switch (action.type) {
    case settingConstants.FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.payload.results[0]
      };
    case settingConstants.FETCH_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
