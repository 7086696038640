import Cookies from 'js-cookie';

import { notificationsConstants } from 'pages/notifications/containers/notifications/constants';
import { setServicesNotificationsCookiesExpiry } from 'helpers';

const initialNotificationsState = {
  notification_next_page: null,
  alert_notifications: [],
  billing_notifications: [],
  notification_center: [],
  dropdown_notifications: [],
  current_page: 1,
  count: 0,
  error: null
};

const initialServicesNotificationsState = {
  previous_page: null,
  next_page: null,
  services_notifications: [],
  display_status: {},
  count: null
};

export function fetchNotifications(state = initialNotificationsState, action) {
  switch (action.type) {
    case notificationsConstants.FETCH_NOTIFICATIONS:
      let alert_notifications = [],
        billing_notifications = [];

      action.payload.results.forEach((notification) => {
        if (notification.type === 'alert') {
          alert_notifications.push(notification);
        } else if (notification.type === 'billing') {
          billing_notifications.push(notification);
        }
      });

      return {
        ...state,
        notification_next_page: action.payload.next,
        alert_notifications: alert_notifications,
        billing_notifications: billing_notifications,
        notification_center: action.payload.results,
        count: action.payload.count,
        current_page: action.currentPage
      };
    case notificationsConstants.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case notificationsConstants.FETCH_DROPDOWN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        dropdown_notifications: action.payload.results
      };
    case notificationsConstants.FETCH_DROPDOWN_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case notificationsConstants.UPDATE_NOTIFICATION_STATUS:
      return state;

    default:
      return state;
  }
}

export function fetchServicesNotifications(state = initialServicesNotificationsState, action) {
  switch (action.type) {
    case notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_SUCCESS:
      let display_status = {};
      action.payload.results.forEach((notification) => {
        display_status[notification.id] = true;
      });

      Cookies.set('panel_services_notifications', action.payload.results, { path: '/' });

      if (
        Cookies.get('panel_services_notifications_status') === undefined ||
        Cookies.get('panel_services_notifications_daily_status') === undefined
      ) {
        Cookies.set('panel_services_notifications_status', display_status, { path: '/' });
        setServicesNotificationsCookiesExpiry(display_status);
      } else {
        let old_notifications = Cookies.getJSON('panel_services_notifications_status');
        Object.keys(old_notifications).forEach(function(key) {
          if (key in display_status) {
            display_status[key] = old_notifications[key];
          }
        });
      }

      return {
        ...state,
        next_page: action.payload.next,
        previous_page: action.payload.previous,
        services_notifications: action.payload.results,
        display_status: display_status,
        count: action.payload.count
      };
    case notificationsConstants.UPDATE_SERVICES_NOTIFICATIONS_DISPLAY_STATUS:
      return {
        ...state,
        display_status: action.payload.display_status
      };
    case notificationsConstants.FETCH_SERVICES_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
