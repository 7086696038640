import { ltiConstants } from 'pages/integrations/containers/LtiIntegrationContainer/constants';

const ltiConfigurationsInitialState = {
  isLoading: false,
  ltiConfigurations: []
};

export const fetchLtiConfigurations = (state = ltiConfigurationsInitialState, action) => {
  switch (action.type) {
    case ltiConstants.FETCH_LTI_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        ltiConfigurations: [],
        isLoading: true
      };
    case ltiConstants.FETCH_LTI_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        ltiConfigurations: action.payload.data,
        isLoading: false
      };
    case ltiConstants.FETCH_LTI_CONFIGURATIONS_FAILURE:
      return {
        error: action.payload.error,
        isLoading: false
      };
    default:
      return state;
  }
};
