import Cookies from 'js-cookie';

import { notificationsDispatches } from 'pages/notifications/containers/notifications/constants';
import { notificationService } from 'services';
import { messageNotification, setServicesNotificationsCookiesExpiry } from 'helpers';

const fetchNotifications = (page_number) => {
  return (dispatch) => {
    notificationService
      .fetchNotifications(page_number)
      .then((notifications) => {
        Promise.resolve(dispatch(notificationsDispatches.fetchNotificationsSuccess(notifications, page_number)));
      })
      .catch((error) => {
        dispatch(notificationsDispatches.fetchNotificationsFailure(error));
        if (error.response) {
          messageNotification('danger', 'There was an error fetching system notifications', error.response);
        }
      });
  };
};

const fetchServicesNotifications = (page_number) => {
  return (dispatch) => {
    notificationService
      .fetchServicesNotifications(page_number)
      .then((notifications) => {
        Promise.resolve(dispatch(notificationsDispatches.fetchServiceNotificationsSuccess(notifications)));
      })
      .catch((error) => {
        dispatch(notificationsDispatches.fetchServiceNotificationsFailure(error));
        if (error.response) {
          messageNotification('danger', 'There was an error fetching services notifications', error.response);
        }
      });
  };
};

const updateNotificationStatus = (notification_id, status, page_number) => {
  return (dispatch) => {
    notificationService
      .updateNotificationStatus(notification_id, status)
      .then((notificationData) => {
        Promise.resolve(dispatch(notificationsDispatches.updateNotificationStatusSuccess(notificationData))).then(
          () => {
            dispatch(fetchNotifications(page_number));
          }
        );
        messageNotification(
          'success',
          'Notification Status Update',
          status === false ? 'Notification Unread' : 'Notification Read'
        );
      })
      .catch((error) => {
        if (error.response) {
          messageNotification('danger', 'There was an error updating notification status', error.response.data.detail);
        }
      });
  };
};

const updateServicesNotificationsStatus = (notification_id, status) => {
  return (dispatch) => {
    let display_status = Cookies.getJSON('panel_services_notifications_status');
    display_status[notification_id.toString()] = status;
    if (
      Cookies.get('panel_services_notifications_status') === undefined ||
      Cookies.get('panel_services_notifications_daily_status') === undefined
    ) {
      setServicesNotificationsCookiesExpiry(display_status);
    }
    Cookies.set('panel_services_notifications_status', display_status, { path: '/' });
    dispatch(notificationsDispatches.updateServiceNotificationDisplayStatusSuccess(notification_id, display_status));
  };
};

const fetchDropdownNotifications = () => {
  return (dispatch) => {
    notificationService
      .fetchNotifications(1)
      .then((notifications) => {
        Promise.resolve(dispatch(notificationsDispatches.fetchDropdownNotificationsSuccess(notifications)));
      })
      .catch((error) => {
        dispatch(notificationsDispatches.fetchDropdownNotificationsFailure(error));
      });
  };
};

export const notificationActions = {
  fetchNotifications,
  fetchDropdownNotifications,
  fetchServicesNotifications,
  updateServicesNotificationsStatus,
  updateNotificationStatus
};
