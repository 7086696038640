import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

export const userSiteService = {
  fetchUserSites
};

function fetchUserSites(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.USER_SITES}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}
