import { useState } from 'react';
import { Button } from 'react-bootstrap';
import GuideIcon from 'assets/images/ico-guide.svg';

import DemoModel from 'components/demo';

const UserGuideButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModelClose = () => {
    setIsOpen(false);
  };
  const handleModelOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Button variant="link" onClick={handleModelOpen} className="nav-link user-guide-btn">
        <img src={GuideIcon} style={{ cursor: 'pointer' }} alt="guide icon" width="24px" />
        <span> User Guide</span>
      </Button>
      <DemoModel isOpen={isOpen} handleGuideTracking={() => {}} handleTermsSubmit={handleModelClose} />
    </>
  );
};

export default UserGuideButton;
