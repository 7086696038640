import { siteDetailsDispatches } from '../constants';
import {
  fetchFreeTrial,
  postSiteDetails,
  updateClientSetup,
  fetchSiteDetailService,
  fetchUpdatedStateService,
  fetchVerifyDnsEntriesService,
  postRestDnsService
} from 'services/siteDetails';
import { messageNotification } from 'helpers';

const postSiteData = (data) => {
  return (dispatch) => {
    dispatch(siteDetailsDispatches.postSiteDetailsRequest());

    postSiteDetails(data)
      .then((response) => {
        dispatch(siteDetailsDispatches.postSiteDetailsSuccess(response.data));
        messageNotification('success', 'DNS generation initiated', 'This process will take a minute or longer.');
      })
      .catch((error) => {
        dispatch(siteDetailsDispatches.postSiteDetailsFailure(error));
        if (error.response) {
          messageNotification('danger', 'There was an error with the DNS entries.', error.response.data.error);
        }
      });
  };
};

const fetchTrialUser = () => {
  return (dispatch) => {
    fetchFreeTrial()
      .then((response) => {
        dispatch(siteDetailsDispatches.fetchTrialUserSuccess(response.data));
      })
      .catch((error) => {
        dispatch(siteDetailsDispatches.fetchTrialUserFailure(error));
        if (error.response) {
          messageNotification('danger', 'There was an error geting trial details.', error.response.data.error);
        }
      });
  };
};

const saveClientSetup = () => {
  return (dispatch) => {
    updateClientSetup()
      .then(() => {
        dispatch(siteDetailsDispatches.updateClientSetupSuccess());
        messageNotification('success', 'DNS Values Updating', 'DNS values will be update soon.');
        return dispatch(fetchUpdatedState());
      })
      .catch((error) => {
        dispatch(siteDetailsDispatches.updateClientSetupFailure());
        if (error.response.data) {
          messageNotification('danger', 'There was an error updating site urls.', error.response.data.error);
          return dispatch(fetchSiteDetails());
        } else {
          messageNotification('danger', 'Error updating site urls.', 'An error occured while updating site urls');
        }
      });
  };
};

const fetchUpdatedState = () => {
  return (dispatch) => {
    fetchUpdatedStateService()
      .then((response) => {
        dispatch(siteDetailsDispatches.fetchJobStateSuccess(response.data));
      })
      .catch(() => {
        dispatch(siteDetailsDispatches.fetchJobStateFailure());
      });
  };
};

const fetchSiteDetails = () => {
  return (dispatch) => {
    fetchSiteDetailService()
      .then((response) => dispatch(siteDetailsDispatches.fetchSiteDetailsSuccess(response.data)))
      .catch((error) => {
        dispatch(siteDetailsDispatches.fetchSiteDetailsFailure(error));
        if (error.response) {
          messageNotification('danger', 'Error in getting site details.', error.response.data.error);
        }
      });
  };
};

const fetchVerifyDnsEntries = () => {
  return (dispatch) => {
    fetchVerifyDnsEntriesService()
      .then((response) => {
        dispatch(siteDetailsDispatches.fetchVerifyDnsEntriesSuccess(response.data));
        messageNotification('success', 'DNS CNAME Verfied', 'You can now update the site URLs.');
      })
      .catch((error) => {
        dispatch(siteDetailsDispatches.fetchVerifyDnsEntriesFailure(error));
        if (error.response) {
          messageNotification('danger', 'There is issue with your entries.', error.response.data.error);
        }
      });
  };
};

const postResetDns = (data) => {
  return (dispatch) => {
    postRestDnsService(data)
      .then((response) => {
        dispatch(siteDetailsDispatches.postResetDnsRecordSuccess(response.data));
        messageNotification('success', 'Dns reset successfully', 'This process will take a minute or longer.');
      })
      .catch((error) => {
        dispatch(siteDetailsDispatches.postResetDnsRecordFailure(error));
        if (error.response) {
          messageNotification(
            'danger',
            'There was an error while resetting your DNS entries..',
            error.response.data.error
          );
        }
      });
  };
};

export const siteDetailsActions = {
  postSiteData,
  fetchTrialUser,
  saveClientSetup,
  fetchSiteDetails,
  fetchUpdatedState,
  fetchVerifyDnsEntries,
  postResetDns
};
