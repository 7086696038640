import React, { useState, useEffect, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DateFilter from 'components/dateFilter';
import PDFHeader from 'components/pdf/header';
import PDFFooter from 'components/pdf/footer';
import FooterNote from 'components/footer/footerNote';
import InsightsLoader from 'components/loaders/InsightsLoader';
import TotalLearners from 'pages/insights/containers/learnersAnalyticsContainer/components/totalLearners';
import DownloadLearnersAnalytics from 'pages/insights/containers/learnersAnalyticsContainer/components/downloadLearnerAnalytics';
import LearnersOverview from 'pages/insights/containers/learnersAnalyticsContainer/components/learnersOverview';
import LearnersMAUs from 'pages/insights/containers/learnersAnalyticsContainer/components/learnersMAUs';
import LearnersCourseCompletions from 'pages/insights/containers/learnersAnalyticsContainer/components/learnersCourseCompletions';
import LearnersAnalyticsSummary from 'pages/insights/containers/learnersAnalyticsContainer/components/learnersAnalyticsSummary';
import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import { quickStatsActions } from 'pages/dashboard/containers/quickStats/actions/quickStats';
import { learnersAnalyticsActions } from 'pages/insights/containers/learnersAnalyticsContainer/actions';
import {
  getAllLearnersAnalytics,
  getLearnerUsersInfo,
  getSiteMetricsInfo,
  getCurrentTotalSiteLearners,
  getCurrentLearnerCourseCompletions,
  getCurrentMAUs,
  getPreviousMAUs
} from 'selectors';
import 'pages/insights/containers/learnersAnalyticsContainer/learnersAnalytics.scss';

const moment = require('moment');

const LearnersAnalytics = (props) => {
  const {
    total_site_learners,
    learners_count,
    learners_data,
    current_page,
    site_daily_metrics,
    site_monthly_metrics,
    current_learners_maus,
    previous_learners_maus,
    dispatch
  } = props;

  const defaultState = {
    filter_type: 'this_year',
    filter_start_date: moment().subtract(1, 'months'),
    filter_end_date: moment()
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [learnerFilterType, setLearnerFilterType] = useState(defaultState.filter_type);
  const [learnerFilterStartDate, setLearnerFilterStartDate] = useState(defaultState.filter_start_date);
  const [learnerFilterEndDate, setLearnerFilterEndDate] = useState(defaultState.filter_end_date);

  useEffect(() => {
    dispatch(learnersAnalyticsActions.fetchUsersDetailAnalytics({ page: 1, learners_only: 'true' }, stopLoader));
    dispatch(
      learnersAnalyticsActions.fetchLearnersMAUs({
        filter_type: learnerFilterType,
        filter_start_date: learnerFilterStartDate,
        filter_end_date: learnerFilterEndDate
      })
    );
    dispatch(
      learnersAnalyticsActions.fetchLearnersCourseCompletions({
        filter_type: learnerFilterType,
        filter_start_date: learnerFilterStartDate,
        filter_end_date: learnerFilterEndDate
      })
    );
    dispatch(quickStatsActions.fetchMAUSQuickStats());
    dispatch(analyticsSummaryActions.fetchSiteMonthlyMetrics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const stopLoader = () => {
    setIsLoaded(true);
  };

  const handleFilterChange = (value) => {
    setLearnerFilterType(value);
  };

  const handleSetStartDate = (date) => {
    setLearnerFilterStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setLearnerFilterEndDate(date);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    dispatch(
      learnersAnalyticsActions.fetchLearnersCourseCompletions({
        filter_type: learnerFilterType,
        filter_start_date: learnerFilterStartDate,
        filter_end_date: learnerFilterEndDate
      })
    );

    dispatch(
      learnersAnalyticsActions.fetchLearnersMAUs({
        filter_type: learnerFilterType,
        filter_start_date: learnerFilterStartDate,
        filter_end_date: learnerFilterEndDate
      })
    );
  };

  const setDefaultFilter = (e) => {
    e.preventDefault();
    setLearnerFilterType(defaultState.filter_type);
    dispatch(learnersAnalyticsActions.fetchLearnersCourseCompletions(defaultState));
    dispatch(learnersAnalyticsActions.fetchLearnersMAUs(defaultState));
  };

  return (
    <div id="page_content" className="learners-analytics">
      {isLoaded ? (
        <Fragment>
          <PDFHeader />
          <div className="page-content-header">
            <h1>Learner Analytics</h1>
            <DownloadLearnersAnalytics {...props} />
          </div>

          <LearnersAnalyticsSummary
            site_daily_metrics={site_daily_metrics}
            site_monthly_metrics={site_monthly_metrics}
            total_site_learners={total_site_learners}
            current_learners_maus={current_learners_maus}
            previous_learners_maus={previous_learners_maus}
          />

          <div className="stats-block has-single-column">
            <TotalLearners />
          </div>

          <DateFilter
            filterType={learnerFilterType}
            handleRefresh={handleRefresh}
            handleFilterChange={handleFilterChange}
            handleSetStartDate={handleSetStartDate}
            handleSetEndDate={handleSetEndDate}
            setDefaultFilter={setDefaultFilter}
          />

          <div className="stats-block has-two-columns">
            <LearnersMAUs show_chart="true" />
            <LearnersCourseCompletions show_chart="true" />
          </div>

          <LearnersOverview
            dispatch={dispatch}
            learners_count={learners_count}
            current_page={current_page}
            learners_data={learners_data}
          />
          <FooterNote />
          <PDFFooter />
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const current_learners_maus = getCurrentMAUs(state);
  const previous_learners_maus = getPreviousMAUs(state);
  const total_site_learners = getCurrentTotalSiteLearners(state);
  const current_course_completions = getCurrentLearnerCourseCompletions(state);
  const all_learners_analytics = getAllLearnersAnalytics(state);
  const { learners_count, learners_data, current_page } = getLearnerUsersInfo(state);
  const { site_daily_metrics, site_monthly_metrics } = getSiteMetricsInfo(state);

  return {
    all_learners_analytics,
    total_site_learners,
    current_learners_maus,
    previous_learners_maus,
    current_course_completions,
    learners_count,
    learners_data,
    current_page,
    site_daily_metrics,
    site_monthly_metrics
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(learnersAnalyticsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnersAnalytics);
