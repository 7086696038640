import React from 'react';

import { Line } from 'react-chartjs-2';

const LineChartTrend = (props) => {
  const { title, labels, values } = props;

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        fill: true,
        lineTension: 0.1,
        backgroundColor: '#fef3f4',
        hoverBackgroundColor: '#dd1f25',
        borderColor: '#dd1f25',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#dd1f25',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#dd1f25',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: values
      }
    ]
  };

  const options = {
    layout: {
      padding: {
        top: 5,
        right: 5
      }
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false
          },
          ticks: {
            display: false,
            min: 0,
            beginAtZero: true,
            callback: function(value, index, values) {
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  };

  return <Line data={data} options={options} />;
};

export default LineChartTrend;
