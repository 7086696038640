import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PDFHeader from 'components/pdf/header';
import PDFFooter from 'components/pdf/footer';
import FooterNote from 'components/footer/footerNote';
import InsightsLoader from 'components/loaders/InsightsLoader';
import LearnerCoursesListing from 'pages/insights/containers/learnerDetailContainer/components/learnerCoursesListing';
import LearnerInfo from 'pages/insights/containers/learnerDetailContainer/components/learnerInfo';
import DownloadLearnerDetails from 'pages/insights/containers/learnerDetailContainer/components/downloadLearnerDetails';
import { learnerDetailsActions } from 'pages/insights/containers/learnerDetailContainer/actions';
import { getCurrentViewedLearnerDetails } from 'selectors';
import 'pages/insights/containers/learnerDetailContainer/learnerDetail.scss';

const LearnerDetails = (props) => {
  const { learner_info, dispatch } = props;
  const { username } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(learnerDetailsActions.fetchLearnerDetails({ username: username }, stopLoader));
  }, [dispatch, username]);

  const stopLoader = () => {
    setIsLoaded(true);
  };

  return (
    <div id="page_content">
      {isLoaded ? (
        <Fragment>
          <PDFHeader />
          <div className="page-content-header">
            <h1>{learner_info ? (learner_info.name ? learner_info.name : learner_info.username) : ''}</h1>
            <DownloadLearnerDetails learner_info={learner_info} dispatch={dispatch} />
          </div>
          <div className="learner-details-container row-view">
            <LearnerInfo learner_info={learner_info} />
            <div className="learner-courses-details">
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Course Title</th>
                      <th width="270px">Course Key</th>
                      <th width="120px">Enrollment Date</th>
                      <th width="120px">Enrollment Status</th>
                      <th width="120px">Completion Date</th>
                      <th width="90px">Grade</th>
                      <th width="90px">Graded Course Progress</th>
                      <th width="90px">Total Course Progress</th>
                    </tr>
                  </thead>
                  <tbody>{learner_info && <LearnerCoursesListing courses_data={learner_info.courses} />}</tbody>
                </table>
                {learner_info.courses.length === 0 ? <span className="no-data">No Data Available</span> : ''}
              </div>
            </div>
          </div>
          <FooterNote />
          <PDFFooter />
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const learner_info = getCurrentViewedLearnerDetails(state);

  return {
    learner_info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(learnerDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerDetails);
