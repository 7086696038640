import React from 'react';

import LearnerListItem from 'pages/insights/containers/learnersAnalyticsContainer/components/learnerListItem';

const LearnersListing = (props) => {
  const { learners_data } = props;
  return learners_data.map((learner, index) => {
    return <LearnerListItem key={index} learner={learner} />;
  });
};

export default LearnersListing;
