import React, { useState, Fragment } from 'react';
import Pagination from 'react-js-pagination';

import { PAGE_SIZE } from 'config';
import SearchInput from 'components/analytics/searchInput';
import LearnersListing from 'pages/insights/containers/learnersAnalyticsContainer/components/learnersListing';
import { learnersAnalyticsActions } from 'pages/insights/containers/learnersAnalyticsContainer/actions';

const LearnersOverview = (props) => {
  const { learners_count, learners_data, current_page, dispatch } = props;
  const [searchText, setSearchText] = useState('');
  const [timer, setTimer] = useState(null);
  const [userFilters, setUserFilters] = useState({
    column: 'profile__name',
    direction: 'desc asc'
  });

  const handlePageChange = (page_number) => {
    let payload = { page: page_number, search: searchText, learners_only: 'true' };
    if (userFilters.direction !== 'desc asc') {
      payload['ordering'] = userFilters.direction === 'asc' ? userFilters.column : `-${userFilters.column}`;
    }
    dispatch(learnersAnalyticsActions.fetchUsersDetailAnalytics(payload));
  };

  const handleInputChange = (event) => {
    let search = event.target.value;
    setSearchText(search);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(
          learnersAnalyticsActions.fetchUsersDetailAnalytics({ page: 1, search: search, learners_only: 'true' })
        );
      }, 400)
    );
  };

  const onSort = (columnName) => {
    const direction = userFilters.column ? (userFilters.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    setUserFilters({
      ...userFilters,
      column: columnName,
      direction: direction
    });

    let order_by = `${columnName}`;
    if (direction === 'desc') {
      order_by = `-${columnName}`;
    }
    setTimer(
      setTimeout(() => {
        dispatch(
          learnersAnalyticsActions.fetchUsersDetailAnalytics({
            search: searchText,
            ordering: order_by,
            learners_only: 'true'
          })
        );
      }, 400)
    );
  };

  return (
    <Fragment>
      <div className="learner-overview-header">
        <SearchInput label="Search Learner" searchText={searchText} handleInputChange={handleInputChange} />
        <h1>
          Learners Overview
          <small>Current Page: {current_page}</small>
        </h1>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>
                <span
                  className={
                    userFilters.column === 'profile__name' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('profile__name')}
                >
                  Name
                </span>
              </th>
              <th>
                <span
                  className={userFilters.column === 'email' ? `sorter ${userFilters.direction}` : 'sorter asc desc'}
                  onClick={() => onSort('email')}
                >
                  Email
                </span>
              </th>
              <th>
                <span>Courses Enrolled</span>
              </th>
              <th>
                <span>Courses Completed</span>
              </th>
              <th>
                <span
                  className={
                    userFilters.column === 'date_joined' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('date_joined')}
                >
                  Account Created
                </span>
              </th>
              <th>
                <span
                  className={
                    userFilters.column === 'last_login' ? `sorter ${userFilters.direction}` : 'sorter asc desc'
                  }
                  onClick={() => onSort('last_login')}
                >
                  Last Login
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <LearnersListing learners_count={learners_count} learners_data={learners_data} />
          </tbody>
        </table>
        <div className="table-footer">
          <Pagination
            activePage={current_page}
            itemsCountPerPage={PAGE_SIZE}
            totalItemsCount={learners_count}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LearnersOverview;
