import React from 'react';
import Switch from 'react-switch';

const ToggleItem = ({ title, description, checked, onChange }) => {
  return (
    <div>
      <div className="toggle-row">
        <div className="text">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className="switch-holder">
          <Switch
            width={45}
            height={24}
            id={title.toLowerCase()}
            checked={checked}
            onChange={onChange}
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <label htmlFor={title.toLowerCase()}>{checked ? 'On' : 'Off'}</label>
        </div>
      </div>
    </div>
  );
};

export default ToggleItem;
