import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const ProgressBar = ({ bgColor, progress, setProgressStart, uploadCancelHandler }) => {
  const parentDiv = {
    height: '3px',
    width: '100%',
    backgroundColor: '#E6E6E6',
    borderRadius: '4px'
  };

  const childDiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgColor,
    textAlign: 'right',
    borderRadius: '4px',
    marginBottom: '5px'
  };

  const progressText = {
    padding: 10,
    color: bgColor,
    fontWeight: 100
  };

  const progressBox = {
    position: 'relative',
    padding: '8px 15px',
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '442px',
    fontSize: '14px',
    minHeight: '66px',
    fontWeight: '700'
  };

  const progressPercentage = {
    margin: '1px 0 4px',
    color: '#929497',
    fontSize: '12px',
    fontWeight: '400'
  };

  const UploadingCompleted = {
    position: 'absolute',
    left: '15px',
    right: '15px',
    top: '0',
    bottom: '0',
    margin: 'auto',
    height: '20px'
  };

  const IconStyleSuccess = {
    backgroundColor: '#E6F4EB',
    border: '1px solid #4DAF70',
    color: '#4DAF70'
  };

  const IconStyleFail = {
    backgroundColor: '#FFE6E8',
    marginTop: '6px',
    border: '1px solid #DD1F25',
    color: '#DD1F25'
  };

  const getUploadedDiv = () => {
    setTimeout(() => {
      setProgressStart(false);
    }, 3000);
    return (
      <div style={UploadingCompleted}>
        Uploading Completed
        <FontAwesomeIcon icon={Icons.faCheck} style={IconStyleSuccess} viewBox={'-240 0 1000 600'} />
      </div>
    );
  };

  return (
    <div style={progressBox}>
      {progress === 100 ? (
        getUploadedDiv()
      ) : (
        <div>
          <span>Uploading...</span>
          <FontAwesomeIcon
            icon={Icons.faTimes}
            style={IconStyleFail}
            onClick={uploadCancelHandler}
            viewBox={'-320 0 1000 600'}
          />
          <div style={progressPercentage}>{progress}%</div>
          <div style={parentDiv}>
            <div style={childDiv}>
              <span style={progressText}></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
