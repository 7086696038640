import { usersDispatches } from 'pages/users/containers/usersContainer/constants';
import { messageNotification, validateLoginSession, getWordpressBaseUrl } from 'helpers';
import { usersService } from 'services';
import { PASSWORD_RESET_EMAIL_TIME_LIMIT } from 'config';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';

const displayErrorMessages = (title, errors) => {
  let error_messages = [];
  Object.keys(errors || {}).forEach((key) => {
    error_messages.push(`${key} : ${errors[key]}`);
  });

  if (error_messages.length > 0) {
    messageNotification('danger', title, error_messages.join());
  }
};

const fetchUsers = (payload = {}) => {
  if (!payload.hasOwnProperty('order_by')) {
    payload.order_by = 'profile__name,asc';
  }
  let { setPaginationLoading, roles, searchText } = payload;
  return (dispatch) => {
    dispatch(usersDispatches.setFetchUsersLoading());
    usersService
      .fetchUsers(payload)
      .then((users) => {
        Promise.resolve(dispatch(usersDispatches.fetchUsersSuccess(users, roles, searchText))).then(() => {
          if (setPaginationLoading !== undefined) {
            setPaginationLoading(false);
          }
        });
      })
      .catch((error) => {
        dispatch(usersDispatches.fetchUsersFailure(error));
        if (error.response) {
          messageNotification('danger', 'There was an error fetching users', error.response);
        }
      });
  };
};

const addNewUser = (payload, closePopup, resetForm) => {
  return (dispatch) => {
    messageNotification('info', 'User Account Creation', 'User account creation in progress', 1000);
    usersService
      .addNewUser(payload)
      .then((user) => {
        Promise.resolve(dispatch(usersDispatches.addUserSuccess(user))).then(() => {
          dispatch(fetchUsers(payload));
          if (getWordpressBaseUrl()) {
            dispatch(addWordpressUser(payload));
          }
        });
        messageNotification('success', 'User Account Creation', 'New User Account Created Successfully');
        closePopup();
        resetForm();
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(usersDispatches.addUserFailure(error));
        displayErrorMessages('There was an error creating new User', error.response.data);
      });
  };
};

const updateUser = (payload, closePopup, setIsRequestInProgress) => {
  return (dispatch) => {
    messageNotification('info', 'User Account Update', 'User account update in progress', 1000);
    usersService
      .updateUser(payload)
      .then((user) => {
        Promise.resolve(dispatch(usersDispatches.updateUserSuccess(user))).then(() => {
          dispatch(fetchUsers(payload));
          if (getWordpressBaseUrl()) {
            dispatch(updateWordpressUser(payload));
          }
        });

        messageNotification('success', 'User Account Update', 'User Account Updated Successfully');
        closePopup();
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(usersDispatches.updateUserFailure(error));
        displayErrorMessages('There was an error updating new User', error?.response?.data);
      })
      .finally(() => {
        setIsRequestInProgress(false);
      });
  };
};

const resetUserPassword = (payload) => {
  return (dispatch) => {
    usersService
      .resetUserPassword(payload)
      .then((userData) => {
        Promise.resolve(dispatch(usersDispatches.resetUserPasswordSuccess(userData))).then(() => {
          messageNotification(
            'success',
            'Reset User Password',
            'We have e-mailed user, instructions for setting password to registered e-mail address.User should be receiving it shortly.'
          );
          dispatch(settingsDispatches.setPasswordResetSuccess({ password_reset: false }));
          setTimeout(() => {
            dispatch(settingsDispatches.setPasswordResetSuccess({ password_reset: true }));
          }, PASSWORD_RESET_EMAIL_TIME_LIMIT * 1000);
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          messageNotification(
            'danger',
            'Reset Password',
            `You have used password reset too many times. Please retry after ${PASSWORD_RESET_EMAIL_TIME_LIMIT} seconds.`
          );
        }
      });
  };
};

const updateUserStatus = (
  username,
  status,
  search,
  wordpress_role,
  activePageNumber = 1,
  roles,
  setIsStatusRequestInProgress,
  pageSize
) => {
  return (dispatch) => {
    usersService
      .updateUserStatus(username, status)
      .then((userData) => {
        Promise.resolve(
          dispatch(usersDispatches.updateUserActiveSuccess(userData)),
          dispatch(fetchUsers({ page_number: activePageNumber, searchText: search, roles, page_size: pageSize }))
        );
        if (getWordpressBaseUrl()) {
          dispatch(updateWordpressUserStatus(username, status, wordpress_role));
        }
        messageNotification('success', 'User Account Status Update', 'User Account Status Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      })
      .finally(() => {
        setIsStatusRequestInProgress(false);
      });
  };
};

const updateUserBlockStatus = (
  username,
  block_status,
  search,
  active_page_number = 1,
  roles,
  setIsBlockRequestInProgress,
  order_by = undefined,
  pageSize
) => {
  return (dispatch) => {
    usersService
      .updateUserStatus(username, null, block_status)
      .then((userData) => {
        Promise.resolve(
          dispatch(usersDispatches.updateUserActiveSuccess(userData)),
          dispatch(
            fetchUsers({ page_number: active_page_number, searchText: search, order_by, roles, page_size: pageSize })
          )
        );
        messageNotification('success', 'User Block Status Update', 'User Block Status Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
      })
      .finally(() => {
        setIsBlockRequestInProgress(false);
      });
  };
};

const blockUsers = (
  selectedUsers,
  searchText,
  page_number = 1,
  roles = undefined,
  order_by = undefined,
  page_size = undefined
) => {
  let pageNumber = roles.hasOwnProperty('is_unblocked') && roles.is_unblocked === true ? 1 : page_number;
  return (dispatch) => {
    usersService
      .blockUsers(selectedUsers)
      .then((usersData) => {
        Promise.resolve(dispatch(usersDispatches.blockUsersSuccess(usersData))).then(() => {
          dispatch(fetchUsers({ page_number: pageNumber, searchText, roles, order_by, page_size }));
          if (getWordpressBaseUrl()) {
            dispatch(deactivateWPUsers(selectedUsers));
          }
        });
        messageNotification('success', 'Users Block Status Update', 'Users Block Status Updated Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(usersDispatches.blockUsersFailure(error));
        displayErrorMessages('There was an error updating User', error.response.data);
      });
  };
};

const deactivateWPUsers = (selectedUsers) => {
  return (dispatch) => {
    usersService
      .deactivateWPUsers(selectedUsers)
      .then((usersData) => {
        Promise.resolve(dispatch(usersDispatches.deactivateWPUsersSuccess(usersData)));
      })
      .catch((error) => {
        dispatch(usersDispatches.deactivateWPUsersFailure(error));
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error in updating WordPress Users', error.response.data.message);
        }
      });
  };
};

const exportUsersInfo = (payload) => {
  return (dispatch) => {
    usersService
      .exportUsersInfo(payload)
      .then((usersData) => {
        Promise.resolve(dispatch(usersDispatches.exportUsersSuccess(usersData))).then(() =>
          document.querySelector('.btn-download-csv').click()
        );
        messageNotification('success', 'Users Accounts Data Export', 'Users Accounts Data Exported Successfully');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(usersDispatches.exportUsersFailure(error));
      });
  };
};

const validateUser = (user_data, callback) => {
  user_data['return_user_info'] = true;
  return (dispatch) => {
    usersService
      .validateUser(user_data)
      .then((response) => {
        let validations = response.data.validation_decisions;
        let validation_messages = {};
        Object.keys(validations).forEach((key) => {
          if (validations[key] !== '') {
            validation_messages[`${key}`] = validations[key];
          }
        });

        const validation =
          Object.keys(validation_messages).length === 0 ||
          (validation_messages?.username_and_name && Object.keys(validation_messages).length === 1);

        if (validation && callback !== undefined) {
          dispatch(callback);
        }

        Promise.resolve(dispatch(usersDispatches.validateUserSuccess(validation_messages)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(usersDispatches.validateUserFailure(error));
      });
  };
};

const addWordpressUser = (user) => {
  return (dispatch) => {
    usersService
      .addWordpressUser(user)
      .then((user) => {
        Promise.resolve(dispatch(usersDispatches.addWPUserSuccess(user)));
      })
      .catch((error) => {
        dispatch(usersDispatches.addWPUserFailure(error));
        if (error.response) {
          messageNotification(
            'danger',
            'There was an error in creating new WordPress User',
            error.response.data.message
          );
        }
      });
  };
};

const updateWordpressUser = (user) => {
  return (dispatch) => {
    usersService
      .updateWordpressUser(user)
      .then((user) => {
        Promise.resolve(dispatch(usersDispatches.updateWPUserSuccess(user)));
      })
      .catch((error) => {
        dispatch(usersDispatches.updateWPUserFailure(error));
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error creating new WordPress User', error.response.data.message);
        }
      });
  };
};

const updateWordpressUserStatus = (username, status, wordpress_role) => {
  return (dispatch) => {
    usersService
      .updateWordpressUserStatus(username, status, wordpress_role)
      .then((userData) => {
        Promise.resolve(dispatch(usersDispatches.updateWPUserStatusSuccess(userData)));
      })
      .catch((error) => {
        dispatch(usersDispatches.updateWPUserStatusFailure(error));
        if (error.response.data.message) {
          messageNotification('danger', 'There was an error in updating WordPress User', error.response.data.message);
        }
      });
  };
};

const uploadBulkUsersFile = (data, setMessage, onUploadProgress) => {
  return (dispatch) => {
    usersService
      .uploadBulkUsers(data, onUploadProgress)
      .then((userData) => {
        Promise.resolve(dispatch(usersDispatches.bulkUsersStatusSuccess(userData)));
        setMessage(userData.data);
      })
      .catch((error) => {
        dispatch(usersDispatches.bulkUsersStatusFailure(error));
        setMessage(error.response.data.error);
      });
  };
};

const getRegistrationTemplate = () => {
  return (dispatch) => {
    usersService.getRegistrationTemplate().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'registration_template.csv');
      document.body.appendChild(link);
      link.click();
    });
  };
};

export const usersActions = {
  fetchUsers,
  addNewUser,
  validateUser,
  updateUser,
  updateUserStatus,
  updateUserBlockStatus,
  resetUserPassword,
  blockUsers,
  exportUsersInfo,
  uploadBulkUsersFile,
  getRegistrationTemplate
};
