import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { clarity } from 'react-microsoft-clarity';

import { getWordpressBaseUrl } from 'helpers';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import PanelRolesButton from 'pages/users/containers/usersContainer/components/panelRolesButton';
import { sendTrackingEvent } from 'services/tracking';

const AddUser = (props) => {
  const { validation_messages, page, searchText, roles, pageSize } = props;
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const username_value = validation_messages?.username_and_name?.username;
  const name_value = validation_messages?.username_and_name?.name;

  const resetInputs = () => {
    setInputs({
      username: '',
      name: '',
      email: '',
      is_staff: true,
      is_active: true,
      is_superuser: false,
      lms_role: 'learner',
      wordpress_role: 'subscriber',
      panel_role: 'panel_restricted'
    });
  };

  const handleInputChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active_user') {
      setInputs((inputs) => ({ ...inputs, is_active: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
      if (inputs.email && inputs.username && inputs.name) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  };

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser(inputs));
    if (inputs.email && inputs.username && inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = inputs;
    payload['page_number'] = page;
    payload['searchText'] = searchText;
    payload['roles'] = roles;
    payload['page_size'] = pageSize;
    setButtonDisabled(true);
    props.dispatch(usersActions.validateUser(inputs, usersActions.addNewUser(payload, props.onHide, resetInputs)));
    sendTrackingEvent({ has_created_user: 'true' }).catch((err) => {});
    clarity.setTag('has_created_user', 'true');
  };

  useEffect(() => {
    if (username_value && name_value) {
      setInputs((inputs) => ({ ...inputs, username: username_value }));
      setInputs((inputs) => ({ ...inputs, name: name_value }));
      setButtonDisabled(false);
    }
  }, [username_value, name_value]);

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size}>
        <Modal.Header>
          <Modal.Title>
            <span className="close" onClick={props.onHide}>
              <FontAwesomeIcon icon={Icons.faTimes} />
            </span>
            <h2>Add User</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.email}
              required
            />
            <span className="text-error">{validation_messages['email']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="username">
              Username
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.username}
              required
              disabled={username_value ? true : false}
            />
            {username_value ? null : <span className="text-error">{validation_messages['username']}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="name">
              Full Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              onChange={handleInputChange}
              onBlur={handleValidation}
              value={inputs.name}
              required
              disabled={name_value ? true : false}
            />
            {name_value ? null : <span className="text-error">{validation_messages['name']}</span>}
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="is_active_user"
                id="is_active_user"
                value={inputs.is_active}
                onChange={handleInputChange}
                checked={inputs.is_active}
              />
              <label className="custom-control-label" htmlFor="is_active_user">
                Active
              </label>
            </div>
          </div>
          <PanelRolesButton inputs={inputs} handleInputChange={handleInputChange} is_active="true" />
          {getWordpressBaseUrl() && (
            <div className="form-group">
              <label htmlFor="admin">Discovery Role</label>
              <div className="roles-row">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="subscriber_filter"
                    onClick={handleInputChange}
                    check={(inputs.wordpress_role.toString() === 'subscriber').toString()}
                    value="subscriber"
                    defaultChecked
                  />
                  <label className="custom-control-label" htmlFor="subscriber_filter">
                    Subscriber
                    <span className="tool-tip">
                      <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                      <span className="tip-content">
                        <span className="tip-content-item">
                          User is registered on the Discovery website but does not have WordPress Admin access
                        </span>
                        <span className="tip-content-item">User cannot make any changes to the WordPress website</span>
                      </span>
                    </span>
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="edly_admin_filter"
                    onClick={handleInputChange}
                    check={(inputs.wordpress_role.toString() === 'edly_admin').toString()}
                    value="edly_admin"
                  />
                  <label className="custom-control-label" htmlFor="edly_admin_filter">
                    Edly Admin
                    <span className="tool-tip">
                      <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                      <span className="tip-content">
                        <span className="tip-content-item">User has full admin access to the Discovery website</span>
                        <span className="tip-content-item">
                          User can customize the WordPress website, add/delete pages and has editing access to the
                          WordPress Administration panel
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="admin">LMS Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="learner_filter"
                  onClick={handleInputChange}
                  check={(inputs.lms_role.toString() === 'learner').toString()}
                  value="learner"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="learner_filter">
                  Learner
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User cannot create a course or library</span>
                      <span className="tip-content-item">User cannot see/edit any course</span>
                      <span className="tip-content-item">User can request to become a site creator</span>
                      <span className="tip-content-item">User does not have access to Studio</span>
                    </span>
                  </span>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="staff_filter"
                  onClick={handleInputChange}
                  check={(inputs.lms_role.toString() === 'staff').toString()}
                  value="staff"
                />
                <label className="custom-control-label" htmlFor="staff_filter">
                  Staff
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">
                        User can view/edit/archive/publish all courses on the current site even if he/she is not a part
                        of the course team
                      </span>
                      <span className="tip-content-item">User can add/remove team members to a course team</span>
                    </span>
                  </span>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="course_creator_filter"
                  onClick={handleInputChange}
                  check={(inputs.lms_role.toString() === 'course_creator').toString()}
                  value="course_creator"
                />
                <label className="custom-control-label" htmlFor="course_creator_filter">
                  Course Creator
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">User can view/edit all courses created by them</span>
                      <span className="tip-content-item">
                        User can view/edit courses created by other users if they are a part of the course team
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="primary" disabled={buttonDisabled} onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddUser;
