import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { siteDetailsActions } from '../actions';
import Cookies from 'js-cookie';
import { FIFTEEN_MINUTES_EXPIRATION } from '../constants';

const DnsValues = ({ hideDnsTable, dnsList, verified, dispatch }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [copiedValue, setCopiedValue] = useState('');
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (verified) {
      const disableUpdateButton = Cookies.get('disable_dns_update_button');
      if (!disableUpdateButton) {
        setDisableSave(false);
      }
    }
  }, [verified]);

  const hideModal = () => {
    setIsOpen(false);
    hideDnsTable();
  };

  const unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {}
    document.body.removeChild(textArea);
  };

  const updateSiteValues = () => {
    Cookies.set('disable_dns_update_button', true, { expires: FIFTEEN_MINUTES_EXPIRATION });
    dispatch(siteDetailsActions.saveClientSetup());
    setIsOpen(false);
    hideDnsTable();
  };

  const verifyEntries = () => {
    dispatch(siteDetailsActions.fetchVerifyDnsEntries());
  };
  const handleCopy = (value) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(value);
    } else {
      unsecuredCopyToClipboard(value);
    }
    setCopiedValue(value);
  };

  return (
    <Modal className="dns-values-modal" onHide={hideModal} show={isOpen} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>DNS Values</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="alert2">
          <FontAwesomeIcon icon={Icons.faInfoCircle} />
          <span> Click the text to copy on clipboard</span>
        </div>
        <table border="1" className="table dns-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {dnsList.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td data-title="Name" onClick={() => handleCopy(row[0])}>
                  {row[0]}
                </td>
                <td data-title="Type" onClick={() => handleCopy(row[1])}>
                  {row[1]}
                </td>
                <td data-title="Value" onClick={() => handleCopy(row[2])}>
                  {row[2]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="copy-text-info">{copiedValue && <p>Copied: {copiedValue}</p>}</div>
        <div className="col-12 d-flex justify-content-end">
          <button className="btn btn-secondary btn-right ml-10" onClick={verifyEntries}>
            <span> Verify Domain</span>
          </button>
          <button className="btn btn-primary btn-right ml-10" onClick={updateSiteValues} disabled={disableSave}>
            <span> Update Domain</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(state) {
  const { verified } = state.SITE_DETAILS;
  return {
    verified
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(siteDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DnsValues);
