export const quickStatsConstants = {
  FETCH_MAUS_QUICK_STATS_SUCCESS: 'FETCH_MAUS_QUICK_STATS_SUCCESS',
  FETCH_MAUS_QUICK_STATS_FAILURE: 'FETCH_MAUS_QUICK_STATS_FAILURE',
  FETCH_ENROLLED_COURSES_QS_SUCCESS: 'FETCH_ENROLLED_COURSES_QUICK_STATS_SUCCESS',
  FETCH_ENROLLED_COURSES_QS_FAILURE: 'FETCH_ENROLLED_COURSES_QUICK_STATS_FAILURE',
  FETCH_COURSE_COMPLETIONS_QS_SUCCESS: 'FETCH_COURSE_COMPLETIONS_QUICK_STATS_SUCCESS',
  FETCH_COURSE_COMPLETIONS_QS_FAILURE: 'FETCH_COURSE_COMPLETIONS_QUICK_STATS_FAILURE'
};

const fetchMAUsQSSuccess = (userData) => {
  return { type: quickStatsConstants.FETCH_MAUS_QUICK_STATS_SUCCESS, payload: userData.data };
};

const fetchMAUsQSFailure = (error) => {
  return { type: quickStatsConstants.FETCH_MAUS_QUICK_STATS_FAILURE, payload: error };
};

const fetchEnrolledCoursesQSSuccess = (coursesData) => {
  return { type: quickStatsConstants.FETCH_ENROLLED_COURSES_QS_SUCCESS, payload: coursesData.data };
};

const fetchEnrolledCoursesQSFailure = (error) => {
  return { type: quickStatsConstants.FETCH_ENROLLED_COURSES_QS_FAILURE, payload: error };
};

const fetchCourseCompletionsQSSuccess = (coursesData) => {
  return { type: quickStatsConstants.FETCH_COURSE_COMPLETIONS_QS_SUCCESS, payload: coursesData.data };
};

const fetchCourseCompletionsQSFailure = (error) => {
  return { type: quickStatsConstants.FETCH_COURSE_COMPLETIONS_QS_FAILURE, payload: error };
};

export const quickStatsDispatches = {
  fetchMAUsQSSuccess,
  fetchMAUsQSFailure,
  fetchEnrolledCoursesQSSuccess,
  fetchEnrolledCoursesQSFailure,
  fetchCourseCompletionsQSSuccess,
  fetchCourseCompletionsQSFailure
};
