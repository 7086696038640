export const learnerDetailsConstants = {
  FETCH_LEARNER_DETAILS_SUCCESS: 'FETCH_LEARNER_DETAILS_SUCCESS',
  FETCH_LEARNER_DETAILS_FAILURE: 'FETCH_LEARNER_DETAILS_FAILURE'
};

const learnerDetailsSuccess = (userData) => {
  return { type: learnerDetailsConstants.FETCH_LEARNER_DETAILS_SUCCESS, payload: userData.data };
};

const learnerDetailsFailure = (error) => {
  return { type: learnerDetailsConstants.FETCH_LEARNER_DETAILS_FAILURE, payload: error };
};

export const learnerDetailsDispatches = {
  learnerDetailsSuccess,
  learnerDetailsFailure
};
