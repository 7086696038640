import { messageNotification, validateLoginSession } from 'helpers';
import { analyticsSummaryService } from 'services/insights';
import { analyticsSummaryDispatches } from 'pages/insights/containers/analyticsSummaryContainer/constants';
import { addSubOrgParamValue } from 'helpers/dashboard';

const moment = require('moment');

export const analyticsSummaryActions = {
  fetchCoursesGeneralData,
  fetchGeneralSiteMetrics,
  fetchMonthlyUsersStats,
  fetchCoursesEnrollmentStats,
  fetchCoursesCompletionStats,
  fetchSiteDailyMetrics,
  fetchSiteMonthlyMetrics,
  fetchAnalyticsSummaryCSV,
  fetchGeneralSitesMetrics
};

function fetchCoursesGeneralData(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  return (dispatch) => {
    analyticsSummaryService
      .fetchCoursesGeneralData(params)
      .then((coursesData) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.coursesGeneralInfoSuccess(coursesData)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.coursesGeneralInfoFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching courses general data', error.response.data.error);
      });
  };
}

function fetchGeneralSiteMetrics(payload) {
  const params = getAnalyticsSummaryPayload(payload);

  return (dispatch) => {
    analyticsSummaryService
      .fetchGeneralSiteMetrics(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.generalSiteMetricsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.generalSiteMetricsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching insights', error.response.data.error);
      });
  };
}

function fetchGeneralSitesMetrics(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  if (payload?.useSites) {
    addSubOrgParamValue(payload.useSites, params);
  }

  return (dispatch) => {
    analyticsSummaryService
      .fetchGeneralSitesMetrics(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.generalSiteMetricsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.generalSiteMetricsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching insights', error.response.data.error);
      });
  };
}

function fetchMonthlyUsersStats(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  return (dispatch) => {
    analyticsSummaryService
      .fetchMonthlyUsersStats(params)
      .then((usersData) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.monthlyUsersStatsSuccess(usersData)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.monthlyUsersStatsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching monthly users stats', error.response.data.error);
      });
  };
}

function fetchCoursesEnrollmentStats(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  if (Object.keys(payload).includes('useSites')) {
    addSubOrgParamValue(payload.useSites, params);
  }
  return (dispatch) => {
    analyticsSummaryService
      .fetchCoursesEnrollmentStats(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.coursesEnrollmentStatsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.coursesEnrollmentStatsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification(
          'danger',
          'There was an error fetching courses enrollment stats',
          error.response.data.error
        );
      });
  };
}

function fetchCoursesCompletionStats(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  if (Object.keys(payload).includes('useSites')) {
    addSubOrgParamValue(payload.useSites, params);
  }
  return (dispatch) => {
    analyticsSummaryService
      .fetchCoursesCompletionStats(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.coursesCompletionStatsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.coursesCompletionStatsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification(
          'danger',
          'There was an error fetching courses completion stats',
          error.response.data.error
        );
      });
  };
}

function fetchSiteDailyMetrics(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  return (dispatch) => {
    analyticsSummaryService
      .fetchSiteDailyMetrics(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.siteDailyMetricsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.siteDailyMetricsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching site daily metrics', error.response.data.error);
      });
  };
}

function fetchSiteMonthlyMetrics(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  return (dispatch) => {
    analyticsSummaryService
      .fetchSiteMonthlyMetrics(params)
      .then((siteMetrics) => {
        Promise.resolve(dispatch(analyticsSummaryDispatches.fetchSiteMonthlyMetricsSuccess(siteMetrics)));
      })
      .catch((error) => {
        dispatch(analyticsSummaryDispatches.fetchSiteMonthlyMetricsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching site monthly metrics', error.response.data.error);
      });
  };
}

function fetchAnalyticsSummaryCSV(payload) {
  const params = getAnalyticsSummaryPayload(payload);
  return (dispatch) => {
    analyticsSummaryService
      .fetchInsightsSummaryCSV(params)
      .then((userData) => {
        messageNotification('success', 'Emailing insights summary csv report', userData.data['message']);
      })
      .catch((error) => {
        messageNotification(
          'danger',
          'There was an error fetching insights summary csv report',
          error.response.data.error
        );
        validateLoginSession(dispatch, error);
      });
  };
}

const getAnalyticsSummaryPayload = (payload) => {
  if (!payload) return payload;
  if (!payload.filter_type) return payload;

  const { filter_type, filter_start_date, filter_end_date } = payload;
  let params = {};
  switch (filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'custom':
      params['start_date'] = moment(filter_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(filter_end_date).format('D-MM-YYYY');
      break;
    default:
      break;
  }
  params['type'] = 'custom';
  return params;
};
