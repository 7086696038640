import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

const fetchBadgesData = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.BADGE_API}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

export const badgrService = {
  fetchBadgesData
};

const fetchLtiConfigurations = () => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.LTI_CONFIGURATION}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

export function updateLtiConfiguration(data) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.LTI_CONFIGURATION}`;
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    url,
    data: data
  };
  return axios(requestOptions);
}
export function deleteLtiConfiguration(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.LTI_CONFIGURATION}`;
  const requestOptions = {
    method: 'DELETE',
    withCredentials: true,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    params: params,
    url
  };
  return axios(requestOptions);
}
export const ltiConfigurationService = {
  fetchLtiConfigurations,
  updateLtiConfiguration,
  deleteLtiConfiguration
};
