import React from 'react';

import Header from 'components/header';
import 'pages/404/index.scss';

const Page404 = () => {
  return (
    <div>
      <Header />
      <div className="error-page">
        <div className="error-block">
          <img src="https://edly-edx-theme-files.s3.amazonaws.com/error-page-assets/error-404.svg" alt="Error 404" />
          <h1>Page Not Found!</h1>
          <h3 className="copy">
            The page you are looking for may have been moved or renamed.
            <br />
            If you continue to have issues, please contact <a href="mailto:support@edly.io">Edly Support</a>.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Page404;
