import React from 'react';

import { getLearnerCompletedCourses } from 'helpers';

const moment = require('moment');
const lodash = require('lodash');

const LearnerInfo = (props) => {
  const { learner_info } = props;
  const registration_fields = learner_info.registration_fields;

  return (
    <div className="learner-info-container">
      <div>
        <strong>Username:</strong> {learner_info.username}
      </div>
      <div className="email">
        <strong>Email:</strong> {learner_info.email}
      </div>
      <br />
      {Object.keys(registration_fields).map((key) => {
        return (
          <div key={key}>
            <strong>{lodash.startCase(key)}:</strong> {registration_fields[key]}
          </div>
        );
      })}
      <hr />
      <div>
        <strong>Courses Enrolled:</strong> {learner_info.courses.length}
      </div>
      <div>
        <strong>Courses Completed:</strong> {getLearnerCompletedCourses(learner_info.courses).length}
      </div>
      <hr />
      <div>
        <strong>Status:</strong> {learner_info.is_active === true ? 'Active' : 'In-active'}
      </div>
      <div>
        <strong>Account Created:</strong>{' '}
        {learner_info.date_joined ? moment(learner_info.date_joined).format('YYYY-M-DD') : 'N/A'}
      </div>
      <div>
        <strong>Last Login:</strong>{' '}
        {learner_info.last_login ? moment(learner_info.last_login).format('YYYY-M-DD HH:mm:ss') : 'N/A'}
      </div>
      <div>
        <strong>Last Course Activity:</strong>{' '}
        {learner_info.course_activity_date
          ? moment(learner_info.course_activity_date).format('YYYY-M-DD HH:mm:ss')
          : 'N/A'}
      </div>
    </div>
  );
};

export default LearnerInfo;
