import React from 'react';

import CourseListItem from 'pages/insights/containers/coursesAnalyticsContainer/components/courseListItem';

const CoursesListing = (props) => {
  const { courses_data } = props;
  return courses_data.map((course, index) => {
    return <CourseListItem key={index} course={course} />;
  });
};

export default CoursesListing;
