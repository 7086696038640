import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import BarChartTrend from 'components/charts/barChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getTotalActiveCoursesDataLabels,
  getTotalActiveCoursesDataValues,
  getCurrentTotalActiveCoursesTotalCount,
  getCurrentTotalActiveCoursesPercentageChange
} from 'selectors';

const ActiveCourses = (props) => {
  const { data_labels, data_values, percent_change, total_count } = props;

  return (
    <div className="stats-box">
      <div className="row align-items-center">
        <div className="col-6 col-xl-7">
          <h1>{total_count}</h1>
        </div>
        <div className="col-6 col-xl-5 justify-content-end d-flex">
          <PercentChangeBox percent_change={percent_change} />
        </div>
      </div>
      <span className="sub-title">
        Active Courses
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">Number of courses that are currently running.</span>
        </span>
      </span>
      <BarChartTrend title="Courses Count" labels={data_labels} values={data_values} />
    </div>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalActiveCoursesDataLabels(state);
  const data_values = getTotalActiveCoursesDataValues(state);
  const percent_change = getCurrentTotalActiveCoursesPercentageChange(state);
  const total_count = getCurrentTotalActiveCoursesTotalCount(state);

  return {
    data_labels,
    data_values,
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(ActiveCourses);
