import './index.scss';
import CustomSiteDetails from './containers/components/customSiteDetails';
import { siteDetailsActions } from './containers/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const SiteDetails = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(siteDetailsActions.fetchTrialUser());
    dispatch(siteDetailsActions.fetchSiteDetails());
  }, [dispatch]);

  return (
    <>
      <CustomSiteDetails />
    </>
  );
};

export default SiteDetails;
