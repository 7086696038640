import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { PANEL_TLD_DOMAIN } from 'config';
import UserSite from 'pages/dashboard/containers/sites/components/userSite';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import { checkUserPanelAccess } from 'helpers/dashboard';
import { getUserPanelRole } from 'selectors';

const UserSites = (props) => {
  const { userSites, dispatch, creatingSite, setCreatingSite, handleSetLoading, panel_role } = props;
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    dispatch(userSiteActions.fetchUserSites());
  }, [dispatch]);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const isCreateSiteVisible = window.location.origin.includes(PANEL_TLD_DOMAIN) && checkUserPanelAccess(panel_role);
  return (
    <div>
      <div className="integration-holder">
        <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center sites-holder">
          <h2>Your sites</h2>
          <div className="d-flex justify-content-between justify-content-md-end">
            <div className="d-flex d-inline-md-flex">
              <div
                className={`btn-slider ${currentSlide === 0 ? 'disable-slider' : ''}`}
                onClick={(event) => {
                  event.preventDefault();
                  const prevSlide = currentSlide - 1;
                  if (prevSlide >= 0) {
                    slider.current.slickGoTo(prevSlide);
                    setCurrentSlide(prevSlide);
                  }
                }}
              >
                <FontAwesomeIcon icon={Icons.faArrowLeft} />
              </div>
              <div
                className={`btn-slider ${
                  userSites.length < 4 || currentSlide + 3 === userSites.length ? 'disable-slider' : ''
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  const nextSlide = currentSlide + 1;
                  if (nextSlide < userSites.length) {
                    slider.current.slickGoTo(nextSlide);
                    setCurrentSlide(nextSlide);
                  }
                }}
              >
                <FontAwesomeIcon icon={Icons.faArrowRight} />
              </div>
              {isCreateSiteVisible && (
                <button
                  className="btn btn-primary ml-2"
                  style={{ display: creatingSite ? 'none' : 'block' }}
                  onClick={() => setCreatingSite(true)}
                >
                  Create new site{' '}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="app-block">
          <div className="sites-slider">
            <Slider {...settings} ref={slider}>
              <UserSite site={{ slug: 'all' }} />
              {userSites.map((item, index) => {
                return <UserSite handleSetLoading={handleSetLoading} site={item} key={index} />;
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  return {
    panel_role,
    userSites: state.USER_SITES.user_site
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const connectedUserSite = connect(mapStateToProps, mapDispatchToProps)(UserSites);
export default connectedUserSite;
