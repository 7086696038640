import React, { useState, Fragment } from 'react';
import Pagination from 'react-js-pagination';
import { useHistory } from 'react-router-dom';

import { PAGE_SIZE } from 'config';
import SearchInput from 'components/analytics/searchInput';
import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import CoursesListing from 'pages/insights/containers/coursesAnalyticsContainer/components/coursesListing';

const CoursesOverview = (props) => {
  const history = useHistory();
  const { courses_count, courses_data, current_page, dispatch } = props;
  const [searchText, setSearchText] = useState('');
  const [timer, setTimer] = useState(null);

  const handlePageChange = (page_number) => {
    const currentParams = new URLSearchParams(window.location.search);
    const newSearchText = currentParams.get('searchText');
    if (newSearchText) {
      dispatch(analyticsSummaryActions.fetchCoursesGeneralData({ page: page_number, search: newSearchText }));
    } else {
      dispatch(analyticsSummaryActions.fetchCoursesGeneralData({ page: page_number, searchText: searchText }));
    }
  };

  const handleInputChange = (event) => {
    let searchText = event.target.value;
    setSearchText(searchText);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        dispatch(analyticsSummaryActions.fetchCoursesGeneralData({ page: 1, search: searchText }));
      }, 400)
    );
    if (searchText !== '') {
      history.push({
        search: `?searchText=${searchText}`
      });
    } else {
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.delete('searchText');
      history.push({ search: currentParams.toString() });
    }
  };

  return (
    <Fragment>
      <div className="learner-overview-header">
        <SearchInput label="Search Courses" searchText={searchText} handleInputChange={handleInputChange} />
      </div>
      {courses_data && courses_data.length > 0 ? (
        <Fragment>
          <CoursesListing courses_data={courses_data} />
          <div className="table-footer">
            <Pagination
              activePage={current_page}
              itemsCountPerPage={PAGE_SIZE}
              totalItemsCount={courses_count}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </div>
        </Fragment>
      ) : (
        <span className="no-data">{searchText !== '' ? 'No Data Available' : ''}</span>
      )}
    </Fragment>
  );
};

export default CoursesOverview;
