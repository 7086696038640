import React, { Fragment, useState } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card } from 'react-bootstrap';

const DownloadFilter = (props) => {
  const { handleFilterChange } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const filters = {
    download_maus: 'Download MAUS',
    download_user_report: 'Download User Report'
  };

  const resetButtonHandler = () => {
    setIsFilterOpen(false);
  };

  const filterListItems = Object.keys(filters).map((key, index) => {
    return (
      <li key={key} style={{ maxWidth: '320px' }}>
        <button
          onClick={() => {
            setIsFilterOpen(false);
            handleFilterChange(key);
          }}
        >
          {filters[key]}
        </button>
      </li>
    );
  });

  return (
    <Fragment>
      <div className="maus-filter-container">
        <Accordion className="maus-filter">
          <Card className="small-drop">
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              Download
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse className={isFilterOpen ? 'show' : ''}>
              <Card.Body>
                <ul>{filterListItems}</ul>
                <div className="maus-filter-footer">
                  <Button variant="link" onClick={resetButtonHandler}>
                    Close
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default DownloadFilter;
