export const themesConstants = {
  FETCH_THEMES_SUCCESS: 'THEMES_FETCH_SUCCESS',
  FETCH_THEMES_FAILURE: 'THEMES_FETCH_FAILURE'
};

const fetchThemesSuccess = (themes) => {
  return { type: themesConstants.FETCH_THEMES_SUCCESS, payload: themes.data };
};

const fetchThemesFailure = (error) => {
  return { type: themesConstants.FETCH_ORGANIZATIONS_FAILURE, payload: error };
};

export const themesDispatches = {
  fetchThemesSuccess,
  fetchThemesFailure
};
