import React, { useState } from 'react';
import './lmsKeyInput.css';
import { updateEdlySiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';
import { LMS_GTM_ID_PLACEHOLDER, LMS_GA_ID_PLACEHOLDER } from '../constants';

const LmsKeyInput = () => {
  const [lmsGTM, setLmsGTM] = useState('');
  const [lmsGA, setLmsGA] = useState('');

  const updateKey = () => {
    if (lmsGTM === '' && lmsGA === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill atleast one field for LMS keys');
      return;
    }

    const lms_data = {
      lms: {
        ...(lmsGTM && { GTM_ID: lmsGTM }),
        ...(lmsGA && { GA_ID: lmsGA })
      },
      cms: {
        ...(lmsGTM && { GTM_ID: lmsGTM }),
        ...(lmsGA && { GA_ID: lmsGA })
      }
    };

    updateEdlySiteConfig(lms_data)
      .then((res) =>
        messageNotification(
          'success',
          'Key updated',
          `The following keys have been updated:
         ${lmsGTM && 'GTM_ID: ' + lmsGTM}
          ${lmsGA && 'GA_ID: ' + lmsGA}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  return (
    <div className="integration-holder">
      <h3>Google analytics for LMS</h3>
      <p>Add Google Analytics for Property (GA4) and Google Tag Manger (GTM) keys for LMS</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="form-label">GTM ID</label>
          <input
            className="form-control"
            value={lmsGTM}
            placeholder={LMS_GTM_ID_PLACEHOLDER}
            onChange={(event) => setLmsGTM(event.target.value)}
          ></input>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">GA4 ID</label>
          <input
            className="form-control"
            value={lmsGA}
            placeholder={LMS_GA_ID_PLACEHOLDER}
            onChange={(event) => setLmsGA(event.target.value)}
          ></input>
        </div>
        <div className="col-12 btn-holder justify-content-end d-flex">
          <button className="btn btn-primary" onClick={updateKey}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default LmsKeyInput;
