import { PAGE_SIZE, FIGURES_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';
import { getInsights } from 'services/insights/methods';

export const coursesAnalyticsService = {
  fetchCourseEnrollmentsData,
  fetchCourseAllLearnersData,
  fetchAllCoursesGeneralData,
  fetchCourseDetail,
  fetchCourseDetailCSV,
  fetchCoursesMAUsData,
  fetchInsightsCoursesCSV
};

function fetchCourseEnrollmentsData(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSE_ENROLLMENTS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchCourseAllLearnersData(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSE_ENROLLMENTS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchAllCoursesGeneralData(course_id) {
  let url = `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSES_GENERAL_API}`;
  if (course_id !== undefined) {
    url = `${url}?course_id=${course_id}`;
  }

  let paramsData = {
    url: url,
    page_size: PAGE_SIZE
  };
  return getInsights(paramsData);
}

function fetchCourseDetail(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSE_DETAIL_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchCourseDetailCSV(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSE_DETAIL_CSV_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchCoursesMAUsData() {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSES_MAUS_API}`
  };
  return getInsights(paramsData);
}

function fetchInsightsCoursesCSV() {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.INSIGHTS_COURSES_CSV}`
  };
  return getInsights(paramsData);
}
