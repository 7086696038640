import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const SearchInput = (props) => {
  const { label, searchText, handleInputChange } = props;
  return (
    <div className="search-bar small">
      <input
        className="form-control"
        placeholder={label}
        id="search"
        name="search"
        value={searchText}
        onChange={handleInputChange}
      />
      <div className="input-group-append">
        <button className="btn btn-primary" type="button" id="button-search-learner">
          <FontAwesomeIcon icon={Icons.faSearch} className="fa-lg" />
          <span className="sr-only">{label}</span>
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
