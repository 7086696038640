import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import { downloadMultiPagePDF, messageNotification } from 'helpers';
import { courseAnalyticsActions } from 'pages/insights/containers/courseDetailContainer/actions';

const DownloadCourseDetail = (props) => {
  const { all_learners_info, course_id } = props;
  const [requestDownload, setRequestDownload] = useState(false);

  useEffect(() => {
    if (requestDownload === true && all_learners_info.length > 0) {
      setRequestDownload(false);
    }
  }, [all_learners_info, requestDownload]);

  const handleFilterChange = (value) => {
    if (value === 'pdf') {
      downloadMultiPagePDF('course_detail');
    } else if (value === 'csv') {
      props.dispatch(courseAnalyticsActions.fetchCourseDetailCSV({ course_id: course_id }));
    }

    if (value === 'pdf' && all_learners_info) {
      messageNotification('success', 'Course Detail Export', 'Course Detail Information Export In Progress');
    }
  };

  return (
    <div id="download_dropdown">
      <DropdownButton title={requestDownload ? 'Generating Report' : 'Export Report'} disabled={requestDownload}>
        <Dropdown.Item onClick={() => handleFilterChange('pdf')}>Download PDF</Dropdown.Item>
        <Dropdown.Item onClick={() => handleFilterChange('csv')}>Email CSV</Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default DownloadCourseDetail;
