import React, { Fragment } from 'react';

import DateFilter from 'components/dateFilter';
import StatsBox from 'components/analytics/statsBox';
import CompletionsOverTime from 'pages/insights/containers/courseDetailContainer/components/completionsOverTime';
import EnrollmentsOverTime from 'pages/insights/containers/courseDetailContainer/components/enrollmentsOverTime';

const SummaryStatsCourseDetail = (props) => {
  const {
    course_info,
    course_detail,
    setDefaultFilter,
    handleFilterChange,
    handleRefresh,
    handleSetEndDate,
    handleSetStartDate,
    CourseDetailFilterType
  } = props;

  return (
    <Fragment>
      <div className="stats-block">
        <StatsBox
          value={course_detail && course_detail.metrics ? course_detail.metrics.enrollment_count : '0'}
          label="Total Enrollments"
          description="Total number of learners who have enrolled in the course since launch."
        />

        <StatsBox
          value={course_detail && course_detail.metrics ? course_detail.metrics.num_learners_completed : '0'}
          label="Total Completions"
          description="Total number of the times the course has been completed."
        />

        <StatsBox
          value={
            course_detail &&
            course_detail.metrics &&
            course_detail.metrics.num_learners_completed > 0 &&
            course_detail.metrics.enrollment_count > 0
              ? `${Math.round(
                  (course_detail.metrics.num_learners_completed / course_detail.metrics.enrollment_count) * 100
                )}%`
              : '0%'
          }
          label="Completion Rate"
          description="Learners who completed the course divided by total enrolled learners."
        />

        <StatsBox
          value={course_detail && course_detail.metrics ? course_detail.metrics.active_learners_this_month : '0'}
          label="Active Learners"
          description="Numbers of learners in the course were active in the current calendar month."
        />

        <StatsBox
          value={
            course_detail && course_detail.metrics
              ? `${Math.round(course_detail.metrics.average_progress * 100)}%`
              : '0%'
          }
          label="Average Course Progress"
          description="Average of the progress of all learners enrolled in the course."
        />

        <StatsBox
          value={course_detail && course_detail.metrics ? course_detail.metrics.average_days_to_complete : '0'}
          label="Average Days to Complete"
          description="Average time it took for the learner to complete the course in days."
        />
      </div>

      <DateFilter
        filterType={CourseDetailFilterType}
        handleRefresh={handleRefresh}
        handleFilterChange={handleFilterChange}
        handleSetStartDate={handleSetStartDate}
        handleSetEndDate={handleSetEndDate}
        setDefaultFilter={setDefaultFilter}
      />
      <div className="stats-block has-two-columns">
        <EnrollmentsOverTime course_info={course_info} />
        <CompletionsOverTime course_info={course_info} />
      </div>
    </Fragment>
  );
};

export default SummaryStatsCourseDetail;
