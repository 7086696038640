import React, { Component } from 'react';
import { Router, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clarity } from 'react-microsoft-clarity';

import { CLARITY_PROJECT_ID, FETCH_SERVICES_NOTIFICATION_TIMER } from 'config';
import { getUserSettings, getUserPanelRole } from 'selectors';
import Routes from 'components/routes/Routes';
import ErrorBoundary from 'components/error-boundary';
import FullPageLoader from 'components/loaders/FullPage';
import {
  history,
  configurePanelApp,
  setOrganizationFromQueryParam,
  hubspotChat,
  getBillingRedirectFromQueryParam
} from 'helpers';
import { notificationActions } from 'pages/notifications/containers/notifications/actions';
import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import { themesActions } from 'pages/appearance/containers/appearanceContainer/actions/themes';
import { quickStatsActions } from 'pages/dashboard/containers/quickStats/actions/quickStats';

import 'react-notifications-component/dist/theme.css';
import 'assets/sass/style.scss';

class App extends Component {
  componentDidMount() {
    getBillingRedirectFromQueryParam();
    setOrganizationFromQueryParam();
    this.props.fetchOrganization();

    this.services_notifications_timer = setInterval(
      () => this.props.fetchServicesNotifications(),
      FETCH_SERVICES_NOTIFICATION_TIMER
    );

    clarity.init(CLARITY_PROJECT_ID);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.is_loading === false &&
      this.props.username !== '' &&
      prevProps.is_loading !== this.props.is_loading &&
      this.props.panel_role !== 'panel_restricted'
    ) {
      configurePanelApp(this.props);
      hubspotChat();
    }
  }

  componentWillUnmount() {
    this.services_notifications_timer = null;
  }

  render() {
    return (
      <Router history={history}>
        <ErrorBoundary>
          {this.props.username === '' && this.props.is_loading === false && <Redirect to={'/panel/login'} />}
          {this.props.panel_role === 'panel_user' &&
            (this.props.path_name === '/panel/users' || this.props.path_name === '/panel/insights') && (
              <Redirect to={'/panel'} />
            )}
          {this.props.is_loading ? <FullPageLoader /> : <Routes panel_role={this.props.panel_role} />}
        </ErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  const { is_loading, username } = getUserSettings(state);
  let panel_role = getUserPanelRole(state);
  let path_name = window.location.pathname;
  return {
    is_loading,
    username,
    panel_role,
    path_name
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...notificationActions,
        ...settingsActions,
        ...usersActions,
        ...themesActions,
        ...quickStatsActions
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
