import Cookies from 'js-cookie';
import { siteDetailsConstants, FIFTEEN_MINUTES_EXPIRATION } from '../constants';

const dnsEntriesInitialState = {
  dnsLoading: false
};

const freeTrialInitialState = {
  isTrialUser: false
};

const siteDetailsInitialState = {
  siteUrl: null,
  email: null,
  dnsList: [],
  showSave: false,
  loading: false,
  showError: false,
  secondJobLoading: false,
  dnsUpdated: false,
  redirect: false,
  verified: false
};

export const dnsEntriesReducer = (state = dnsEntriesInitialState, action) => {
  switch (action.type) {
    case siteDetailsConstants.POST_SITE_DETAILS_REQUEST:
      return { ...state, dnsLoading: true };
    case siteDetailsConstants.POST_SITE_DETAILS_SUCCESS:
      return { ...state, dnsLoading: true };
    case siteDetailsConstants.POST_SITE_DETAILS_FAILURE:
      return { ...state, dnsLoading: false };
    default:
      return state;
  }
};

export const freeTrialReducer = (state = freeTrialInitialState, action) => {
  switch (action.type) {
    case siteDetailsConstants.FETCH_TRIAL_USER_SUCCESS:
      return { ...state, isTrialUser: action.payload.trial };
    case siteDetailsConstants.FETCH_TRIAL_USER_FAILURE:
      return { ...state, isTrialUser: true };
    default:
      return state;
  }
};

export const updateClientSetupReducer = (state = {}, action) => {
  switch (action.type) {
    case siteDetailsConstants.JENKINS_DNS_SECOND_JOB_SUCCESS:
      return { ...state };
    case siteDetailsConstants.JENKINS_DNS_SECOND_JOB_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export const siteDetailsReducer = (state = siteDetailsInitialState, action) => {
  switch (action.type) {
    case siteDetailsConstants.FETCH_SITE_DETAILS_SUCCESS:
      return {
        ...state,
        siteUrl: action.payload.site_url,
        email: action.payload.email,
        showSave: action.payload.dns_save,
        loading: action.payload.loading,
        dnsList: action.payload.dns_list,
        showError: action.payload.second_job_triggered === siteDetailsConstants.FAILURE,
        secondJobLoading: action.payload.second_job_triggered,
        dnsUpdated: action.payload.dns_updated
      };
    case siteDetailsConstants.FETCH_SITE_DETAILS_FAILURE:
      return { ...state, siteUrl: null, email: null, showSave: false, loading: false, dnsList: [], showError: false };
    case siteDetailsConstants.JENKINS_DNS_SECOND_JOB_SUCCESS:
      return { ...state, showError: false };
    case siteDetailsConstants.JENKINS_DNS_SECOND_JOB_FAILURE:
      return { ...state, showError: true };
    case siteDetailsConstants.FETCH_JOB_STATE_SUCCESS:
      return {
        ...state,
        secondJobLoading: action.payload.second_job_triggered,
        showError: false,
        redirect: action.payload.redirect
      };
    case siteDetailsConstants.FETCH_JOB_STATE_FAILURE:
      Cookies.set('show_dns_error', true, { expires: FIFTEEN_MINUTES_EXPIRATION });
      return { ...state, secondJobLoading: false, showError: true };
    case siteDetailsConstants.FETCH_VERIFY_DNS_ENTRIES_SUCCESS:
      return { ...state, verified: true };
    case siteDetailsConstants.FETCH_VERIFY_DNS_ENTRIES_FAILURE:
      return { ...state, verified: false };
    case siteDetailsConstants.POST_RESET_DNS_RECORD_SUCCESS:
      return { ...state, redirect: true };
    case siteDetailsConstants.POST_RESET_DNS_RECORD_FAILURE:
      return { ...state, redirect: false };
    default:
      return state;
  }
};
