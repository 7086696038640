import { messageNotification, validateLoginSession } from 'helpers';
import { quickStatsService } from 'services/quickStats';
import { learnersAnalyticsService } from 'services/insights';
import { learnersAnalyticsDispatches } from 'pages/insights/containers/learnersAnalyticsContainer/constants';

const moment = require('moment');

export const learnersAnalyticsActions = {
  fetchUsersDetailAnalytics,
  fetchAllUsersAnalytics,
  fetchLearnersMAUs,
  fetchLearnersCourseCompletions,
  downloadLearnersDetailsPDF,
  fetchLearnersSummaryCSV
};

function fetchUsersDetailAnalytics(payload = { page: 1, search: '', learners_only: 'true' }, stopLoader) {
  return (dispatch) => {
    learnersAnalyticsService
      .fetchUsersDetailAnalytics(payload)
      .then((usersData) => {
        Promise.resolve(dispatch(learnersAnalyticsDispatches.usersDetailAnalyticsSuccess(usersData))).then(() => {
          if (stopLoader !== undefined) {
            stopLoader();
          }
        });
      })
      .catch((error) => {
        dispatch(learnersAnalyticsDispatches.usersDetailAnalyticsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching users detail analytics', error.response.data.error);
      });
  };
}

function fetchAllUsersAnalytics() {
  return (dispatch) => {
    learnersAnalyticsService
      .fetchAllUsersAnalytics()
      .then((usersData) => {
        return Promise.resolve(dispatch(learnersAnalyticsDispatches.fetchAllUsersAnalyticsSuccess(usersData)));
      })
      .catch((error) => {
        dispatch(learnersAnalyticsDispatches.fetchAllUsersAnalyticsFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
}

function fetchLearnersMAUs(payload) {
  let params = getMAUSPayloadParams(payload);
  return (dispatch) => {
    learnersAnalyticsService
      .fetchLearnersMAUs(params)
      .then((usersData) => {
        Promise.resolve(dispatch(learnersAnalyticsDispatches.fetchLearnersMausSuccess(usersData)));
      })
      .catch((error) => {
        dispatch(learnersAnalyticsDispatches.fetchLearnersMausFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
}

function fetchLearnersCourseCompletions(payload) {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    quickStatsService
      .fetchCourseCompletions(params)
      .then((courseCompletionsData) => {
        Promise.resolve(
          dispatch(learnersAnalyticsDispatches.fetchLearnersCourseCompletionsSuccess(courseCompletionsData))
        );
      })
      .catch((error) => {
        dispatch(learnersAnalyticsDispatches.fetchLearnersCourseCompletionsFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
}

function downloadLearnersDetailsPDF() {
  return (dispatch) => {
    quickStatsService
      .downloadLearnersPDF()
      .then((download_data) => {
        Promise.resolve(dispatch(learnersAnalyticsDispatches.downloadLearnersDetailsPDFSuccess(download_data)));
      })
      .catch((error) => {
        dispatch(learnersAnalyticsDispatches.downloadLearnersDetailsPDFFailure(error));
        validateLoginSession(dispatch, error);
      });
  };
}

function fetchLearnersSummaryCSV(payload) {
  const { filter_roles, filter_start_date, filter_end_date } = payload;
  return (dispatch) => {
    const params = {
      type: 'custom',
      start_date: filter_start_date,
      end_date: filter_end_date,
      roles: filter_roles
    };

    learnersAnalyticsService
      .fetchLearnersSummaryCSV(params)
      .then((userData) => {
        messageNotification('success', 'Emailing learners detail csv report', userData.data['message']);
      })
      .catch((error) => {
        messageNotification(
          'danger',
          'There was an error fetching learners detail csv report',
          error.response.data.error
        );
        validateLoginSession(dispatch, error);
      });
  };
}

function getPayloadParams(payload) {
  const { filter_type, filter_year, filter_roles, filter_start_date, filter_end_date } = payload;
  let params = {
    type: 'custom',
    start_date: filter_start_date,
    end_date: filter_end_date,
    roles: filter_roles,
    year: filter_year
  };

  switch (filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('D-MM-YYYY');
      params['year'] = moment()
        .subtract(1, 'year')
        .format('YYYY');
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('D-MM-YYYY');
      params['year'] = moment().format('YYYY');
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'custom':
      params['start_date'] = moment(filter_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(filter_end_date).format('D-MM-YYYY');
      break;
    default:
      break;
  }

  return params;
}

const getMAUSPayloadParams = (payload) => {
  const { filter_type, filter_start_date, filter_end_date } = payload;

  let params = { roles: 'learner' };
  switch (filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('M-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('M-YYYY');
      params['year'] = moment()
        .subtract(1, 'year')
        .format('YYYY');
      params['type'] = 'yearly';
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('M-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('M-YYYY');
      params['year'] = moment().format('YYYY');
      params['type'] = 'yearly';
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    case 'custom':
      params['start_date'] = moment(filter_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(filter_end_date).format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    default:
      break;
  }

  return params;
};
