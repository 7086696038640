import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const PanelRolesButton = (props) => {
  const { inputs, handleInputChange } = props;
  return (
    <div className="form-group">
      <label htmlFor="admin">Edly Panel Role</label>
      <div className="roles-row">
        <div className="custom-control custom-radio">
          <input
            type="radio"
            name="panel_role"
            className="custom-control-input"
            id="panel_restricted_filter"
            onChange={handleInputChange}
            checked={inputs.panel_role.toString() === 'panel_restricted'}
            value="panel_restricted"
          />
          <label className="custom-control-label" htmlFor="panel_restricted_filter">
            Restricted
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                <span className="tip-content-item">User does not have access to Edly Panel</span>
              </span>
            </span>
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            name="panel_role"
            className="custom-control-input"
            id="panel_user_filter"
            onChange={handleInputChange}
            checked={inputs.panel_role.toString() === 'panel_user'}
            value="panel_user"
          />
          <label className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`} htmlFor="panel_user_filter">
            Panel User
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                <span className="tip-content-item">User can access Edly Panel</span>
                <span className="tip-content-item">User can view Quick Stats, and other basic information</span>
                <span className="tip-content-item">User cannot edit anything or make any changes</span>
              </span>
            </span>
          </label>
        </div>

        <div className="custom-control custom-radio">
          <input
            type="radio"
            name="panel_role"
            className="custom-control-input"
            id="insights_admin_filter"
            onChange={handleInputChange}
            checked={inputs.panel_role.toString() === 'insights_admin'}
            value="insights_admin"
          />
          <label
            className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`}
            htmlFor="insights_admin_filter"
          >
            Insights Viewer
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                <span className="tip-content-item">User can access the Edly Panel Insights</span>
                <span className="tip-content-item">
                  User can view Quick Stats, Insights and other basic information
                </span>
              </span>
            </span>
          </label>
        </div>

        <div className="custom-control custom-radio">
          <input
            type="radio"
            name="panel_role"
            className="custom-control-input"
            id="panel_admin_filter"
            onChange={handleInputChange}
            checked={inputs.panel_role.toString() === 'panel_admin'}
            value="panel_admin"
          />
          <label className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`} htmlFor="panel_admin_filter">
            Panel Admin
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">
                <span className="tip-content-item">User can access the Edly Panel</span>
                <span className="tip-content-item">
                  User can view Quick Stats, Pricing Plans, Insights and other basic information
                </span>
                <span className="tip-content-item">User can change themes and user roles</span>
                <span className="tip-content-item">User can also add new users or delete existing ones</span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PanelRolesButton;
