import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { convertValueTo2DP } from 'helpers';
import BarChart from 'components/charts/barChart';
import BarChartTrend from 'components/charts/barChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import {
  getMAUsQuickStats,
  getMAUsQuickStatsLabels,
  getMAUsQuickStatsTotalCount,
  getMAUsQuickStatsPercentageChange
} from 'selectors';

const MAUSAnalytics = (props) => {
  const { total_count, percent_change, data_labels, data_values, show_chart, trending, title } = props;

  return (
    <div className="stats-box">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="mb-0" test-data-field="current-maus">
          {total_count}
        </h1>
        <PercentChangeBox percent_change={convertValueTo2DP(percent_change)} />
        {show_chart &&
          (trending === 'true' ? (
            <BarChartTrend title="MAUs Count" labels={data_labels} values={data_values} />
          ) : (
            <BarChart title="MAUS Count" labels={data_labels} values={data_values} />
          ))}
      </div>
      <span className="sub-title">
        {title}
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">
            Monthly active users (MAUs) refers to the number of unique learners who signed in to your site in the
            current calendar month. This includes users who created an account for the first time and users who accessed
            your site while already logged in.
          </span>
        </span>
      </span>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getMAUsQuickStatsLabels(state);
  const data_values = getMAUsQuickStats(state);
  const total_count = getMAUsQuickStatsTotalCount(state);
  const percent_change = getMAUsQuickStatsPercentageChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    total_count,
    percent_change,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(MAUSAnalytics);
