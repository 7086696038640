import { analyticsSummaryConstants } from 'pages/insights/containers/analyticsSummaryContainer/constants';

const coursesInitialState = {
  courses_count: 0,
  current_page: 1,
  total_pages: 1,
  previous: null,
  next: null,
  courses_data: null
};

export function fetchCoursesGeneralData(state = coursesInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_COURSES_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        courses_count: action.payload.count,
        current_page: action.payload.current_page,
        total_pages: action.payload.total_pages,
        previous: action.payload.previous,
        next: action.payload.next,
        courses_data: action.payload.results
      };
    case analyticsSummaryConstants.FETCH_COURSES_GENERAL_INFO_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const generalSiteMetricsInitialState = {
  total_site_learners: {
    current_month: 0,
    history: null
  },
  total_site_staff_users: {
    current_month: 0,
    history: []
  },
  total_site_courses: {
    current_month: 0,
    history: []
  },
  total_active_courses: {
    current_month: 0,
    history: []
  }
};

export function fetchGeneralSiteMetrics(state = generalSiteMetricsInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_GENERAL_SITE_METRICS_SUCCESS:
      return {
        ...state,
        total_site_learners: action.payload.total_site_learners,
        total_site_staff_users: action.payload.total_site_staff_users,
        total_site_courses: action.payload.total_site_courses,
        total_active_courses: action.payload.total_active_courses
      };
    case analyticsSummaryConstants.FETCH_GENERAL_SITE_METRICS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const monthlyUsersStatsInitialState = {
  total_learners_count: 0,
  total_staff_count: 0,
  dates: [],
  users_stats: []
};

export function fetchMonthlyUsersStats(state = monthlyUsersStatsInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_MONTHLY_USERS_STATS_SUCCESS:
      return {
        ...state,
        total_learners_count: action.payload.data.total_learners_count,
        total_staff_count: action.payload.data.total_staff_count,
        dates: action.payload.data.dates,
        users_stats: action.payload.data.users_stats
      };
    case analyticsSummaryConstants.FETCH_MONTHLY_USERS_STATS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const coursesCompletionInitialState = {
  completion_count: 0,
  next: null,
  previous: null,
  completion_results: []
};

export function fetchCoursesCompletionStats(state = coursesCompletionInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_COURSES_COMPLETION_SUCCESS:
      return {
        ...state,
        completion_count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        completion_results: action.payload.results
      };
    case analyticsSummaryConstants.FETCH_COURSES_COMPLETION_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const coursesEnrollmentInitialState = {
  enrollment_count: 0,
  next: null,
  previous: null,
  enrollment_results: []
};

export function fetchCoursesEnrollmentStats(state = coursesEnrollmentInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_COURSES_ENROLLMENT_SUCCESS:
      return {
        ...state,
        enrollment_count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        enrollment_results: action.payload.results
      };
    case analyticsSummaryConstants.FETCH_COURSES_ENROLLMENT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}

const monthlyMeticsInitialState = {
  active_users: 0,
  site_courses: 0,
  course_enrollments: 0,
  registered_users: 0,
  new_users: 0,
  course_completions: 0
};

const siteMetricsInitialState = {
  site_daily_metrics: {
    date_for: null,
    cumulative_active_user_count: 0,
    todays_active_user_count: 0,
    todays_active_learners_count: 0,
    total_user_count: 0,
    course_count: 0,
    total_enrollment_count: 0
  },
  site_monthly_metrics: {
    current_month: monthlyMeticsInitialState,
    last_month: monthlyMeticsInitialState
  }
};

export function fetchSiteMetrics(state = siteMetricsInitialState, action) {
  switch (action.type) {
    case analyticsSummaryConstants.FETCH_SITE_DAILY_METRICS_SUCCESS:
      let site_daily_metrics = siteMetricsInitialState.site_daily_metrics;
      if (action.payload.results.length > 0) {
        site_daily_metrics = action.payload.results[0];
      }
      return {
        ...state,
        site_daily_metrics: site_daily_metrics
      };
    case analyticsSummaryConstants.FETCH_SITE_DAILY_METRICS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case analyticsSummaryConstants.FETCH_SITE_MONTHLY_METRICS_SUCCESS:
      return {
        ...state,
        site_monthly_metrics: action.payload
      };
    case analyticsSummaryConstants.FETCH_SITE_MONTHLY_METRICS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
