import React from 'react';
import 'pages/notifications/index.scss';

import NotificationsCenter from 'pages/notifications/containers/notifications/notificationsCenter';

const Notifications = () => {
  return (
    <div className="block">
      <NotificationsCenter />
    </div>
  );
};

export default Notifications;
