import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import LineChart from 'components/charts/lineChart';

import InsightsLoader from 'components/loaders/InsightsLoader';

const EnrollmentsOverTime = (props) => {
  const { course_info } = props;

  const labels =
    course_info &&
    course_info.learners_enrolled &&
    course_info.learners_enrolled.history.map((data) => {
      return data.period;
    });
  const values =
    course_info &&
    course_info.learners_enrolled &&
    course_info.learners_enrolled.history.map((data) => {
      return data.value;
    });

  return (
    <div className="stats-box">
      {course_info && course_info.learners_enrolled && course_info.learners_enrolled.history ? (
        <Fragment>
          <span className="sub-title">
            Enrollments Over Time
            <span className="tool-tip">
              <FontAwesomeIcon icon={Icons.faQuestionCircle} />
              <span className="tip-content">Number of enrollments over time, for the last six months.</span>
            </span>
          </span>
          {labels && values && <LineChart title="Course Enrollments" labels={labels} values={values} />}
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

export default EnrollmentsOverTime;
