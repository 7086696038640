import React from 'react';
import { connect } from 'react-redux';

import { getOrganization } from 'selectors';

const PDFHeader = (props) => {
  const { organization } = props;

  return (
    <div className="pdf-content-header" id="header_content" style={{ display: 'none' }}>
      <img
        src={
          organization.current_site_logo.includes('?')
            ? `${organization.current_site_logo}&v=${new Date().getTime()}`
            : `${organization.current_site_logo}?v=${new Date().getTime()}`
        }
        alt={organization.name}
        crossOrigin="true"
      />
    </div>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  return { organization };
}

export default connect(mapStateToProps, null)(PDFHeader);
