import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import IconArrow from 'assets/images/icon-arrow.svg';
import { sendTrackingEvent } from 'services/tracking';
import { clarity } from 'react-microsoft-clarity';

const DemoModel = (props) => {
  const { isOpen, handleTermsSubmit } = props;
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [showLearnerDemo, setLearnerDemo] = useState(false);
  const [showInstuctorDemo, setshowInstuctorDemo] = useState(false);

  const handleGuideTracking = (guideType) => {
    const form_data = { guide: guideType };
    sendTrackingEvent(form_data).catch((err) => {});
    clarity.setTag('guide', guideType);
  };

  return (
    <Modal className="accept-tos-modal user-guide-model" show={isOpen} onHide={() => {}}>
      <section className="user-guide">
        <div className="form-head">
          <h4>User guide</h4>
        </div>
        <div className={`demo-container ${showLearnerDemo ? 'hide-display' : showInstuctorDemo ? 'hide-display' : ''}`}>
          <div
            id="learner_guide"
            onClick={(event) => {
              setLearnerDemo(true);
              setshowInstuctorDemo(false);
              setIsGuideOpen(true);
              handleGuideTracking('learner_guide');
            }}
          >
            <h5>
              User guide for <strong>learners</strong>
            </h5>
            <img src={IconArrow} alt="Icon Arrow" />
          </div>
          <div
            id="Instructors_guide"
            onClick={(event) => {
              setshowInstuctorDemo(true);
              setLearnerDemo(false);
              setIsGuideOpen(true);
              handleGuideTracking('instructor_guide');
            }}
          >
            <h5>
              User guide for <strong>instructors</strong>
            </h5>
            <img src={IconArrow} alt="Icon Arrow" />
          </div>
        </div>

        <div className={`learner-demo ${showLearnerDemo ? '' : 'hide-display'}`}>
          <h5>
            User guide for <strong>learners</strong>
          </h5>

          <div
            className="sl-embed"
            style={{
              position: 'relative',
              paddingBottom: 'calc(55.34% + 27px)',
              width: '100%',
              height: '0',
              transform: 'scale(1)'
            }}
          >
            <iframe
              title="learner_demo"
              className="sl-demo"
              src="https://app.storylane.io/demo/l0ebybe5myhz"
              name="sl-embed"
              allow="fullscreen; camera; microphone"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                border: 'none',
                height: '100%',
                boxSizing: 'border-box'
              }}
            ></iframe>
          </div>
        </div>

        <div className={`instructors-demo ${showInstuctorDemo ? '' : 'hide-display'}`}>
          <h5>
            User guide for <strong>instructors</strong>
          </h5>
          <div
            className="sl-embed"
            style={{
              position: 'relative',
              paddingBottom: 'calc(55.34% + 27px)',
              width: '100%',
              height: '0',
              transform: 'scale(1)'
            }}
          >
            <iframe
              title="instructor_demo"
              className="sl-demo"
              src="https://app.storylane.io/demo/zgl1qvmufhoz"
              name="sl-embed"
              allow="fullscreen; camera; microphone"
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                border: 'none',
                height: '100%',
                boxSizing: 'border-box'
              }}
            ></iframe>
          </div>
        </div>

        <div className="form-cta">
          <button
            type="button"
            className={`btn outline-primary ${!isGuideOpen ? 'hide-display' : ''}`}
            onClick={(event) => {
              setIsGuideOpen(false);
              setLearnerDemo(false);
              setshowInstuctorDemo(false);
            }}
          >
            Back
          </button>
          <div></div>
          <button
            type="button"
            className={`btn btn-primary ${showLearnerDemo || showInstuctorDemo ? 'hide-display' : ''}`}
            onClick={(event) => {
              handleTermsSubmit();
              handleGuideTracking('skip');
            }}
          >
            Skip
          </button>
          <button
            type="button"
            className={`btn btn-primary ${showLearnerDemo || showInstuctorDemo ? '' : 'hide-display'}`}
            onClick={(event) => {
              handleTermsSubmit();
            }}
          >
            Close
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default DemoModel;
