import { mausService } from 'services';
import { messageNotification, validateLoginSession } from 'helpers';
import { mausDispatches } from 'pages/dashboard/containers/monthlyActiveUsers/constants';
import { addSubOrgParamValue } from 'helpers/dashboard';

const moment = require('moment');

const fetchMAUS = (payload) => {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    mausService
      .fetchMAUS(params)
      .then((userData) => {
        Promise.resolve(dispatch(mausDispatches.fetchMAUsSuccess(userData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(mausDispatches.fetchMAUsFailure(error));
        messageNotification('danger', 'There was an error fetching MAUs', error.response.data.error);
      });
  };
};

const requestMAUSReport = (payload, include_users_data) => {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    params['include_users_data'] = include_users_data;

    mausService
      .requestMAUSReport(params)
      .then((userData) => {
        Promise.resolve(dispatch(mausDispatches.requestMausReportSuccess(userData))).then(() => {
          document.querySelector('.btn-download-csv').click();
          messageNotification(
            'success',
            'Request Detailed Report',
            'Report has been successfully generated and ready for download'
          );
        });
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(mausDispatches.requestMausReportFailure(error));
        messageNotification(
          'danger',
          'MAU Report Request Failed',
          error.response.data.error ? error.response.data.error : 'There was an error requesting MAUs Report!'
        );
      });
  };
};

const fetchMAUsReportCSV = (payload, include_users_data) => {
  return (dispatch) => {
    let params = getPayloadParams(payload);
    params['csv'] = true;
    if (include_users_data) {
      params['include_users_data'] = include_users_data;
    }

    mausService
      .requestMAUSReport(params)
      .then(() => {
        messageNotification('success', 'Request Detailed Report', 'Report will be emailed soon');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification(
          'danger',
          'MAU Report Request Failed',
          error.response.data.error ? error.response.data.error : 'There was an error requesting MAUs Report!'
        );
      });
  };
};

const getPayloadParams = (mausSettings) => {
  const { maus_filter_type, maus_start_date, maus_end_date, course_activity_filter } = mausSettings;
  let params = { roles: 'learner' };
  if (Object.keys(mausSettings).includes('userSites')) {
    addSubOrgParamValue(mausSettings.userSites, params);
  }
  if (course_activity_filter !== undefined) {
    params['course_activity_filter'] = course_activity_filter;
  }

  switch (maus_filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('M-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('M-YYYY');
      params['year'] = moment()
        .subtract(1, 'year')
        .format('YYYY');
      params['type'] = 'yearly';
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('M-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('M-YYYY');
      params['year'] = moment().format('YYYY');
      params['type'] = 'yearly';
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    case 'custom':
      params['start_date'] = moment(maus_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(maus_end_date).format('D-MM-YYYY');
      params['type'] = 'custom';
      break;
    default:
      params['start_date'] = moment()
        .startOf('month')
        .format('M-YYYY');
      params['end_date'] = moment().format('M-YYYY');
      params['type'] = 'monthly';
      break;
  }

  return params;
};

export const mausActions = {
  fetchMAUS,
  requestMAUSReport,
  fetchMAUsReportCSV
};
