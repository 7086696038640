import { FIGURES_API_ENDPOINTS, EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';
import { getInsights } from 'services/insights/methods';

export const analyticsSummaryService = {
  fetchCoursesGeneralData,
  fetchGeneralSiteMetrics,
  fetchMonthlyUsersStats,
  fetchCoursesCompletionStats,
  fetchCoursesEnrollmentStats,
  fetchSiteDailyMetrics,
  fetchSiteMonthlyMetrics,
  fetchInsightsSummaryCSV,
  fetchGeneralSitesMetrics
};

function fetchCoursesGeneralData(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSES_GENERAL_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchGeneralSiteMetrics(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.GENERAL_SITE_METRICS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchGeneralSitesMetrics(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.GENERAL_SITES_METRICS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchMonthlyUsersStats(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_MONTHLY_USERS_STATS}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchCoursesCompletionStats(params) {
  if (!params) {
    params = {};
  }
  params['order_by'] = 'num_learners_completed,desc';
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSES_STATS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchCoursesEnrollmentStats(params) {
  if (!params) {
    params = {};
  }
  params['order_by'] = 'enrollment_count,desc';
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.COURSES_STATS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchSiteDailyMetrics(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.SITE_DAILY_METRIC_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchSiteMonthlyMetrics(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.SITE_MONTHLY_METRIC_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchInsightsSummaryCSV(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.INSIGHTS_SUMMARY_CSV}`,
    requestData: params
  };
  return getInsights(paramsData);
}
