import { useState, useEffect } from 'react';
import SearchInput from 'components/analytics/searchInput';
import LtiConfigurationForm from './ltiConfigurationForm';
import LtiIntegrationList from './ltiConfigurationList';
import InsightsLoader from 'components/loaders/InsightsLoader';
import { connect } from 'react-redux';

const LtiIntegration = ({ ltiConfigurations, isLoading }) => {
  const [isFormOpen, setFormIsOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [configurations, setConfigurations] = useState(ltiConfigurations);

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      setConfigurations(
        ltiConfigurations.filter((item) => {
          if (item.course_key) return item.course_key.includes(searchText);
        })
      );
    } else {
      setConfigurations(ltiConfigurations);
    }
  }, [searchText, ltiConfigurations]);

  const hideModal = () => setFormIsOpen(false);

  return (
    <>
      <div className="lti-header row">
        <div className="col-12 col-lg-7">
          <h2>LTI Integration</h2>
        </div>
        <div className="col-12 col-lg-5 d-flex">
          <SearchInput
            label="Search Course ID"
            searchText={searchText}
            handleInputChange={(event) => setSearchText(event.target.value)}
          />
          <button className="btn btn-primary ml-2" onClick={() => setFormIsOpen(true)}>
            ADD NEW
          </button>
        </div>
      </div>
      {isLoading ? <InsightsLoader /> : <LtiIntegrationList ltiConfigurations={configurations} />}
      <LtiConfigurationForm isFormOpen={isFormOpen} hideModal={hideModal} />
    </>
  );
};

function mapStateToProps(state) {
  const { ltiConfigurations, isLoading } = state.LTI_CONFIGURATIONS;
  return {
    ltiConfigurations,
    isLoading
  };
}
export default connect(mapStateToProps)(LtiIntegration);
