import { messageNotification, validateLoginSession } from 'helpers';
import { coursesAnalyticsService } from 'services/insights';
import { coursesAnalyticsDispatches } from 'pages/insights/containers/coursesAnalyticsContainer/constants';

export const coursesAnalyticsActions = {
  fetchAllCoursesGeneralData,
  fetchCoursesMAUsData,
  fetchInsightsCoursesCSV
};

function fetchAllCoursesGeneralData(payload = {}, stopLoader) {
  const { course_id } = payload;
  return (dispatch) => {
    coursesAnalyticsService
      .fetchAllCoursesGeneralData(course_id)
      .then((coursesData) => {
        Promise.resolve(dispatch(coursesAnalyticsDispatches.coursesFetchAllDataSuccess(coursesData))).then(() => {
          if (stopLoader !== undefined) {
            stopLoader();
          }
        });
      })
      .catch((error) => {
        dispatch(coursesAnalyticsDispatches.coursesFetchAllDataFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching courses general data', error.response.data.error);
      });
  };
}

function fetchCoursesMAUsData() {
  return (dispatch) => {
    coursesAnalyticsService
      .fetchCoursesMAUsData()
      .then((coursesMAUs) => {
        Promise.resolve(dispatch(coursesAnalyticsDispatches.coursesFetchMAUsSuccess(coursesMAUs)));
      })
      .catch((error) => {
        dispatch(coursesAnalyticsDispatches.coursesFetchMAUsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching courses maus data', error.response.data.error);
      });
  };
}

function fetchInsightsCoursesCSV() {
  return (dispatch) => {
    coursesAnalyticsService
      .fetchInsightsCoursesCSV()
      .then((response) => {
        messageNotification('success', 'Emailing courses csv report', response.data['message']);
      })
      .catch((error) => {
        messageNotification('danger', 'There was an error fetching courses csv report', error.response.data.error);
        validateLoginSession(dispatch, error);
      });
  };
}
