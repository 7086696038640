import { createSelector } from 'reselect';
import { computePercentageChange, extractDataLabels, extractDataValues } from 'selectors/computation_helpers';

export const getTotalActiveCourses = (state) => state.GENERAL_SITE_METRICS.total_active_courses;
export const getTotalActiveCoursesDataLabels = createSelector([getTotalActiveCourses], extractDataLabels);
export const getTotalActiveCoursesDataValues = createSelector([getTotalActiveCourses], extractDataValues);
export const getTotalActiveCoursesChange = createSelector([getTotalActiveCoursesDataValues], computePercentageChange);

export const getTotalSiteCourses = (state) => state.GENERAL_SITE_METRICS.total_site_courses;
export const getTotalSiteCoursesDataLabels = createSelector([getTotalSiteCourses], extractDataLabels);
export const getTotalSiteCoursesDataValues = createSelector([getTotalSiteCourses], extractDataValues);
export const getTotalSiteCoursesChange = createSelector([getTotalSiteCoursesDataValues], computePercentageChange);

export const getTotalSiteStaffUsers = (state) => state.GENERAL_SITE_METRICS.total_site_staff_users;
export const getTotalSiteStaffUsersDataLabels = createSelector([getTotalSiteStaffUsers], extractDataLabels);
export const getTotalSiteStaffUsersDataValues = createSelector([getTotalSiteStaffUsers], extractDataValues);
export const getTotalSiteStaffUsersChange = createSelector([getTotalSiteStaffUsersDataValues], computePercentageChange);
