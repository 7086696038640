import { themesConstants } from 'pages/appearance/containers/appearanceContainer/constants';

const initialStateThemes = {
  themes_next_page: null,
  themes: []
};

export const fetchThemes = (state = initialStateThemes, action) => {
  switch (action.type) {
    case themesConstants.FETCH_THEMES_SUCCESS:
      return {
        ...state,
        themes_next_page: action.payload.next,
        themes: action.payload.results
      };
    case themesConstants.FETCH_THEMES_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
