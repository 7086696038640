import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import BarChart from 'components/charts/barChart';
import BarChartTrend from 'components/charts/barChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getLearnerMAUsChange, getLearnerMAUsLabels, getLearnerMAUsValues } from 'selectors';

const LearnersMAUs = (props) => {
  const { data_labels, data_values, percent_change, show_chart, trending } = props;

  return (
    <div className="stats-box">
      <div className="d-flex justify-content-between align-items-center">
        <span className="sub-title pt-0">
          Monthly Active Learners
          <span className="tool-tip">
            <FontAwesomeIcon icon={Icons.faQuestionCircle} />
            <span className="tip-content">Number of monthly active learners over time.</span>
          </span>
        </span>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      {show_chart &&
        (trending === 'true' ? (
          <BarChartTrend title="MAUs Count" labels={data_labels} values={data_values} />
        ) : (
          <BarChart title="MAUs Count" labels={data_labels} values={data_values} />
        ))}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getLearnerMAUsLabels(state);
  const data_values = getLearnerMAUsValues(state);
  const percent_change = getLearnerMAUsChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    percent_change,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(LearnersMAUs);
