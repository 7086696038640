import { EDX_API_ENDPOINTS, FIGURES_API_ENDPOINTS, EXPORT_PAGE_SIZE } from 'config';
import { getPanelLmsBaseUrl, fetchDataRecursive } from 'helpers';
import { getInsights } from 'services/insights/methods';

export const learnersAnalyticsService = {
  fetchUsersDetailAnalytics,
  fetchLearnerDetails,
  fetchAllUsersAnalytics,
  fetchLearnersMAUs,
  downloadLearnerDetails,
  fetchLearnersSummaryCSV
};

function fetchUsersDetailAnalytics(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.USERS_DETAIL_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchLearnerDetails(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.USERS_DETAIL_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function downloadLearnerDetails(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.LEARNER_DETAIL_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchAllUsersAnalytics() {
  let url = `${getPanelLmsBaseUrl()}${
    FIGURES_API_ENDPOINTS.USERS_DETAIL_API
  }?learners_only=true&page=1&page_size=${EXPORT_PAGE_SIZE}`;

  return fetchDataRecursive(url);
}

function fetchLearnersMAUs(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.MAUS_API}`,
    requestData: params
  };
  return getInsights(paramsData);
}

function fetchLearnersSummaryCSV(params) {
  let paramsData = {
    url: `${getPanelLmsBaseUrl()}${FIGURES_API_ENDPOINTS.LEARNERS_SUMMARY_CSV}`,
    requestData: params
  };
  return getInsights(paramsData);
}
