import React from 'react';

const PDFFooter = () => {
  return (
    <div className="pdf-content-footer" id="footer_content" style={{ display: 'none' }}>
      <p>© Edly {new Date().getFullYear()}. All rights reserved.</p>
    </div>
  );
};

export default PDFFooter;
