export const dataBackupConstants = {
  FETCH_DATA_BACKUP_SUCCESS: 'FETCH_DATA_BACKUP_SUCCESS',
  FETCH_DATA_BACKUP_FAILURE: 'FETCH_DATA_BACKUP_FAILURE'
};

const fetchDataBackupSuccess = (dataBackup) => {
  return {
    type: dataBackupConstants.FETCH_DATA_BACKUP_SUCCESS,
    payload: dataBackup
  };
};

const fetchDataBackupFailure = (error) => {
  return {
    type: dataBackupConstants.FETCH_DATA_BACKUP_FAILURE,
    error
  };
};

export const dataBackupDispatches = {
  fetchDataBackupSuccess,
  fetchDataBackupFailure
};
