import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const BusinessDetails = (props) => {
  return (
    <Modal onHide={props.onHide} show={props.show} size={props.size}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>Organization Details</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="organizationName">
            Organization name<span className="required">*</span>
          </label>
          <input
            type="text"
            id="organizationName"
            name="business_name"
            className="form-control mb-0"
            onChange={props.handleInputChange}
            value={props.inputs.business_name}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">
            Address<span className="required">*</span>
          </label>
          <input
            type="text"
            id="address"
            name="address"
            className="form-control mb-0"
            onChange={props.handleInputChange}
            value={props.inputs.address}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="apartment">Apartment, suite etc.</label>
          <input
            type="text"
            id="apartment"
            name="apartment"
            className="form-control mb-0"
            onChange={props.handleInputChange}
            value={props.inputs.apartment}
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="city">
                City<span className="required">*</span>
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.city}
                required
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="country">
                Country<span className="required">*</span>
              </label>
              <input
                type="text"
                id="country"
                name="country"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.country}
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="state">
                State<span className="required">*</span>
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.state}
                required
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label htmlFor="zip">
                Zip<span className="required">*</span>
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                className="form-control mb-0"
                onChange={props.handleInputChange}
                value={props.inputs.zip}
                required
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={props.buttonDisabled} onClick={props.handleSubmit}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessDetails;
