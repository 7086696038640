import { messageNotification, validateLoginSession } from 'helpers';
import { coursesAnalyticsService } from 'services/insights';
import { courseDetailDispatches } from 'pages/insights/containers/courseDetailContainer/constants';

const moment = require('moment');

export const courseAnalyticsActions = {
  fetchCourseEnrollmentsData,
  fetchCourseAllLearnersData,
  fetchCourseDetail,
  fetchCourseDetailCSV
};

function fetchCourseEnrollmentsData(payload, stopLoader) {
  const { course_id } = payload;
  return (dispatch) => {
    coursesAnalyticsService
      .fetchCourseEnrollmentsData(payload)
      .then((coursesData) => {
        Promise.resolve(dispatch(courseDetailDispatches.courseEnrollmentsSuccess(coursesData, course_id))).then(() => {
          if (stopLoader !== undefined) {
            stopLoader();
          }
        });
      })
      .catch((error) => {
        dispatch(courseDetailDispatches.courseEnrollmentsFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching course enrollments data', error.response.data.error);
      });
  };
}

function fetchCourseAllLearnersData(payload, stopLoader) {
  return (dispatch) => {
    coursesAnalyticsService
      .fetchCourseAllLearnersData(payload)
      .then((coursesData) => {
        Promise.resolve(dispatch(courseDetailDispatches.courseAllLearnersSuccess(coursesData))).then(() => {
          if (stopLoader !== undefined) {
            stopLoader();
          }
        });
      })
      .catch((error) => {
        dispatch(courseDetailDispatches.courseAllLearnersFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error fetching course learners data', error.response.data.error);
      });
  };
}

function fetchCourseDetail(payload) {
  const params = getCourseDetailsPayload(payload);
  return (dispatch) => {
    coursesAnalyticsService
      .fetchCourseDetail(params)
      .then((courseData) => {
        Promise.resolve(dispatch(courseDetailDispatches.courseDetailSuccess(courseData)));
      })
      .catch((error) => {
        dispatch(courseDetailDispatches.courseDetailFailure(error));
        validateLoginSession(dispatch, error);
        messageNotification(
          'danger',
          'There was an error fetching course detailed information',
          error.response.data.error
        );
      });
  };
}

function fetchCourseDetailCSV(payload) {
  payload['no_page'] = true;
  return (dispatch) => {
    coursesAnalyticsService
      .fetchCourseDetailCSV(payload)
      .then(() => {
        messageNotification('success', 'Course Detail Report', 'Course detail report will be emailed soon.');
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        messageNotification('danger', 'There was an error emailing course detail report', error.response.data.error);
      });
  };
}

const getCourseDetailsPayload = (payload) => {
  const { filter_type, filter_start_date, filter_end_date, course_id } = payload;
  let params = {
    course_id: course_id
  };
  switch (filter_type) {
    case 'last_year':
      params['start_date'] = moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'year')
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_year':
      params['start_date'] = moment()
        .startOf('year')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('year')
        .format('D-MM-YYYY');
      break;
    case 'this_month':
      params['start_date'] = moment()
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'last_month':
      params['start_date'] = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('D-MM-YYYY');
      params['end_date'] = moment()
        .subtract(1, 'month')
        .endOf('month')
        .format('D-MM-YYYY');
      break;
    case 'custom':
      params['start_date'] = moment(filter_start_date).format('D-MM-YYYY');
      params['end_date'] = moment(filter_end_date).format('D-MM-YYYY');
      break;
    default:
      break;
  }
  return params;
};
