import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { STRIPE_CUSTOMER_PORTAL } from 'config';
import NavItem from 'components/sidebar/navItem';

const settingsDropDown = (props) => {
  const {
    location,
    settingsOpen,
    setSidebarOpen,
    setSettingsOpen,
    setInsightsOpen,
    insightsOpen,
    panelRole,
    userEmail
  } = props;
  const stripeCustomerPortalLink = `${STRIPE_CUSTOMER_PORTAL}?prefilled_email=${userEmail}`;

  const checkLocation = location.pathname === '/panel/dashboard' || location.pathname === '/panel/settings/general';

  return (
    <li className={'settings-list'}>
      <Accordion>
        <Card>
          <Card.Header>
            <button
              className={
                location.pathname.includes('settings') && settingsOpen
                  ? 'active btn btn-link btn-settings'
                  : !location.pathname.includes('settings') && settingsOpen
                  ? 'btn btn-link focused'
                  : 'btn btn-link'
              }
              onClick={() => setSettingsOpen(!settingsOpen)}
            >
              <FontAwesomeIcon icon={Icons.faCog} />
              Settings <FontAwesomeIcon icon={Icons.faCaretDown} />
            </button>
          </Card.Header>
          <Accordion.Collapse className={settingsOpen ? 'show' : ''} eventKey="0">
            <Card.Body>
              <ul>
                {checkLocation ? (
                  <NavItem
                    link="/panel/settings/general"
                    label="General"
                    settingsOpen={true}
                    setSidebarOpen={setSidebarOpen}
                    setSettingsOpen={setSettingsOpen}
                    insightsOpen={insightsOpen}
                    setInsightsOpen={setInsightsOpen}
                  />
                ) : (
                  <NavItem
                    link="/panel/settings/profile"
                    label="General"
                    settingsOpen={true}
                    setSidebarOpen={setSidebarOpen}
                    setSettingsOpen={setSettingsOpen}
                    insightsOpen={insightsOpen}
                    setInsightsOpen={setInsightsOpen}
                  />
                )}
                {(panelRole === 'panel_admin' || panelRole === 'super_admin') &&
                  location.pathname !== '/panel/dashboard' &&
                  location.pathname !== '/panel/settings/general' && (
                    <React.Fragment>
                      <NavItem
                        link="/panel/settings/appearance"
                        label="Appearance"
                        settingsOpen={true}
                        setSidebarOpen={setSidebarOpen}
                        setSettingsOpen={setSettingsOpen}
                        insightsOpen={insightsOpen}
                        setInsightsOpen={setInsightsOpen}
                      />
                      <NavItem
                        link="/panel/settings/signin"
                        label="Sign in/up settings"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                      <NavItem
                        link="/panel/settings/analytics"
                        label="Analytics"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                      <NavItem
                        link="/panel/settings/integrations"
                        label="Integrations"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                      <NavItem
                        link="/panel/settings/sitedetails"
                        label="Site Details"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                      <NavItem
                        link="/panel/settings/billing"
                        label="Billing and Plans"
                        className={location.pathname.includes('billing') && settingsOpen ? 'active' : ''}
                        settingsOpen={true}
                        setSidebarOpen={setSidebarOpen}
                        setSettingsOpen={setSettingsOpen}
                        insightsOpen={insightsOpen}
                        setInsightsOpen={setInsightsOpen}
                      />
                      <a href={stripeCustomerPortalLink} target="_blank" rel="noreferrer">
                        Customer Portal
                        <FontAwesomeIcon icon={Icons.faExternalLinkAlt} />
                      </a>
                      <NavItem
                        link="/panel/settings/mobileconfig"
                        label="Edly Go"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                      <NavItem
                        link="/panel/settings/data_backup"
                        label="Data Backup"
                        insightsOpen={insightsOpen}
                        setSidebarOpen={setSidebarOpen}
                        setInsightsOpen={setInsightsOpen}
                        settingsOpen={true}
                        setSettingsOpen={setSettingsOpen}
                      />
                    </React.Fragment>
                  )}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </li>
  );
};

export default settingsDropDown;
