import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row, Button } from 'react-bootstrap';

import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';
import { getUserSettings } from 'selectors';
import { messageNotification, yearsList } from 'helpers';
import 'pages/settings/index.scss';

const current_year = new Date().getFullYear();

const AccountSettings = (props) => {
  const { name, username, email, profile_image, old_email, year_of_birth, password_reset } = props;
  const [inputs, setInputs] = useState({
    name: '',
    username: '',
    email: '',
    profile_image: '',
    old_email: '',
    year_of_birth: ''
  });
  const [profileImageFile, setProfileImageFile] = useState('');
  const [image_disabled, setImageDisabled] = useState(false);

  useEffect(() => {
    setInputs({
      name: name,
      username: username,
      email: email,
      profile_image: profile_image,
      old_email: old_email,
      year_of_birth: year_of_birth
    });
    if (year_of_birth === null || current_year - year_of_birth <= 13) {
      setImageDisabled(true);
    } else {
      setImageDisabled(false);
    }
  }, [name, username, email, profile_image, old_email, year_of_birth]);

  const updateAccountSettings = (event) => {
    event.preventDefault();
    if (profileImageFile) {
      props.dispatch(settingsActions.updateUserProfileImage(inputs, profileImageFile));
    }
    props.dispatch(settingsActions.updateSettings(inputs));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
  };

  const imageChange = (event) => {
    let profile_image = event.target.files[0];
    if (profile_image.size < 1024 * 1024) {
      profile_image = URL.createObjectURL(profile_image);
      setInputs((inputs) => ({ ...inputs, profile_image: profile_image }));
      setProfileImageFile(event.target.files[0]);
    } else {
      messageNotification(
        'danger',
        'Profile Image Update Failed',
        "Please select a file that isn't exceeding 1MB limit"
      );
    }
  };

  const handlePasswordReset = (event) => {
    if (inputs.email) {
      props.dispatch(settingsActions.resetUserPassword(inputs));
    } else {
      messageNotification('danger', 'Accounts Settings Update Failed', 'Email Address is required.');
    }
  };

  const years = yearsList(current_year - 119, current_year).map((year, index) => {
    return (
      <option key={index} value={year}>
        {year}
      </option>
    );
  });

  return (
    <Fragment>
      <form name="accounts_settings" onSubmit={updateAccountSettings} className="profile-form">
        <Form.Group controlId="name" as={Row}>
          <Form.Label column sm="12" lg="3" htmlFor="name">
            Name
          </Form.Label>
          <Col sm="12" lg="9">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              value={inputs.name}
              onChange={handleInputChange}
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="year_of_birth" as={Row}>
          <Form.Label column sm="12" lg="3" htmlFor="year_of_birth">
            Year of Birth
          </Form.Label>
          <Col sm="12" lg="9">
            <select
              id="year_of_birth"
              name="year_of_birth"
              className="form-control"
              value={inputs.year_of_birth}
              onChange={handleInputChange}
            >
              <option value="">--</option>
              {years}
            </select>
          </Col>
        </Form.Group>
        <Form.Group controlId="profile_image" as={Row}>
          <Form.Label column sm="12" lg="3">
            Profile Image
          </Form.Label>
          <Col sm="12" lg="9" className="profile-image-holder">
            <div className="profile-image-frame">
              <img src={inputs.profile_image} alt="Profile" className="profile-image" />
            </div>
            <div className="custom-file dragable">
              <input
                type="file"
                id="profile_image"
                name="profile_image"
                onChange={imageChange}
                disabled={image_disabled}
                className="form-control"
              />
              <label htmlFor="profile_image" className="custom-file-label">
                Drop file here or click to upload
              </label>
            </div>
            <span className="input-info">You must be over 13 years to set your profile image</span>
            <span className="input-info">You can attach gif, jpg, png, with a maximum size of 1MB</span>
          </Col>
        </Form.Group>
        <Form.Group controlId="username" as={Row}>
          <Form.Label column sm="12" lg="3" htmlFor="username">
            Username <span className="required">*</span>
          </Form.Label>
          <Col sm="12" lg="9">
            <input
              type="text"
              name="username"
              id="username"
              readOnly
              className="form-control"
              value={inputs.username}
              onChange={handleInputChange}
              required
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="email" as={Row}>
          <Form.Label column sm="12" lg="3" htmlFor="email">
            Email <span className="required">*</span>
          </Form.Label>
          <Col sm="12" lg="9">
            <input
              className="form-control"
              type="email"
              name="email"
              id="email"
              value={inputs.email}
              onChange={handleInputChange}
              required
              readOnly
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="password-reset" as={Row}>
          <Form.Label column sm="12" lg="3">
            Password
          </Form.Label>
          <Col sm="12" lg="9">
            <input
              type="button"
              name="password-reset"
              value="Reset Your Password"
              onClick={handlePasswordReset}
              className="btn btn-primary"
              disabled={!password_reset}
            />
            <p>
              <small>(Check your email account for instructions to reset your password)</small>
            </p>
          </Col>
        </Form.Group>
        <Col sm="12" align="end">
          <Button type="submit" className="btn btn-primary">
            Update
          </Button>
        </Col>
      </form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  let user_settings = getUserSettings(state);
  if (user_settings.length < 1) {
    return {};
  }
  const { name, username, email, profile_image, old_email, year_of_birth, password_reset } = user_settings;

  return {
    name,
    username,
    email,
    profile_image,
    old_email,
    year_of_birth,
    password_reset
  };
}

const accountSettings = connect(mapStateToProps)(AccountSettings);
export { accountSettings as AccountSettings };
