import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Slider } from 'rsuite';
import 'rsuite/Slider/styles/index.css';

import { setUserSliderCount } from '../actions/pricing';
import { Row } from 'react-bootstrap';

const CustomTooltip = ({ value }) => {
  return <div>{value}&nbsp;Learners</div>;
};

const PriceSlider = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  const scaleValue = (value) => {
    if (value <= 15) {
      return Math.round(value * (20 / 15));
    } else if (value <= 30) {
      return Math.round((value - 15) * (80 / 15) + 20);
    } else if (value <= 60) {
      return Math.round((value - 30) * (1400 / 30) + 100);
    } else {
      return Math.round((value - 60) * (10500 / 40) + 1500);
    }
  };

  const inverseScaleValue = (scaledValue) => {
    if (scaledValue <= 20) {
      return Math.round(scaledValue * (15 / 20));
    } else if (scaledValue <= 100) {
      return Math.round((scaledValue - 20) * (15 / 80) + 15);
    } else if (scaledValue <= 1500) {
      return Math.round((scaledValue - 100) * (30 / 1400) + 30);
    } else {
      return Math.round((scaledValue - 1500) * (40 / 10500) + 60);
    }
  };

  const handleChange = (value) => {
    const scaledValue = scaleValue(value);
    setValue(scaledValue);
    dispatch(setUserSliderCount(scaledValue));
  };

  return (
    <div
      style={{
        '--rs-tooltip-bg': 'var(--rs-slider-progress)',
        '--rs-tooltip-text': 'white',
        '--rs-slider-progress': 'rgb(2, 88, 237)',
        '--rs-slider-thumb-border': 'rgb(2, 88, 237)'
      }}
    >
      <Slider
        progress
        style={{
          marginTop: 26,
          marginBottom: 0,
          width: '70%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
        step={1}
        value={inverseScaleValue(value)}
        onChange={handleChange}
        renderTooltip={(value) => <CustomTooltip value={scaleValue(value)} />}
        min={0}
        max={100}
      />
      <div className="bar-divider">
        <span>20</span>
        <span>10,000</span>
      </div>
    </div>
  );
};

export default PriceSlider;
