import React, { useState } from 'react';
import IconDelete from 'assets/images/ico-del.svg';
import { messageNotification } from 'helpers';

export const FileUploader = (props) => {
  const [file, setFile] = useState(null);
  const [inputKey, setInputKey] = useState(0);

  function handleUpload(event) {
    const uploadedFile = event.target.files[0];

    if (uploadedFile && uploadedFile.type === 'image/png') {
      const fileUrl = URL.createObjectURL(event.target.files[0]);
      setFile(fileUrl);
      props.onUpdate(event.target.files[0]);
    } else {
      messageNotification('danger', 'Error', 'Please upload a PNG file.');
    }
  }

  function handleDelete() {
    setFile(null);
    setInputKey(inputKey + 1);
    props.onUpdate(null);
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-5 upload-assets">
          <div
            className="upload-box"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '180px',
              height: '140px',
              border: '1px solid #d7d7d7',
              borderRadius: '4px'
            }}
          >
            {file ? <img src={file} alt="File Preview" /> : 'File Preview'}
          </div>
          {file && (
            <div className="file-delete-hold">
              <button onClick={handleDelete}>
                <img src={IconDelete} alt="Icon Delete" />
              </button>
            </div>
          )}
        </div>
        <div className="col-12 col-md-6 col-xl-7 upload-assets">
          <div className="upload-helptext">
            <h4>{props.uploadFilename}</h4>
            <p>{props.helpText}</p>
          </div>
          <div className="custom-file-field">
            <input
              key={inputKey}
              id={`fileInput-${props.uploadFilename}`}
              type="file"
              value={null}
              onChange={handleUpload}
            />
            <label htmlFor={`fileInput-${props.uploadFilename}`} style={{ marginRight: '10px' }}>
              Browse
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
