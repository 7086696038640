import React, { useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import { getPreparedGraphDataset } from 'helpers/dashboard';

const MAUsDashboard = (props) => {
  const { maus_data_labels, maus_data } = props;
  const [legendDisplay, setlegendDisplay] = useState(true);

  useEffect(() => {
    setlegendDisplay(false);
    setTimeout(() => {
      setlegendDisplay(true);
    }, 0);
  }, [maus_data_labels, maus_data]);

  return (
    <div className="chart-frame">
      <Bar
        data={{
          labels: maus_data_labels,
          datasets: getPreparedGraphDataset(maus_data)
        }}
        width={600}
        height={315}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: legendDisplay,
            position: 'bottom',
            labels: {
              boxWidth: 15,
              padding: 10
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  beginAtZero: true,
                  fontFamily: '"Lato", sans-serif',
                  fontSize: 11,
                  fontStyle: 700
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  userCallback: function(label, index, labels) {
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                  fontFamily: '"Lato", sans-serif',
                  fontSize: 11,
                  fontStyle: 700
                }
              }
            ]
          }
        }}
      />
    </div>
  );
};

export default MAUsDashboard;
