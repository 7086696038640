import React from 'react';
import Slider from 'react-slick';
import BenefitsSlideContent from './benefitsSlideContent';
import { benefitsContent } from '../constants/benefitsContent';

function BenefitsSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  const benefits = benefitsContent.map((item) => (
    <BenefitsSlideContent title={item.title} description={item.description} />
  ));
  return (
    <>
      <Slider {...settings}>{benefits}</Slider>
    </>
  );
}

export default BenefitsSlider;
