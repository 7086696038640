import React, { useState } from 'react';
import './marketingKeyInput.css';

import { WP_GA_ID_PLACEHOLDER, WP_GTM_ID_PLACEHOLDER } from '../constants';
import { updateWpSiteConfig } from 'services/siteConfiguration';
import { messageNotification } from 'helpers';

const MarketingKeyInput = () => {
  const [wpGa, setWpGa] = useState('');
  const [wpGtm, setWpGtm] = useState('');

  const updateKey = () => {
    if (wpGa === '' && wpGtm === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill atleast one field for Marketing keys');
      return;
    }

    const wp_data = {
      ...(wpGa && { site_ga_id: wpGa }),
      ...(wpGtm && { site_gtm_id: wpGtm })
    };
    updateWpSiteConfig(wp_data)
      .then((res) =>
        messageNotification(
          'success',
          'Key updated',
          `The following keys have been updated:
        ${wpGtm && 'GTM_ID: ' + wpGtm}
        ${wpGa && 'GA_ID: ' + wpGa}`
        )
      )
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };

  return (
    <div className="integration-holder">
      <h3>Google analytics for marketing site</h3>
      <p>Add Google Analytics for Property (GA4) and Google Tag Manger (GTM) keys for marketing site</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="form-label">GTM ID</label>
          <input
            className="form-control"
            placeholder={WP_GTM_ID_PLACEHOLDER}
            value={wpGtm}
            onChange={(event) => setWpGtm(event.target.value)}
          ></input>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">GA4 ID</label>
          <input
            className="form-control"
            placeholder={WP_GA_ID_PLACEHOLDER}
            value={wpGa}
            onChange={(event) => setWpGa(event.target.value)}
          ></input>
        </div>
        <div className="col-12 btn-holder justify-content-end d-flex">
          <button className="btn btn-primary" onClick={updateKey}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketingKeyInput;
