import React from 'react';

import LearnerCourseListItem from 'pages/insights/containers/learnerDetailContainer/components/learnerCourseListItem';

const LearnerCoursesListing = (props) => {
  const { courses_data } = props;
  return courses_data.map((course, index) => {
    return <LearnerCourseListItem key={index} course={course} />;
  });
};

export default LearnerCoursesListing;
