import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCurrentMonthMAUs, getUserPanelRole, getOrganization } from 'selectors';
import { mausActions } from 'pages/dashboard/containers/monthlyActiveUsers/actions/maus';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import 'pages/pricing/index.scss';
import FlexiblePricing from './components/flexiblePricing';
import { dateFormat, nanoSecondsToSecond } from 'pages/pricing/containers/pricingContainer/constants';

const moment = require('moment');

const PricingCenter = (props) => {
  const { fetchMAUS, fetchPaymentMethod, fetchPricing, pricing, organization } = props;
  useEffect(() => {
    fetchPricing();
    const expiryDate =
      organization.current_site_plan === 'trial'
        ? organization.renewal_expiry
        : organization.renewal_expiry * nanoSecondsToSecond;

    const endDate = moment(expiryDate).format(dateFormat);
    fetchMAUS({
      maus_filter_type: 'custom',
      roles: 'learner',
      maus_start_date: moment(endDate)
        .subtract(1, 'M')
        .format(dateFormat),
      maus_end_date: endDate
    });
    fetchPaymentMethod();
  }, [fetchMAUS, fetchPricing, fetchPaymentMethod, organization]);

  return (
    pricing && (
      <>
        <FlexiblePricing />
      </>
    )
  );
};

function mapStateToProps(state) {
  const { pricing } = state.PRICING;
  const current_month_maus = getCurrentMonthMAUs(state);
  const panel_role = getUserPanelRole(state);
  const organization = getOrganization(state);
  return { pricing, organization, current_month_maus, panel_role };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...pricingActions, ...mausActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingCenter);
