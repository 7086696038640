export const pricingConstants = {
  FETCH_PRICING_SUCCESS: 'FETCH_PRICING_SUCCESS',
  FETCH_PRICING_FAILURE: 'FETCH_PRICING_FAILURE',
  FETCH_PAYMENT_METHOD_SUCCESS: 'FETCH_PAYMENT_METHOD_SUCCESS',
  FETCH_PAYMENT_METHOD_FAILURE: 'FETCH_PAYMENT_METHOD_FAILURE'
};

export const termsConditionsConstants = {
  UPDATE_TERMS_CONDITIONS_SUCCESS: 'UPDATE_TERMS_CONDITIONS_SUCCESS',
  UPDATE_TERMS_CONDITIONS_FAILURE: 'UPDATE_TERMS_CONDITIONS_FAILURE'
};

export const dateFormat = 'MMMM DD, YYYY';
export const nanoSecondsToSecond = 1000;

export const userSliderConstants = {
  SET_COUNT: 'SET_COUNT'
};

const fetchPricingSuccess = (pricing) => {
  return { type: pricingConstants.FETCH_PRICING_SUCCESS, payload: pricing.data };
};

const fetchPricingSFailure = (error) => {
  return { type: pricingConstants.FETCH_PRICING_FAILURE, payload: error };
};

const fetchPaymentMethodSuccess = (paymentMethod) => {
  return { type: pricingConstants.FETCH_PAYMENT_METHOD_SUCCESS, payload: paymentMethod };
};

const fetchPaymentMethodFailure = (error) => {
  return { type: pricingConstants.FETCH_PAYMENT_METHOD_FAILURE, payload: error };
};

const updateTermsConditionsSuccess = (response) => {
  return { type: termsConditionsConstants.UPDATE_TERMS_CONDITIONS_SUCCESS, payload: response };
};

const updateTermsConditionsFailure = (error) => {
  return { type: termsConditionsConstants.UPDATE_TERMS_CONDITIONS_FAILURE, payload: error };
};

export const termsConditionsDispatches = {
  updateTermsConditionsSuccess,
  updateTermsConditionsFailure
};

export const pricingDispatches = {
  fetchPricingSuccess,
  fetchPricingSFailure,
  fetchPaymentMethodSuccess,
  fetchPaymentMethodFailure
};
