export const graphColors = [
  '#e27c7c',
  '#a86464',
  '#6d4b4b',
  '#503f3f',
  '#333333',
  '#3c4e4b',
  '#466964',
  '#599e94',
  '#6cd4c5'
];
