import React from 'react';

const RoleCheckBox = (props) => {
  const { id, label, isChecked, handleChange } = props;
  return (
    <li>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          name={id}
          value={id}
          checked={isChecked === true}
          onChange={handleChange}
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </li>
  );
};

export default RoleCheckBox;
