export const ltiConstants = {
  FETCH_LTI_CONFIGURATIONS_REQUEST: 'FETCH_LTI_CONFIGURATIONS_REQUEST',
  FETCH_LTI_CONFIGURATIONS_SUCCESS: 'FETCH_LTI_CONFIGURATIONS_SUCCESS',
  FETCH_LTI_CONFIGURATIONS_FAILURE: 'FETCH_LTI_CONFIGURATIONS_FAILURE'
};

const fetchLtiConfigurationsRequest = () => {
  return {
    type: ltiConstants.FETCH_LTI_CONFIGURATIONS_REQUEST
  };
};

const fetchLtiConfigurationsSuccess = (configurations) => {
  return {
    type: ltiConstants.FETCH_LTI_CONFIGURATIONS_SUCCESS,
    payload: configurations
  };
};

const fetchLtiConfigurationsFailure = (error) => {
  return {
    type: ltiConstants.FETCH_LTI_CONFIGURATIONS_FAILURE,
    error
  };
};

export const ltiDispatches = {
  fetchLtiConfigurationsRequest,
  fetchLtiConfigurationsSuccess,
  fetchLtiConfigurationsFailure
};

const commonVariables = {
  version: 'version',
  courseKey: 'course_key',
  editMode: 'edit_mode'
};

export const lti1p1Variables = {
  ...commonVariables,
  ltiUrl: 'lti_1p1_launch_url',
  clientKey: 'lti_1p1_client_key',
  clientSecret: 'lti_1p1_client_secret'
};
export const lti1p3Variables = {
  ...commonVariables,
  toolLaunchUrl: 'lti_1p3_launch_url',
  loginUrl: 'lti_1p3_oidc_url',
  publicKey: 'lti_1p3_tool_public_key',
  keysetUrl: 'lti_1p3_tool_keyset_url',
  enableDeepLinking: 'lti_advantage_deep_linking_enabled',
  deepLinkingLaunchUrl: 'lti_advantage_deep_linking_launch_url'
};

export const inputFieldsLTI1p1 = [
  {
    name: 'courseKey',
    label: 'Course ID',
    type: 'text',
    placeholder: '',
    helptext: 'Leaving this field blank will set this configuration organization wide.'
  },
  {
    name: 'ltiUrl',
    label: 'LTI URL',
    type: 'text',
    placeholder: 'https://example/launch',

    helptext: 'The URL of the external tool that initiates the launch.'
  },
  {
    name: 'clientKey',
    label: 'LTI Client ID',
    type: 'text',
    placeholder: '',
    helptext: 'Client key provided by the LTI tool provider.'
  },
  {
    name: 'clientSecret',
    label: 'LTI Client Secret',
    placeholder: '',
    helptext: 'Client secret provided by the LTI tool provider.'
  }
];

export const inputFieldsLTI1p3 = [
  {
    name: 'courseKey',
    label: 'Course ID',
    type: 'text',
    placeholder: '',
    helptext: 'Leaving this field blank will set this configuration organization wide.'
  },
  {
    name: 'toolLaunchUrl',
    label: 'Tool Launch URL',
    type: 'text',
    placeholder: 'https://example/launch',
    helptext: 'The LTI launch URL, otherwise known as the target_link_uri.'
  },
  {
    name: 'loginUrl',
    label: 'Tool Initiate Login URL',
    type: 'text',
    placeholder: 'https://example/login',
    helptext: 'This is the OIDC third-party initiated login endpoint URL.'
  },
  {
    name: 'publicKey',
    label: 'Tool Public Key',
    type: 'textarea',
    placeholder: '',
    helptext: 'Please enter either a public key or a keyset URL.'
  },
  {
    name: 'keysetUrl',
    label: 'Tool Keyset URL',
    type: 'text',
    placeholder: 'https://example.json',
    helptext: 'Please enter either a public key or a keyset URL.'
  },
  {
    name: 'deepLinkingLaunchUrl',
    label: 'Deep Linking Launch Url ',
    type: 'text',
    placeholder: 'https://example/launch',
    helptext: 'Adding Deeplink URL will enable deeplinking functionality.'
  }
];
