import { pricingConstants, userSliderConstants } from 'pages/pricing/containers/pricingContainer/constants';

const initialStatePricing = {
  pricing_next_page: null,
  pricing: []
};

const initialStatePaymentMethod = {
  customerId: '',
  paymentCardDigits: '',
  paymentCardType: ''
};

const userSliderCount = {
  count: 0
};

export const fetchPricing = (state = initialStatePricing, action) => {
  switch (action.type) {
    case pricingConstants.FETCH_PRICING_SUCCESS:
      return {
        ...state,
        pricing_next_page: action.payload.next,
        pricing: action.payload.results
      };
    case pricingConstants.FETCH_PRICING_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const fetchPaymentMethod = (state = initialStatePaymentMethod, action) => {
  switch (action.type) {
    case pricingConstants.FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        customerId: action.payload.data.id,
        paymentCardDigits: action.payload.data.last_four_card_digits,
        paymentCardType: action.payload.data.card_type
      };
    case pricingConstants.FETCH_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const userSliderReducer = (state = userSliderCount, action) => {
  switch (action.type) {
    case userSliderConstants.SET_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};
