import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import LtiConfigurationForm from './ltiConfigurationForm';
import Confirmation from './confirmation';

const LtiConfigurationsList = ({ ltiConfigurations }) => {
  const [editMode, setEditMode] = useState(false);
  const [config, setConfig] = useState({});
  const [deleteParams, setDeleteParams] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const ltiVersion = (version) => version.replace('lti_', ' ').replace('p', '.');

  const hideConfirmation = () => setDeleteConfirmation(false);
  const editConfiguration = (configuration) => {
    setEditMode(true);
    setConfig(configuration);
  };
  const deleteConfiguration = (configuration) => {
    const params = {};
    if (configuration['course_key']) {
      params['course_key'] = configuration['course_key'];
    }
    params['version'] = configuration.version;
    setDeleteParams(params);
    setDeleteConfirmation(true);
  };
  return (
    <>
      <table border="1" className="table dns-table">
        <thead>
          <tr>
            <th>Course ID</th>
            <th>LTI Version</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ltiConfigurations.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td data-title="Course ID">
                {row.course_key ? row.course_key : 'Enabled organization-wide for ' + row.organization_slug}
              </td>
              <td data-title="LTI Version">{ltiVersion(row.version)}</td>
              <td data-title="Actions" onClick={() => {}}>
                <FontAwesomeIcon icon={Icons.faPen} onClick={() => editConfiguration(row)} />
                <FontAwesomeIcon className="ml-2" icon={Icons.faTrashAlt} onClick={() => deleteConfiguration(row)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LtiConfigurationForm
        isFormOpen={editMode}
        editMode={editMode}
        configuration={config}
        hideModal={() => setEditMode(false)}
      />
      <Confirmation isOpen={deleteConfirmation} deleteParams={deleteParams} hideModal={hideConfirmation} />
    </>
  );
};

export default LtiConfigurationsList;
