import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSiteNotifications } from 'selectors';
import BillingNotificationsListing from 'pages/notifications/containers/notifications/components/billing/billingNotificationsListing';
import { notificationActions } from 'pages/notifications/containers/notifications/actions';

const BillingNotifications = (props) => {
  const { billing_notifications, updateNotificationStatus } = props;

  return (
    <div>
      <BillingNotificationsListing
        billing_notifications={billing_notifications}
        updateNotificationStatus={updateNotificationStatus}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { billing_notifications } = getSiteNotifications(state);
  return {
    billing_notifications
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingNotifications);
