import { EDX_API_ENDPOINTS, WORDPRESS_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl, getWordpressBaseUrl, getStudioBaseUrl } from 'helpers';

const axios = require('axios');

const fetchUsers = (payload = {}) => {
  let { page_number, searchText, roles, order_by, page_size } = payload;

  if (searchText === undefined) {
    searchText = '';
  }
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_USERS_INFO}?search=${searchText}`;
  if (roles !== undefined) {
    let selectedRoles = {};
    selectedRoles = Object.keys(roles).filter(function(key) {
      return roles[key] === true;
    });
    url = `${url}&roles=${selectedRoles.join(',')}`;
  }
  if (order_by !== undefined) {
    url = `${url}&order_by=${order_by}`;
  }
  if (page_number !== undefined) {
    url = `${url}&page=${page_number}`;
  }
  if (page_size !== undefined) {
    url = `${url}&page_size=${page_size}`;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const addNewUser = (user_data) => {
  const { username, name, email, is_active, lms_role, wordpress_role, panel_role } = user_data;
  let url = `${getStudioBaseUrl()}${EDX_API_ENDPOINTS.FETCH_USERS_INFO}`;

  let is_staff = false,
    is_superuser = false;
  let is_course_creator = false;
  if (lms_role === 'staff') {
    is_staff = true;
  }
  if (lms_role === 'course_creator') {
    is_course_creator = true;
  }

  let profile = { name: name };

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('studio_csrf_token')
    },
    data: {
      username,
      profile,
      email,
      is_active,
      is_staff,
      is_course_creator,
      is_superuser,
      wordpress_role,
      panel_role
    }
  };
  return axios(requestOptions);
};

const addWordpressUser = (user_data) => {
  const { username, email, wordpress_role, is_active } = user_data;

  let url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.CREATE_USER_ACCOUNT}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    data: {
      username,
      email,
      is_active,
      role: wordpress_role,
      link_wp_user: 'true'
    }
  };
  return axios(requestOptions);
};

const updateUser = (user_data) => {
  const { id, name, email, is_active, is_blocked, lms_role, wordpress_role, panel_role } = user_data;
  let url = `${getStudioBaseUrl()}${EDX_API_ENDPOINTS.FETCH_USERS_INFO}${id}/`;

  let is_staff = false,
    is_superuser = false;
  let is_course_creator = false;
  if (lms_role === 'staff') {
    is_staff = true;
  }
  if (lms_role === 'course_creator') {
    is_course_creator = true;
  }

  let profile = { name: name };

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('studio_csrf_token')
    },
    data: {
      id,
      profile,
      email,
      is_active,
      is_blocked,
      is_staff,
      is_course_creator,
      is_superuser,
      wordpress_role,
      panel_role
    }
  };
  return axios(requestOptions);
};

const updateWordpressUser = (user_data) => {
  const { username, wordpress_role, is_active, email } = user_data;
  let url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username,
      is_active,
      email,
      role: wordpress_role
    }
  };
  return axios(requestOptions);
};

const resetUserPassword = (email) => {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.RESET_USER_PASSWORD}`;

  let bodyFormData = new FormData();
  bodyFormData.set('email', email);

  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    headers: {
      Accept: '*/*',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: bodyFormData,
    url
  };
  return axios(requestOptions);
};

const updateUserStatus = (username, status = null, block_status = null) => {
  let url = `${getStudioBaseUrl()}${EDX_API_ENDPOINTS.UPDATE_USERS_ACCOUNT_STATUS}`;
  let data = { username };
  if (status !== null) {
    data['status'] = status;
  }
  if (block_status !== null) {
    data['block_status'] = block_status;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('studio_csrf_token')
    },
    data: data
  };
  return axios(requestOptions);
};

const updateWordpressUserStatus = (username, status, wordpress_role) => {
  let url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username,
      is_active: status,
      role: wordpress_role
    }
  };
  return axios(requestOptions);
};

const blockUsers = (selectedUsers) => {
  let url = `${getStudioBaseUrl()}${EDX_API_ENDPOINTS.UPDATE_USERS_ACCOUNT_STATUS}`;
  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('studio_csrf_token')
    },
    data: {
      username: selectedUsers.join(','),
      block_status: true
    }
  };
  return axios(requestOptions);
};

const deactivateWPUsers = (selectedUsers) => {
  let url = `${getWordpressBaseUrl()}${WORDPRESS_API_ENDPOINTS.UPDATE_USER_ROLE}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'PATCH',
    url,
    data: {
      username: selectedUsers.join(','),
      is_active: false,
      role: 'subscriber'
    }
  };
  return axios(requestOptions);
};

const exportUsersInfo = (payload) => {
  let { searchText, roles, users } = payload;
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.FETCH_USERS_ACCOUNTS_DATA}`;
  url = `${url}?search=${searchText}&export=true&username=${users.join(',')}`;
  if (roles !== undefined) {
    let selectedRoles = {};
    selectedRoles = Object.keys(roles).filter(function(key) {
      return roles[key] === true;
    });
    url = `${url}&roles=${selectedRoles.join(',')}`;
  }

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
};

const validateUser = (user_data) => {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.VALIDATE_USER_INFO}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    },
    data: user_data
  };
  return axios(requestOptions);
};

const uploadBulkUsers = (form_data, onUploadProgress) => {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.BULK_USERS_UPLOAD}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    url,
    headers: {
      Accept: '*/*',
      'X-CSRFToken': localStorage.getItem('lms_csrf_token'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    },
    data: form_data,
    onUploadProgress
  };
  return axios(requestOptions);
};

const getRegistrationTemplate = () => {
  let url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.BULK_USERS_UPLOAD}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url,
    responseType: 'blob',
    headers: {
      'X-CSRFToken': localStorage.getItem('lms_csrf_token')
    }
  };
  return axios(requestOptions);
};

export const usersService = {
  fetchUsers,
  validateUser,
  addNewUser,
  addWordpressUser,
  updateUser,
  updateWordpressUser,
  updateUserStatus,
  updateWordpressUserStatus,
  resetUserPassword,
  blockUsers,
  deactivateWPUsers,
  exportUsersInfo,
  uploadBulkUsers,
  getRegistrationTemplate
};
