import React from 'react';

import 'components/loaders/index.scss';

const FullPageLoader = () => {
  return (
    <div className="preload loading">
      <ul className="loading-dots">
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
};

export default FullPageLoader;
