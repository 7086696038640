import React, { useState } from 'react';

import { BusinessDetails } from 'pages/pricing/containers/pricingContainer/components/businessDetails';
import TermsConditionsDetail from './termsConditionsDetail';

export const TermsAndConditions = (props) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [termsModalShow, setTermsModalShow] = useState(false);
  const inputs = props.inputs;
  const setInputs = props.setInputs;

  const handleInputChange = (event) => {
    event.persist();
    const updateFields = { ...inputs, [event.target.name]: event.target.value };
    setInputs(updateFields);
    if (
      updateFields.business_name !== '' &&
      updateFields.address !== '' &&
      updateFields.country !== '' &&
      updateFields.state !== '' &&
      updateFields.zip !== '' &&
      updateFields.city !== '' &&
      event.target.value !== '' &&
      event.target.name !== 'apartment'
    ) {
      setButtonDisabled(false);
    } else if (event.target.name !== 'apartment') {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    event.persist();
    props.setBusinessModalShow(false);
    setTermsModalShow(true);
  };

  return (
    <React.Fragment>
      <BusinessDetails
        show={props.show}
        onHide={props.onHide}
        inputs={inputs}
        buttonDisabled={buttonDisabled}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
      />
      <TermsConditionsDetail
        show={termsModalShow}
        inputs={inputs}
        onHide={() => {
          setTermsModalShow(false);
        }}
        handleAccept={props.handleAccept}
      ></TermsConditionsDetail>
    </React.Fragment>
  );
};

export default TermsAndConditions;
