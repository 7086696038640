import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ltiActions } from 'pages/integrations/containers/LtiIntegrationContainer/actions';
const Confirmation = ({ isOpen, hideModal, deleteParams, dispatch }) => {
  const deleteLtiConfiguration = () => {
    dispatch(ltiActions.deleteLtiConfiguration(deleteParams));
    hideModal();
  };

  return (
    <Modal onHide={hideModal} show={isOpen} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>Confirmation</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '10px' }}>
        <FontAwesomeIcon icon={Icons.faExclamationCircle} /> &nbsp; Are you sure you want to delete it?
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary btn-right" onClick={deleteLtiConfiguration} disabled={false}>
          <span> Yes, delete it</span>
        </button>
      </Modal.Footer>
      <div className="justify-content-end d-flex"></div>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(ltiActions, dispatch)
  };
}
export default connect(mapDispatchToProps)(Confirmation);
