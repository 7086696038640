import React, { useState } from 'react';
import './segmentKeyInput.css';
import { messageNotification } from 'helpers';
import { updateEdlySiteConfig } from 'services/siteConfiguration';
import { SEGMENT_KEY_PLACEHOLDER } from '../constants/index';

const SegmentKeyInput = () => {
  const [segmentKey, setSegmentKey] = useState('');

  const updateKey = () => {
    if (segmentKey === '') {
      messageNotification('danger', 'Invalid Input', 'You must fill segment key field');
      return;
    }

    const json_data = {
      lms: {
        DJANGO_SETTINGS_OVERRIDE: {
          LMS_SEGMENT_KEY: `${segmentKey}`,
          CMS_SEGMENT_KEY: `${segmentKey}`
        }
      },
      cms: {
        DJANGO_SETTINGS_OVERRIDE: {
          LMS_SEGMENT_KEY: `${segmentKey}`,
          CMS_SEGMENT_KEY: `${segmentKey}`
        }
      }
    };
    updateEdlySiteConfig(json_data)
      .then((res) => messageNotification('success', 'Key updated', res.data.success))
      .catch((error) => {
        messageNotification('danger', 'Error', `${error}`);
      });
  };
  return (
    <div className="integration-holder">
      <h3>Segment</h3>
      <p>
        Adding segment's key will send users interaction data to the segment, which can be analyzed in a variety of
        analytics tools.
      </p>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="form-label">Segment key</label>
          <input
            className="form-control"
            value={segmentKey}
            placeholder={SEGMENT_KEY_PLACEHOLDER}
            onChange={(event) => setSegmentKey(event.target.value)}
          ></input>
        </div>
        <div className="col-12 btn-holder justify-content-end d-flex">
          <button className="btn btn-primary" onClick={updateKey}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SegmentKeyInput;
