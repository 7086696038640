import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import PercentChangeBox from 'components/analytics/percentChangeBox';
import { getCurrentTotalSiteStaffUsersTotalCount, getCurrentTotalSiteStaffUsersPercentageChange } from 'selectors';

const TotalStaffUsers = (props) => {
  const { percent_change, total_count } = props;

  return (
    <div className="stats-box">
      <div className="d-flex align-items-center justify-content-between">
        <h1>{total_count}</h1>
        <PercentChangeBox percent_change={percent_change} />
      </div>
      <span className="sub-title">
        Staff Users
        <span className="tool-tip">
          <FontAwesomeIcon icon={Icons.faQuestionCircle} />
          <span className="tip-content">Total number of unique staff users registered on your platform.</span>
        </span>
      </span>
    </div>
  );
};

function mapStateToProps(state) {
  const percent_change = getCurrentTotalSiteStaffUsersPercentageChange(state);
  const total_count = getCurrentTotalSiteStaffUsersTotalCount(state);

  return {
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalStaffUsers);
