export const learnersAnalyticsConstants = {
  FETCH_LEARNERS_DETAIL_ANALYTICS_SUCCESS: 'FETCH_LEARNERS_DETAIL_ANALYTICS_SUCCESS',
  FETCH_LEARNERS_DETAIL_ANALYTICS_FAILURE: 'FETCH_LEARNERS_DETAIL_ANALYTICS_FAILURE',
  FETCH_ALL_LEARNERS_ANALYTICS_SUCCESS: 'FETCH_ALL_LEARNERS_ANALYTICS_SUCCESS',
  FETCH_ALL_LEARNERS_ANALYTICS_FAILURE: 'FETCH_ALL_LEARNERS_ANALYTICS_FAILURE',
  FETCH_LEARNERS_MAUS_SUCCESS: 'FETCH_LEARNERS_MAUS_SUCCESS',
  FETCH_LEARNERS_MAUS_FAILURE: 'FETCH_LEARNERS_MAUS_FAILURE',
  FETCH_LEARNERS_COURSE_COMPLETIONS_SUCCESS: 'FETCH_LEARNERS_COURSE_COMPLETIONS_SUCCESS',
  FETCH_LEARNERS_COURSE_COMPLETIONS_FAILURE: 'FETCH_LEARNERS_COURSE_COMPLETIONS_FAILURE',
  DOWNLOAD_LEARNER_PDF_SUCCESS: 'DOWNLOAD_LEARNER_PDF_SUCCESS',
  DOWNLOAD_LEARNER_PDF_FAILURE: 'DOWNLOAD_LEARNER_PDF_SUCCESS'
};

const usersDetailAnalyticsSuccess = (usersData) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_DETAIL_ANALYTICS_SUCCESS, payload: usersData.data };
};

const usersDetailAnalyticsFailure = (error) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_DETAIL_ANALYTICS_FAILURE, payload: error };
};

const fetchAllUsersAnalyticsSuccess = (usersData) => {
  return { type: learnersAnalyticsConstants.FETCH_ALL_LEARNERS_ANALYTICS_SUCCESS, payload: usersData };
};

const fetchAllUsersAnalyticsFailure = (error) => {
  return { type: learnersAnalyticsConstants.FETCH_ALL_LEARNERS_ANALYTICS_FAILURE, payload: error };
};

const fetchLearnersMausSuccess = (usersData) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_MAUS_SUCCESS, payload: usersData.data };
};

const fetchLearnersMausFailure = (error) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_MAUS_FAILURE, payload: error };
};

const fetchLearnersCourseCompletionsSuccess = (usersData) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_COURSE_COMPLETIONS_SUCCESS, payload: usersData.data };
};

const fetchLearnersCourseCompletionsFailure = (error) => {
  return { type: learnersAnalyticsConstants.FETCH_LEARNERS_COURSE_COMPLETIONS_FAILURE, payload: error };
};

const downloadLearnersDetailsPDFSuccess = (download_data) => {
  return { type: learnersAnalyticsConstants.DOWNLOAD_LEARNER_PDF_SUCCESS, payload: download_data };
};

const downloadLearnersDetailsPDFFailure = (error) => {
  return { type: learnersAnalyticsConstants.DOWNLOAD_LEARNER_PDF_FAILURE, payload: error };
};

export const learnersAnalyticsDispatches = {
  usersDetailAnalyticsSuccess,
  usersDetailAnalyticsFailure,
  fetchAllUsersAnalyticsSuccess,
  fetchAllUsersAnalyticsFailure,
  fetchLearnersMausSuccess,
  fetchLearnersMausFailure,
  fetchLearnersCourseCompletionsSuccess,
  fetchLearnersCourseCompletionsFailure,
  downloadLearnersDetailsPDFSuccess,
  downloadLearnersDetailsPDFFailure
};
