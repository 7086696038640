import { quickStatsConstants } from 'pages/dashboard/containers/quickStats/constants';

const initialState = {
  monthly_active_quick_stats_data_label: [],
  monthly_active_quick_stats_data: [0, 0],
  monthly_active_quick_stats_total_users_count: 0,
  monthly_active_quick_stats_percentage_change: '0.00',
  enrolled_courses_quick_stats_data_label: [],
  enrolled_courses_quick_stats_data: [0, 0],
  course_completions_quick_stats_data_label: [],
  course_completions_quick_stats_data: [0, 0],
  course_completions_quick_stats_total_users_count: 0,
  course_completions_quick_stats_percentage_change: '0.00'
};

export function fetchQuickStats(state = initialState, action) {
  switch (action.type) {
    case quickStatsConstants.FETCH_MAUS_QUICK_STATS_SUCCESS: {
      let { monthly_users_count = [], total_users_count = -1, percentage_change = 0 } = action.payload.data;
      if ((total_users_count = -1)) {
        let org = action.payload.data.organization;
        let prev_user_count = 0;
        total_users_count = 0;
        for (const key in org) {
          total_users_count += org[key].total_users_count;
          prev_user_count += org[key].prev_users_count;
        }
        if (prev_user_count === 0) {
          percentage_change = 'NA';
        } else {
          percentage_change = (total_users_count / prev_user_count) * 100;
        }
      }
      return {
        ...state,
        monthly_active_quick_stats_data_label: action.payload.data.month_names,
        monthly_active_quick_stats_data: monthly_users_count,
        monthly_active_quick_stats_total_users_count: total_users_count,
        monthly_active_quick_stats_percentage_change: percentage_change
      };
    }
    case quickStatsConstants.FETCH_MAUS_QUICK_STATS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case quickStatsConstants.FETCH_ENROLLED_COURSES_QS_SUCCESS:
      return {
        ...state,
        enrolled_courses_quick_stats_data_label: action.payload.data.month_names,
        enrolled_courses_quick_stats_data: action.payload.data.monthly_enrollments_count
      };
    case quickStatsConstants.FETCH_ENROLLED_COURSES_QS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case quickStatsConstants.FETCH_COURSE_COMPLETIONS_QS_SUCCESS:
      return {
        ...state,
        course_completions_quick_stats_data_label: action.payload.data.month_names,
        course_completions_quick_stats_data: action.payload.data.monthly_course_completions_count,
        course_completions_quick_stats_total_users_count: action.payload.data.total_course_completions,
        course_completions_quick_stats_percentage_change: action.payload.data.percentage_change
      };
    case quickStatsConstants.FETCH_COURSE_COMPLETIONS_QS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
