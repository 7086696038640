import BadgrIntegration from 'pages/integrations/containers/BadgrContainer/badgrIndex';
import DiscussionIntegration from 'pages/integrations/containers/DiscussionContainer/discussionIndex';
import LtiConfiguration from 'pages/integrations/containers/LtiIntegrationContainer/ltiConfigurationIndex';
import React, { useState } from 'react';
import 'pages/integrations/index.scss';

const Integrations = () => {
  const [currentTab, setCurrentTab] = useState('badgr');

  return (
    <div className="block form-container">
      <h1>Integrations</h1>

      <div className="tabs" id="accountSettings">
        <ul className="tab-links">
          <li onClick={() => setCurrentTab('badgr')} className={currentTab === 'badgr' ? 'active' : ''}>
            <span>BADGR</span>
          </li>
          <li
            onClick={() => setCurrentTab('lti_integration')}
            className={currentTab === 'lti_integration' ? 'active' : ''}
          >
            <span>LTI INTEGRATION</span>
          </li>
        </ul>
      </div>

      {currentTab === 'badgr' ? (
        <>
          <DiscussionIntegration />
          <BadgrIntegration />
        </>
      ) : (
        <LtiConfiguration />
      )}
    </div>
  );
};

export default Integrations;
