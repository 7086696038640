import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FooterNote from 'components/footer/footerNote';
import InsightsLoader from 'components/loaders/InsightsLoader';
import CoursesOverview from 'pages/insights/containers/coursesAnalyticsContainer/components/coursesOverview';
import DownloadCoursesAnalytics from 'pages/insights/containers/coursesAnalyticsContainer/components/downloadCoursesAnalytics';
import { coursesAnalyticsActions } from 'pages/insights/containers/coursesAnalyticsContainer/actions';
import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import { getAllCoursesGeneralData, getCoursesGeneralInfo, getCurrentSiteLearnerHistory } from 'selectors';
import 'pages/insights/containers/coursesAnalyticsContainer/coursesAnalytics.scss';
import ActiveCourses from 'pages/insights/containers/analyticsSummaryContainer/components/activeCourses';
import CoursesCompletions from 'components/coursesCompletions';
import DateFilter from 'components/dateFilter';
import TotalCourses from 'pages/insights/containers/analyticsSummaryContainer/components/totalCourses';
import { quickStatsActions } from 'pages/dashboard/containers/quickStats/actions/quickStats';

const moment = require('moment');

const CoursesAnalytics = (props) => {
  const { courses_data, dispatch, site_history } = props;

  const defaultState = {
    filter_type: 'this_year',
    filter_start_date: moment().subtract(1, 'months'),
    filter_end_date: moment()
  };
  const [AnalyticsSummaryFilterType, setAnalyticsSummaryFilterType] = useState(defaultState.filter_type);
  const [AnalyticsSummaryFilterStartDate, setAnalyticsSummaryFilterStartDate] = useState(
    defaultState.filter_start_date
  );
  const [AnalyticsSummaryFilterEndDate, setAnalyticsSummaryFilterEndDate] = useState(defaultState.filter_end_date);

  const handleFilterChange = (value) => {
    setAnalyticsSummaryFilterType(value);
  };

  const handleSetStartDate = (date) => {
    setAnalyticsSummaryFilterStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setAnalyticsSummaryFilterEndDate(date);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    dispatch(
      analyticsSummaryActions.fetchCoursesGeneralData({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchGeneralSiteMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchMonthlyUsersStats({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteDailyMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteMonthlyMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      quickStatsActions.fetchMAUSQuickStats({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      quickStatsActions.fetchCourseCompletionsQuickStats({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
  };

  const setDefaultFilter = (e) => {
    e.preventDefault();
    setAnalyticsSummaryFilterType(defaultState.filter_type);
    dispatch(
      analyticsSummaryActions.fetchCoursesGeneralData({
        ...defaultState
      })
    );
    dispatch(
      analyticsSummaryActions.fetchGeneralSiteMetrics({
        ...defaultState
      })
    );
    dispatch(
      analyticsSummaryActions.fetchMonthlyUsersStats({
        ...defaultState
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteDailyMetrics({
        ...defaultState
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteMonthlyMetrics({
        ...defaultState
      })
    );
    dispatch(
      quickStatsActions.fetchMAUSQuickStats({
        ...defaultState
      })
    );
    dispatch(
      quickStatsActions.fetchCourseCompletionsQuickStats({
        ...defaultState
      })
    );
  };

  useEffect(() => {
    dispatch(coursesAnalyticsActions.fetchAllCoursesGeneralData());
    dispatch(analyticsSummaryActions.fetchCoursesGeneralData());
    dispatch(
      analyticsSummaryActions.fetchCoursesGeneralData({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchGeneralSiteMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchMonthlyUsersStats({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteDailyMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      analyticsSummaryActions.fetchSiteMonthlyMetrics({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(
      quickStatsActions.fetchMAUSQuickStats({
        filter_type: AnalyticsSummaryFilterType,
        filter_start_date: AnalyticsSummaryFilterStartDate,
        filter_end_date: AnalyticsSummaryFilterEndDate
      })
    );
    dispatch(quickStatsActions.fetchCourseCompletionsQuickStats());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="courses-analytics" id="page_content">
      {courses_data && site_history ? (
        <Fragment>
          <div className="page-content-header">
            <h1>Courses Analytics</h1>
            <DownloadCoursesAnalytics {...props} />
          </div>
          <div>
            <DateFilter
              filterType={AnalyticsSummaryFilterType}
              handleRefresh={handleRefresh}
              handleFilterChange={handleFilterChange}
              handleSetStartDate={handleSetStartDate}
              handleSetEndDate={handleSetEndDate}
              setDefaultFilter={setDefaultFilter}
            />
          </div>
          <div className="stats-block">
            <CoursesCompletions show_chart="true" trending="true" title="Course Completions" />
            <TotalCourses />
            <ActiveCourses />
          </div>
          <CoursesOverview {...props} />
          <FooterNote />
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { courses_count, current_page, courses_data } = getCoursesGeneralInfo(state);
  const { all_courses_stats } = getAllCoursesGeneralData(state);
  const site_history = getCurrentSiteLearnerHistory(state);

  return {
    courses_count,
    current_page,
    courses_data,
    all_courses_stats,
    site_history
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(analyticsSummaryActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoursesAnalytics);
