export const courseDetailConstants = {
  FETCH_COURSE_ENROLLMENT_SUCCESS: 'FETCH_COURSE_ENROLLMENT_SUCCESS',
  FETCH_COURSE_ENROLLMENT_FAILURE: 'FETCH_COURSE_ENROLLMENT_FAILURE',
  FETCH_COURSE_ALL_LEARNERS_SUCCESS: 'FETCH_COURSE_ALL_LEARNERS_SUCCESS',
  FETCH_COURSE_ALL_LEARNERS_FAILURE: 'FETCH_COURSE_ALL_LEARNERS_FAILURE',
  FETCH_COURSE_DETAIL_SUCCESS: 'FETCH_COURSE_DETAIL_SUCCESS',
  FETCH_COURSE_DETAIL_FAILURE: 'FETCH_COURSE_DETAIL_FAILURE'
};

const courseEnrollmentsSuccess = (courseData, course_id) => {
  return {
    type: courseDetailConstants.FETCH_COURSE_ENROLLMENT_SUCCESS,
    payload: courseData.data,
    course_id: course_id
  };
};

const courseEnrollmentsFailure = (error) => {
  return { type: courseDetailConstants.FETCH_COURSE_ENROLLMENT_FAILURE, error };
};

const courseAllLearnersSuccess = (courseData) => {
  return {
    type: courseDetailConstants.FETCH_COURSE_ALL_LEARNERS_SUCCESS,
    payload: courseData.data
  };
};

const courseAllLearnersFailure = (error) => {
  return { type: courseDetailConstants.FETCH_COURSE_ALL_LEARNERS_FAILURE, error };
};

const courseDetailSuccess = (courseData) => {
  return {
    type: courseDetailConstants.FETCH_COURSE_DETAIL_SUCCESS,
    payload: courseData.data
  };
};

const courseDetailFailure = (error) => {
  return { type: courseDetailConstants.FETCH_COURSE_DETAIL_FAILURE, error };
};

export const courseDetailDispatches = {
  courseEnrollmentsSuccess,
  courseEnrollmentsFailure,
  courseAllLearnersSuccess,
  courseAllLearnersFailure,
  courseDetailSuccess,
  courseDetailFailure
};
