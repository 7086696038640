import React, { useState } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';

import UserListing from 'pages/users/containers/usersContainer/components/usersListing';
import { getOrganization, getSiteUsers } from 'selectors';
import 'pages/users/containers/index.scss';
import 'assets/sass/components/pagination.scss';
import { PLAN_FEATURES } from 'pages/users/containers/usersContainer/constants';

const UsersCenter = (props) => {
  const [addModalShow, setAddModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);
  const history = useHistory();

  const handleUserAddition = () => {
    const plan = props.organization['current_site_plan'];
    if (props.count <= PLAN_FEATURES[plan.toUpperCase()]['NUMBER_OF_REGISTERED_USERS']) {
      setAddModalShow(true);
      mixpanel.track('Add New User', {
        service: 'edly_panel',
        screen_name: 'panel_users',
        organization: props.organization.name
      });
    } else {
      history.push('/panel/settings/billing/?redirect=panel');
    }
  };

  return (
    <div className="block">
      <div className="users-header">
        <h1>User Management</h1>
        <button
          className="btn btn-primary"
          onClick={() => {
            setBulkModalShow(true);
          }}
        >
          Add bulk users
        </button>
        <button className="btn btn-primary btn-right" onClick={handleUserAddition}>
          Add new user
        </button>
      </div>
      <div className="tabs">
        <div className="tab">
          <div className="user-content">
            <UserListing
              bulkModalShow={bulkModalShow}
              setBulkModalShow={setBulkModalShow}
              addModalShow={addModalShow}
              setAddModalShow={setAddModalShow}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { count } = getSiteUsers(state);

  return { organization, count };
}

export default connect(mapStateToProps)(UsersCenter);
