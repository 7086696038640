import { getPanelBackendUrl, messageNotification } from 'helpers';
import { userSettingsService } from 'services';
import { npsDispatches } from 'pages/dashboard/containers/npsDialog/constants';
import { settingsActions } from 'pages/settings/containers/settingsContainer/actions';

const updateNps = (data, callback) => {
  return (dispatch) => {
    if (getPanelBackendUrl()) {
      userSettingsService
        .updateNpsData(data)
        .then((organizations) => {
          Promise.resolve(dispatch(npsDispatches.updateNpsSuccess(organizations))).then(() => {
            dispatch(settingsActions.fetchOrganization());
            callback();
          });
        })
        .catch((error) => {
          if (error.response) {
            messageNotification('danger', 'There was an error updating your feedback', error.response);
          }
        });
    } else {
      dispatch(npsDispatches.updateNpsFailure());
    }
  };
};

export const npsActions = {
  updateNps
};
