import { PANEL_API_ENDPOINTS } from 'config';
import { getPanelBackendUrl } from 'helpers';

const axios = require('axios');

export function fetchFreeTrial() {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.FREE_TRIAL}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
}

export function fetchSiteDetailService() {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.DNS_ENTRIES}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    url
  };
  return axios(requestOptions);
}

export function postSiteDetails(data) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.DNS_ENTRIES}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    data: data,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}

export function updateClientSetup(data) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.JENKINS_DNS_SECOND_JOB}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    data: data,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}

export function fetchUpdatedStateService() {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.JENKINS_DNS_SECOND_JOB}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}

export function fetchVerifyDnsEntriesService() {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.VERIFY_DNS_ENTRIES}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'GET',
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}

export function postRestDnsService(data) {
  let url = `${getPanelBackendUrl()}${PANEL_API_ENDPOINTS.RESET_DNS_TO_DEFAULT}`;

  const requestOptions = {
    crossDomain: true,
    withCredentials: true,
    method: 'POST',
    data: data,
    headers: {
      'X-CSRFToken': localStorage.getItem('panel_csrf_token')
    },
    url
  };
  return axios(requestOptions);
}
