import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PriceSlider from './priceSlider';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { pricingService } from 'services/index';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import { EDLY_SAAS_PID } from 'config';
import { messageNotification } from 'helpers';
import BenefitsSlider from 'pages/pricing/containers/pricingContainer/components/benefitsSlider';
import { TermsAndConditions } from 'pages/pricing/containers/pricingContainer/components/termsAndConditions';

const moment = require('moment');

const FlexiblePricing = (props) => {
  const usersCount = useSelector((state) => state.USER_SLIDER.count);
  const org = useSelector((state) => state.ORGANIZATION.organization);
  const learnerCount = useSelector((state) => state.MAUS.total_active_learner) || 0;
  const { expected_billing, renewal_expiry, current_site_plan } = org;
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (usersCount >= 0 && usersCount <= 20) {
      setPrice(0);
    } else if (usersCount > 20 && usersCount <= 100) {
      setPrice(300);
    } else if (usersCount > 100 && usersCount <= 250) {
      setPrice(500);
    } else if (usersCount > 250 && usersCount <= 500) {
      setPrice(750);
    } else if (usersCount > 500 && usersCount <= 1000) {
      setPrice(1125);
    } else if (usersCount > 1000 && usersCount <= 2500) {
      setPrice(1850);
    } else if (usersCount > 2500 && usersCount <= 5000) {
      setPrice(2250);
    } else if (usersCount > 5000 && usersCount <= 10000) {
      setPrice(3500);
    }
  }, [usersCount]);

  const [businessModalShow, setBusinessModalShow] = useState(false);
  const [inputs, setInputs] = useState({
    business_name: '',
    address: '',
    apartment: '',
    country: '',
    city: '',
    zip: '',
    state: ''
  });

  function handleSubscribe() {
    setBusinessModalShow(true);
  }

  const handleAccept = (event) => {
    event.persist();
    Promise.resolve(props.updateOrgTermsConditions(true, inputs)).then(() => {
      pricingService
        .createCheckoutSession(EDLY_SAAS_PID)
        .then((response) => {
          const checkout_url = response.data;
          window.location.href = checkout_url;
        })
        .catch((error) => {
          messageNotification(
            'danger',
            'Unable to checkout',
            error.response ? error.response?.data : 'There was an error encountered while checking out with stripe'
          );
        });
    });
  };

  return (
    <>
      <h1>Billing and Plans</h1>
      <TermsAndConditions
        show={businessModalShow}
        setBusinessModalShow={setBusinessModalShow}
        onHide={() => {
          setBusinessModalShow(false);
        }}
        handleAccept={handleAccept}
        inputs={inputs}
        setInputs={setInputs}
      ></TermsAndConditions>
      <div class="block"></div>
      <Row>
        <Col xs={12} md={{ span: 12 }}>
          <div className="current-billing">
            {current_site_plan === 'trial' ? (
              <>
                <h2>
                  Currently you are on Free Trial with {learnerCount ? learnerCount : 0} learners and expected bill is $
                  {expected_billing ? (expected_billing / 100).toFixed(2) : 0} this month
                </h2>
              </>
            ) : (
              <>
                <h2>
                  Your current month's learners are {learnerCount ? learnerCount : 0} and expected bill is{' '}
                  <b> ${expected_billing ? (expected_billing / 100).toFixed(2) : 0}</b> this month
                </h2>
              </>
            )}
            <h4>
              Renewal/Expiry date -{' '}
              <b>
                {current_site_plan === 'trial'
                  ? moment(renewal_expiry).format('MMMM DD, YYYY')
                  : moment(renewal_expiry * 1000).format('MMMM DD, YYYY')}
              </b>
            </h4>
          </div>
          <div className="price-box">
            <h1>Calculate Your Cost</h1>
            <h4>You will be charged based on active learners you have each month</h4>
            <PriceSlider />
            {current_site_plan === 'trial' ? (
              <>
                <h4>
                  Currently you added {usersCount} learners{usersCount <= 10000 ? ', you will be charged' : ''}
                </h4>
                {usersCount <= 10000 ? (
                  <h2>${price}</h2>
                ) : (
                  <h4>Please contact sales department for further information</h4>
                )}
                {usersCount <= 20 ? (
                  <Button variant="secondary" disabled>
                    Free Trial
                  </Button>
                ) : usersCount > 20 && usersCount <= 10000 ? (
                  <Button onClick={handleSubscribe}>Subscribe</Button>
                ) : (
                  ''
                )}
                {usersCount > 10000 && (
                  <a href="https://edly.io/request-a-demo/">
                    <Button>Contact Sales</Button>
                  </a>
                )}
                <h5>No upfront charge, you will be invoiced after 30 days based on the actual learners</h5>
              </>
            ) : (
              <>
                {usersCount <= 10000 ? (
                  <h2>${price}</h2>
                ) : (
                  <h4>Please contact sales department for further information</h4>
                )}
              </>
            )}
          </div>
          <div className="contact-info">
            <h4>
              If you need help to setup your account{' '}
              <a href="https://edly.io/request-a-demo/" className="nav-link">
                Contact Sales
              </a>
            </h4>
            <h6>
              <i>Services charges will be applied</i>
            </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="benefit-slider">
          <h2>Benefit Details</h2>
          <BenefitsSlider />
        </Col>
      </Row>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(pricingActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(FlexiblePricing);
