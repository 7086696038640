import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confirmation from './confirmation';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { siteDetailsActions } from '../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const SiteDetailsValues = ({ siteUrl, email, edit, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const openConfirmation = (edit = false) => {
    setIsOpen(true);
    edit ? setIsEdit(true) : setIsEdit(false);
  };

  const resetDnsRecord = () => {
    setIsOpen(false);
    dispatch(siteDetailsActions.postResetDns(null));
  };

  return (
    <>
      <h4>Site url details</h4>
      <div className="site-card">
        <div className="url-details">
          <FontAwesomeIcon icon={Icons.faGlobe} className="site-details-icon" />
          <span className="ml-5">{siteUrl}</span>
        </div>
        <div className="url-details">
          <FontAwesomeIcon icon={Icons.faEnvelope} className="site-details-icon" />
          <span>{email}</span>
        </div>
        <div className="btn-holder">
          <button className="btn pen-button" onClick={() => openConfirmation(true)}>
            <FontAwesomeIcon icon={Icons.faPen} className="justify-content-end" />
          </button>
        </div>
      </div>

      <div className="reset-holder">
        <span className="reset-text">
          You can disable these settings anytime, By disable these settings your site url will be edly subdomain, based
          on the site name.
        </span>

        <button className="btn btn-link reset-button site-detail-btn" onClick={() => openConfirmation(false)}>
          Reset to default settings
        </button>
      </div>
      <Confirmation
        isOpen={isOpen}
        isEdit={isEdit}
        callBack={isEdit ? edit : resetDnsRecord}
        hideModal={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(siteDetailsActions, dispatch)
  };
}

export default connect(mapDispatchToProps)(SiteDetailsValues);
