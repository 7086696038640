export const coursesAnalyticsConstants = {
  FETCH_COURSES_ALL_DATA_SUCCESS: 'FETCH_COURSES_ALL_DATA_SUCCESS',
  FETCH_COURSES_ALL_DATA_FAILURE: 'FETCH_COURSES_ALL_DATA_FAILURE',
  FETCH_COURSES_MAUS_SUCCESS: 'FETCH_COURSES_MAUS_SUCCESS',
  FETCH_COURSES_MAUS_FAILURE: 'FETCH_COURSES_MAUS_FAILURE'
};

const coursesFetchAllDataSuccess = (coursesData) => {
  return { type: coursesAnalyticsConstants.FETCH_COURSES_ALL_DATA_SUCCESS, payload: coursesData.data };
};

const coursesFetchAllDataFailure = (error) => {
  return { type: coursesAnalyticsConstants.FETCH_COURSES_ALL_DATA_FAILURE, error };
};

const coursesFetchMAUsSuccess = (coursesMAUs) => {
  return { type: coursesAnalyticsConstants.FETCH_COURSES_MAUS_SUCCESS, payload: coursesMAUs.data };
};

const coursesFetchMAUsFailure = (error) => {
  return { type: coursesAnalyticsConstants.FETCH_COURSES_MAUS_FAILURE, error };
};

export const coursesAnalyticsDispatches = {
  coursesFetchAllDataSuccess,
  coursesFetchAllDataFailure,
  coursesFetchMAUsSuccess,
  coursesFetchMAUsFailure
};
