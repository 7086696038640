import { getPanelBackendUrl, messageNotification } from 'helpers';
import { userSettingsService } from 'services';
import { showUserGuideDispatches } from 'pages/dashboard/containers/displayDemo/constants';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';

const updateShowUserGuide = (showUserGuide) => {
  return (dispatch) => {
    if (getPanelBackendUrl()) {
      userSettingsService
        .updateShowUserGuide(showUserGuide)
        .then((organizations) => {
          Promise.resolve(
            dispatch(settingsDispatches.fetchOrganizationSuccess({ data: { results: [organizations.data] } }))
          );
        })
        .catch((error) => {
          if (error.response) {
            messageNotification('danger', 'There was an error updating user guide', error.response);
          }
        });
    } else {
      dispatch(showUserGuideDispatches.updateShowUserGuideFailure());
    }
  };
};

export const showUserGuideActions = {
  updateShowUserGuide
};
