import { EDX_API_ENDPOINTS } from 'config';
import { getPanelLmsBaseUrl } from 'helpers';

const axios = require('axios');

export const mausService = {
  fetchMAUS,
  requestMAUSReport
};

function fetchMAUS(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.MAUS_API}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}

function requestMAUSReport(params) {
  const url = `${getPanelLmsBaseUrl()}${EDX_API_ENDPOINTS.MAUS_API}`;
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    params: params,
    url
  };
  return axios(requestOptions);
}
