export const settingConstants = {
  UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
  USER_SETTINGS_FAILURE: 'USER_SETTINGS_FAILURE',
  FETCH_USER_SETTINGS: 'FETCH_USER_SETTINGS',
  FETCH_COUNTRY_TIMEZONES: 'FETCH_COUNTRY_TIMEZONES',
  FETCH_USERNAME: 'FETCH_USERNAME',
  FETCH_USER_PREFERENCES: 'FETCH_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  UPDATE_USER_PROFILE_IMAGE: 'UPDATE_USER_PROFILE_IMAGE',
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  SET_PASSWORD_RESET: 'SET_PASSWORD_RESET',
  FETCH_USER_ROLES: 'FETCH_USER_ROLES',
  FETCH_USER_ROLES_FAILURE: 'FETCH_USER_ROLES_FAILURE',
  FETCH_ECOMMERCE_LOGIN_SESSION: 'FETCH_ECOMMERCE_LOGIN_SESSION',
  APPLICATION_LOADED: 'APPLICATION_LOADED',
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  FETCH_ORGANIZATIONS_FAILURE: 'FETCH_ORGANIZATIONS_FAILURE'
};

const updateUserSettingsSuccess = (userData) => {
  return { type: settingConstants.UPDATE_USER_SETTINGS, payload: userData.data };
};

const userSettingsFailure = (error) => {
  return { type: settingConstants.USER_SETTINGS_FAILURE, payload: error };
};

const fetchUserSettingsSuccess = (userData) => {
  return { type: settingConstants.FETCH_USER_SETTINGS, payload: userData.data[0] };
};

const fetchCountryTimezoneSuccess = (userData) => {
  return { type: settingConstants.FETCH_COUNTRY_TIMEZONES, payload: userData.data };
};

const fetchUsernameSuccess = (organizations) => {
  return {
    type: settingConstants.FETCH_USERNAME,
    payload: { username: organizations.data.results[0].current_site_user }
  };
};

const fetchUserPreferencesSuccess = (userData) => {
  return { type: settingConstants.FETCH_USER_PREFERENCES, payload: userData.data };
};

const updateUserPreferencesSuccess = (userData) => {
  return { type: settingConstants.UPDATE_USER_PREFERENCES, payload: userData.data };
};

const updateUserProfileImageSuccess = (userData) => {
  return { type: settingConstants.UPDATE_USER_PROFILE_IMAGE, payload: userData.data };
};

const userPasswordResetSuccess = (userData) => {
  return { type: settingConstants.RESET_USER_PASSWORD, payload: userData.data };
};

const setPasswordResetSuccess = (payload) => {
  return { type: settingConstants.SET_PASSWORD_RESET, payload: payload };
};

const fetchUserRolesSuccess = (userData) => {
  return { type: settingConstants.FETCH_USER_ROLES, payload: userData.data[0] };
};

const fetchUserRolesFailure = (error) => {
  return { type: settingConstants.FETCH_USER_ROLES_FAILURE, payload: error };
};

const ecommerceLoginSuccess = (response) => {
  return { type: settingConstants.FETCH_ECOMMERCE_LOGIN_SESSION, payload: response };
};

const applicationLoadedSuccess = () => {
  return { type: settingConstants.APPLICATION_LOADED };
};

const fetchOrganizationSuccess = (organizations) => {
  return { type: settingConstants.FETCH_ORGANIZATION, payload: organizations.data };
};

const fetchOrganizationFailure = (error) => {
  return { type: settingConstants.FETCH_ORGANIZATIONS_FAILURE, payload: error };
};

export const settingsDispatches = {
  updateUserSettingsSuccess,
  userSettingsFailure,
  fetchUserSettingsSuccess,
  fetchCountryTimezoneSuccess,
  fetchUsernameSuccess,
  fetchUserPreferencesSuccess,
  updateUserPreferencesSuccess,
  updateUserProfileImageSuccess,
  userPasswordResetSuccess,
  setPasswordResetSuccess,
  fetchUserRolesSuccess,
  fetchUserRolesFailure,
  ecommerceLoginSuccess,
  applicationLoadedSuccess,
  fetchOrganizationSuccess,
  fetchOrganizationFailure
};
