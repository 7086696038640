import React, { useState } from 'react';
import Slider from 'react-slick';
import IconView from 'assets/images/ico-view.svg';

import { themesActions } from 'pages/appearance/containers/appearanceContainer/actions/themes';
import ImagePreviewSlider from 'pages/appearance/containers/appearanceContainer/components/imagePreviewSlider';
import 'pages/appearance/index.scss';

const ThemePreview = (props) => {
  const { theme, activeTheme, setActiveTheme, organization, dispatch } = props;
  const [imageModalShow, setImageModalShow] = useState(false);
  const [indexThemeImagePreview, setIndexThemeImagePreview] = useState(0);

  const handleThemeChange = (e) => {
    const { id, value } = e.target;
    setActiveTheme(value);
    dispatch(
      themesActions.updateThemeConfiguration({
        theme_id: id,
        theme_name: value,
        organization_id: organization.id
      })
    );
  };

  const settings = {
    infinite: false,
    slidesToShow: 3,
    centerPadding: '50px',
    slidesToScroll: 1,
    speed: 500,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const previewImages = theme.theme_previews.map((themePreview, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          setIndexThemeImagePreview(index);
          setImageModalShow(true);
        }}
        className="theme-slide"
      >
        <h2 className="title">
          <span className="txt">{themePreview.service_name}</span>
        </h2>
        <div className="image-post">
          <img src={themePreview.preview_image} alt={themePreview.service_name} />
          <span className="ico-view">
            <img src={IconView} alt="Icon View" />
          </span>
        </div>
      </div>
    );
  });

  return (
    <div className={`theme-card `}>
      {theme.theme_name !== activeTheme && (
        <button
          id={theme.id}
          value={theme.theme_name}
          onClick={handleThemeChange}
          type="button"
          className="btn btn-primary"
        >
          Activate
        </button>
      )}
      <Slider {...settings}>{previewImages}</Slider>
      <ImagePreviewSlider
        showSlider={imageModalShow}
        themePreviews={theme.theme_previews}
        currentIndex={indexThemeImagePreview}
        onHide={() => {
          setImageModalShow(false);
        }}
      />
    </div>
  );
};

export default ThemePreview;
