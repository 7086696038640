import React from 'react';
import 'pages/users/containers/index.scss';

const PaginationLoader = () => {
  return (
    <div className="section-loader loading">
      <ul className="loading-dots">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default PaginationLoader;
