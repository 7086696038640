import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { coursesAnalyticsActions } from 'pages/insights/containers/coursesAnalyticsContainer/actions';

const DownloadCoursesAnalytics = (props) => {
  const handleFilterChange = () => {
    props.dispatch(coursesAnalyticsActions.fetchInsightsCoursesCSV());
  };

  return (
    <div id="export_button">
      <button className="btn btn-primary" onClick={handleFilterChange}>
        Export <span className="hidden-mobile">CSV</span>
        <FontAwesomeIcon icon={Icons.faFileExport} />
      </button>
    </div>
  );
};

export default DownloadCoursesAnalytics;
